import React, { useContext, useState, useEffect } from 'react';
import { HospitalContext } from './HospitalContext';
import './GenerateLinkForAPT.css';
import { db } from '../../firebaseconfig';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';

const GenerateLink = () => {
  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;
  const phoneCode = hospitalData ? hospitalData.phoneCode : null;

  const [email, setEmail] = useState('');
  const [link, setLink] = useState('');
  const [aiBotLink, setAIBotLink] = useState(''); // State for AI Chatbot link
  const [showLink, setShowLink] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [hospitalDocRef, setHospitalDocRef] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    const isValid = validateEmail(emailValue);
    setIsValidEmail(isValid);
    if (!isValid) {
      setShowLink(false);
    }
  };

  const generateLink = (path) => {
    if (HID && phoneCode) {
      const baseUrl = window.location.origin;
      const generatedLink = `${baseUrl}/${path}?HID=${HID}&phoneCode=${encodeURIComponent(phoneCode)}&email=${encodeURIComponent(email)}`;
      return generatedLink;
    }
    return '';
  };

  const fetchHospitalDocRef = async () => {
    if (HID) {
      const hospitalCollectionRef = collection(db, 'hospitals');
      const q = query(hospitalCollectionRef, where('HID', '==', HID));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const hospitalDoc = querySnapshot.docs[0];
        const hospitalDocRef = hospitalDoc.ref;
        setHospitalDocRef(hospitalDocRef);

        const hospitalData = hospitalDoc.data();
        const storedEmail = hospitalData.EmailForAppointmentNotify;
        const storedLink = hospitalData.LinkForAppointment;
        const storedAIBotLink = hospitalData.LinkForAppointmentAIBOT; // Fetch stored AI Chatbot link

        if (storedEmail) {
          setEmail(storedEmail);
        }

        if (storedLink) {
          setLink(storedLink);
          setShowLink(true);
        }

        if (storedAIBotLink) {
          setAIBotLink(storedAIBotLink);
          setShowLink(true);
        }
      }
    }
  };

  useEffect(() => {
    fetchHospitalDocRef();
  }, [HID]);

  const handleConfirm = async () => {
    if (hospitalDocRef) {
      setIsProcessing(true);
      const generatedLink = generateLink('AppointmentLink');
      const generatedAIBotLink = generateLink('AIChatBotForAppointment'); // Generate AI Chatbot link
      setLink(generatedLink);
      setAIBotLink(generatedAIBotLink);
      setShowLink(true);

      try {
        await updateDoc(hospitalDocRef, {
          EmailForAppointmentNotify: email,
          LinkForAppointment: generatedLink,
          LinkForAppointmentAIBOT: generatedAIBotLink, // Update AI Chatbot link in Firestore
        });
        setIsProcessing(false);
        alert('New links are ready to copy!');
      } catch (error) {
        console.error('Error updating document:', error);
        setIsProcessing(false);
        alert('An error occurred while updating the links. Please try again.');
      }
    }
  };

  const handleCopy = (linkToCopy) => {
    navigator.clipboard.writeText(linkToCopy);
    alert('Link copied to clipboard!');
  };

  return (
    <div className="APTLINK-container">
      <h2 className="APTLINK-heading">Create Shareable Links for Patients to Schedule Appointment</h2>
      <p className="APTLINK-text">Please enter the email address below to create the links and receive appointment notifications.</p>
      <input
        type="email"
        value={email}
        onChange={handleEmailChange}
        placeholder="Enter email"
        className="APTLINK-email-input"
      />
      {!isValidEmail && <p className="APTLINK-error-message">Please enter a valid email address.</p>}
      <button onClick={handleConfirm} disabled={!isValidEmail || isProcessing} className="APTLINK-confirm-button">
        {isProcessing ? 'Processing...' : 'Confirm'}
      </button>
      {showLink && (
        <div className="APTLINK-link-container">
          <p className="APTLINK-text">Copy the links below to share with patients or post on social media platforms, WhatsApp, websites, etc.</p>
          <div className="APTLINK-link-group">
            <input type="text" value={link} readOnly className="APTLINK-link-input" />
            <button onClick={() => handleCopy(link)} className="APTLINK-copy-button">Copy Appointment Link</button>
          </div>
          <div className="APTLINK-link-group">
            <input type="text" value={aiBotLink} readOnly className="APTLINK-link-input" />
            <button onClick={() => handleCopy(aiBotLink)} className="APTLINK-copy-button">Copy AI Chatbot Link</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GenerateLink;
