// firebaseconfig.js
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

// TEST SERVER CONFIG

// const firebaseConfig = {
//   apiKey: "AIzaSyDCu7188lh_2_qdkkv0Ap-CzkKefxshKIc",
//   authDomain: "qms-apt-1app-for-all.firebaseapp.com",
//   projectId: "qms-apt-1app-for-all",
//   storageBucket: "qms-apt-1app-for-all.appspot.com",
//   messagingSenderId: "762690113253",
//   appId: "1:762690113253:web:989d956c574933393ace0c",
//   measurementId: "G-2NMBBP747G"
// };


// // PRODUCTION SERVER CONFIG

const firebaseConfig = {
  apiKey: "AIzaSyANQ8cfR-kafUNeAba8YXKIdS02NCPLBZU",
  authDomain: "webapp-krystacare-forpartners.firebaseapp.com",
  projectId: "webapp-krystacare-forpartners",
  storageBucket: "webapp-krystacare-forpartners.appspot.com",
  messagingSenderId: "783741310766",
  appId: "1:783741310766:web:fb5bf8d520596158588cbf",
  measurementId: "G-YBRB0DJGVS"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { firebaseConfig, app, analytics, db, storage, auth };
