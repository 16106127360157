import React, { useState, useEffect, useContext } from "react";
import { db, storage } from "../../firebaseconfig";
import { HospitalContext } from '../ScreenForAdmin/HospitalContext'; // Import HospitalContext
import {
  collection,
  onSnapshot,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
  getDocs,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import ALogin from "../ScreenForAdmin/ALogin";
import { auth } from "../../firebaseconfig";
import "./AddDoctor.css";


const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const DoctorsManager = () => {
  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;

  const [doctors, setDoctors] = useState([]);
  const [newDoctor, setNewDoctor] = useState({
    name: "",
    room: "",
    counter: 0,
    departmentName: "",
    hospitalName: "", // New field for Hospital Name
    availability: "Y",
    availableDays: [],
    timeSlots: [],
    photoURL: "",
    appointmentAllowed: "Y", // New field to store appointment allowance
    languages: [], // Initialize languages as an empty array
    consultationFee: "", // New field for consultation fee
    consultationFeeComments: "", // Add this line
    educationalQualifications: "",
    areasOfExpertise: [],
    aboutDoctor: "",
  });
  const [user, setUser] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [photoFile, setPhotoFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [addingDoctor, setAddingDoctor] = useState(false);

  const [updatingPhoto, setUpdatingPhoto] = useState(false);
  const [photoUpdated, setPhotoUpdated] = useState(false);

  const [languages, setLanguages] = useState([]);

  const [currency, setCurrency] = useState("");

  const fetchCurrency = async () => {
    if (HID) {
      const hospitalRef = doc(db, "hospitals", HID);
      const hospitalDoc = await getDoc(hospitalRef);
      if (hospitalDoc.exists()) {
        const currency = hospitalDoc.data().currency;
        setCurrency(currency);
        console.log("Fetched currency:", currency); // Console log the fetched currency
      }
    }
  };
  
  fetchCurrency();
  



  

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "doctors"), (snapshot) => {
      setDoctors(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          editCounter: false,
          editedCounterValue: doc.data().counter,
        }))
      );
    });

    const fetchLanguages = async () => {
      const languagesCollection = collection(db, "languages");
      const languagesSnapshot = await getDocs(languagesCollection);
      const languagesData = languagesSnapshot.docs.map((doc) => doc.data().name);
      setLanguages(languagesData);
    };
  
    fetchLanguages();

   

    const unsubscribeDepartments = onSnapshot(collection(db, "departments"), (snapshot) => {
      setDepartments(snapshot.docs.map((doc) => doc.data().name));
    });

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
      unsubscribeDepartments();
      unsubscribeAuth();
    };
  }, []);

  const validateTimeSlots = () => {
    return newDoctor.availableDays.every(day => {
      const timeSlot = newDoctor.timeSlots.find(slot => slot.day === day);
      return timeSlot && timeSlot.timeRanges.length > 0 && timeSlot.timeRanges[0].startTime !== "";
    });
  };

 

  const addDoctor = async () => {
    // Check for basic required fields
    if (
      !newDoctor.name.trim() ||
      !newDoctor.room.trim() ||
      !newDoctor.departmentName ||
      !newDoctor.availability ||
      !newDoctor.appointmentAllowed ||
      !newDoctor.consultationFee ||
      newDoctor.languages.length === 0
    ) {
      alert("Please fill in all required fields.");
      return;
    }
  
    // Check if appointments are allowed and validate available days and timepoints
    if (newDoctor.appointmentAllowed === "Y") {
      if (newDoctor.availableDays.length === 0) {
        alert("Please select at least one available day when appointments are allowed.");
        return;
      }
  
      if (!validateTimeSlots()) {
        alert("Please add at least one time point for each selected available day when appointments are allowed.");
        return;
      }
    }
  
    // Filter out vacant fields before adding the doctor
    let filteredDoctor = Object.fromEntries(
      Object.entries(newDoctor).filter(([_, value]) => value !== "")
    );
  
    // If hospitalData exists and is an object, include it in the filteredDoctor object
    if (hospitalData && typeof hospitalData === 'object') {
      filteredDoctor = {
        ...filteredDoctor,
        hospitalData,
      };
    }
  
    setLoading(true);
    setAddingDoctor(true);
  
    try {
      const doctorRef = await addDoc(collection(db, "doctors"), filteredDoctor);
  
      if (photoFile) {
        const photoRef = ref(storage, `doctorPhotos/${doctorRef.id}`);
        await uploadBytes(photoRef, photoFile);
        const photoURL = await getDownloadURL(photoRef);
        await updateDoc(doctorRef, { photoURL });
      }
  
      setNewDoctor({
        name: "",
        room: "",
        counter: 0,
        departmentName: "",
        availability: "Y",
        availableDays: [],
        timeSlots: [],
        photoURL: "",
        appointmentAllowed: "Y",
        languages: [],
        consultationFee: "",
        consultationFeeComments: "",
        HID: HID,
        educationalQualifications: "",
        areasOfExpertise: [],
        aboutDoctor: "",
      });
      setPhotoFile(null);
      setSuccessMessage("Doctor added successfully.");
      alert("Doctor added successfully.");
      window.location.reload(); // Refresh the page after Addition
    } catch (error) {
      console.error("Error adding doctor:", error);
      setSuccessMessage("Error adding doctor. Please try again.");
      alert("Error adding doctor. Please try again.");
    } finally {
      setLoading(false);
      setAddingDoctor(false);
    }
  };
  

  
  const handleCheckboxChange = (day) => {
    setNewDoctor((prevDoctor) => {
      const existingSlot = prevDoctor.timeSlots.find(
        (slot) => slot.day === day
      );

      if (existingSlot) {
        return {
          ...prevDoctor,
          availableDays: prevDoctor.availableDays.filter((d) => d !== day),
          timeSlots: prevDoctor.timeSlots.filter((slot) => slot.day !== day),
        };
      } else {
        return {
          ...prevDoctor,
          availableDays: [...prevDoctor.availableDays, day],
          timeSlots: [
            ...prevDoctor.timeSlots,
            { day, timeRanges: [{ startTime: "", endTime: "" }] },
          ],
        };
      }
    });
  };

  const addTimeSlot = (day) => {
    setNewDoctor((prevDoctor) => {
      const updatedTimeSlots = prevDoctor.timeSlots.map((slot) => {
        if (slot.day === day) {
          return {
            ...slot,
            timeRanges: [...slot.timeRanges, { startTime: "", endTime: "" }],
          };
        }
        return slot;
      });

      return {
        ...prevDoctor,
        timeSlots: updatedTimeSlots,
      };
    });
  };

  const removeTimeSlot = (day, index) => {
    setNewDoctor((prevDoctor) => {
      const updatedTimeSlots = prevDoctor.timeSlots.map((slot) => {
        if (slot.day === day) {
          const updatedTimeRanges = [...slot.timeRanges];
          updatedTimeRanges.splice(index, 1);
          return { ...slot, timeRanges: updatedTimeRanges };
        }
        return slot;
      });

      return {
        ...prevDoctor,
        timeSlots: updatedTimeSlots,
      };
    });
  };

  const updateStartTime = (day, index, value) => {
    setNewDoctor((prevDoctor) => {
      const updatedTimeSlots = prevDoctor.timeSlots.map((slot) => {
        if (slot.day === day) {
          const updatedTimeRanges = [...slot.timeRanges];
          updatedTimeRanges[index].startTime = value;
          return { ...slot, timeRanges: updatedTimeRanges };
        }
        return slot;
      });

      return {
        ...prevDoctor,
        timeSlots: updatedTimeSlots,
      };
    });
  };

  const handleLanguageChange = (language) => {
    setNewDoctor((prevDoctor) => {
      if (prevDoctor.languages.includes(language)) {
        return {
          ...prevDoctor,
          languages: prevDoctor.languages.filter((lang) => lang !== language),
        };
      } else {
        return {
          ...prevDoctor,
          languages: [...prevDoctor.languages, language],
        };
      }
    });
  };

  const updateEndTime = (day, index, value) => {
    setNewDoctor((prevDoctor) => {
      const updatedTimeSlots = prevDoctor.timeSlots.map((slot) => {
        if (slot.day === day) {
          const updatedTimeRanges = [...slot.timeRanges];
          updatedTimeRanges[index].endTime = value;
          return { ...slot, timeRanges: updatedTimeRanges };
        }
        return slot;
      });

      return {
        ...prevDoctor,
        timeSlots: updatedTimeSlots,
      };
    });
  };

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    setPhotoFile(file);
  };

  

  const addAreaOfExpertise = () => {
    setNewDoctor(prevDoctor => ({
      ...prevDoctor,
      areasOfExpertise: [...prevDoctor.areasOfExpertise, ""]
    }));
  };
  
  const removeAreaOfExpertise = (index) => {
    setNewDoctor(prevDoctor => ({
      ...prevDoctor,
      areasOfExpertise: prevDoctor.areasOfExpertise.filter((_, i) => i !== index)
    }));
  };
  
  const updateAreaOfExpertise = (index, value) => {
    setNewDoctor(prevDoctor => ({
      ...prevDoctor,
      areasOfExpertise: prevDoctor.areasOfExpertise.map((area, i) => 
        i === index ? value : area
      )
    }));
  };

  return (
    <div className="AD-container">
      {user ? (
        <>
          <h2 className="AD-title">Add Doctor</h2>
          {successMessage && (
            <div className="success-message">{successMessage}</div>
          )}
          <div className="AD-input-group" disabled={loading || addingDoctor}>
            {" "}
            {/* Disable input fields when loading or when adding a doctor */}
            <input
              type="text"
              className="AD-input-field"
              value={newDoctor.name}
              onChange={(e) =>
                setNewDoctor({ ...newDoctor, name: e.target.value })
              }
              placeholder="Doctor's Name"
            />
            <input
              type="text"
              className="AD-input-field"
              value={newDoctor.room}
              onChange={(e) =>
                setNewDoctor({ ...newDoctor, room: e.target.value })
              }
              placeholder="Doctor's Room"
            />
            <select
              className="AD-input-field-dep"
              value={newDoctor.departmentName}
              onChange={(e) =>
                setNewDoctor({ ...newDoctor, departmentName: e.target.value })
              }
            >
              <option value="" disabled>
                Select Department
              </option>
              {departments.map((department, index) => (
                <option key={index} value={department} className="dLLM-option">
                  {department}
                </option>
              ))}
            </select>

     <p className="AvailDays3">Please select the languages known by the doctor</p>
            <div className="AD-checkbox-group">
  {languages.map((language, index) => (
    <label key={index} className="AD-checkbox-label">
      <input
        type="checkbox"
        value={language}
        checked={newDoctor.languages.includes(language)}
        onChange={(e) => handleLanguageChange(e.target.value)}
        className="AD-checkbox"
      />
      {language}
    </label>
  ))}
</div>

<p className="AvailDays3">Enter the consulation fee in  ({hospitalData.currency}) and comments</p>

<div className="AD-input-group">

{/* <input
  type="number"
  step="0.01"
  id="consultationFee"
  className="AD-input-field"
  value={newDoctor.consultationFee || ""} // Ensure it's either a number or an empty string
  onChange={(e) =>
    setNewDoctor({
      ...newDoctor,
      consultationFee: parseFloat(e.target.value) || null, // Parse value to number or set to null if empty
    })
  }
  placeholder="Enter consultation fee"
/> */}

<input
  type="number"
  step="0.01"
  id="consultationFee"
  className="AD-input-field"
  value={newDoctor.consultationFee || ""}
  onChange={(e) => {
    const value = e.target.value;
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      setNewDoctor({
        ...newDoctor,
        consultationFee: value === "" ? null : parseFloat(value),
      });
    }
  }}
  onKeyDown={(e) => {
    // Allow: backspace, delete, tab, escape, enter, decimal point
    if (!/[\d.]/.test(e.key) && 
        e.key !== 'Backspace' && 
        e.key !== 'Delete' && 
        e.key !== 'Tab' && 
        e.key !== 'Escape' && 
        e.key !== 'Enter') {
      e.preventDefault();
    }
  }}
  placeholder={`Enter consultation fee in (${hospitalData.currency}), eg: 100.000 or 100.500`}
/>


</div>

<textarea
  className="AD-comment-group"
  placeholder="Comments for consultation fee (if any).
  E.g.: This offer price is valid until [date], or We accept insurance cards from X, Y, Z."
  
  value={newDoctor.consultationFeeComments}
  onChange={(e) =>
    setNewDoctor({ ...newDoctor, consultationFeeComments: e.target.value })
  }
/>

{/* After the consultation fee comments textarea */}

<p className="AvailDays3">Qualifications</p>
<textarea
  className="AD-comment-group"
  placeholder="Enter educational qualifications"
  value={newDoctor.educationalQualifications}
  onChange={(e) =>
    setNewDoctor({ ...newDoctor, educationalQualifications: e.target.value })
  }
/>

<p className="AvailDays3">Areas of Expertise</p>
{newDoctor.areasOfExpertise.map((area, index) => (
  <div key={index} className="AD-expertise-item">
    <input
      type="text"
      className="AD-input-field"
      value={area}
      onChange={(e) => updateAreaOfExpertise(index, e.target.value)}
      placeholder="Enter area of expertise"
    />
    <button
      className="AD-remove-btn"
      onClick={() => removeAreaOfExpertise(index)}
    >
      Remove
    </button>
  </div>
))}
<button className="AD-add-btn" onClick={addAreaOfExpertise}>
  Add Area of Expertise
</button>

<p className="AvailDays3">About Doctor</p>
<textarea
  className="AD-comment-About"
  placeholder="Enter information about the doctor"
  value={newDoctor.aboutDoctor}
  onChange={(e) =>
    setNewDoctor({ ...newDoctor, aboutDoctor: e.target.value })
  }
/>

<p className="AvailDays3">Please select the availability of the doctor for appointments and availability now</p>
            <select
              className="AD-select-field"
              value={newDoctor.availability}
              onChange={(e) =>
                setNewDoctor({ ...newDoctor, availability: e.target.value })
              }
            >
              <option value="Y" className="AD-option">
                Doctor is available now
              </option>
              <option value="X" className="AD-option">
                Doctor is not available now
              </option>
            </select>

             {/* New input field for appointment allowed */}
             <select
              className="AD-select-field"
              value={newDoctor.appointmentAllowed}
              onChange={(e) =>
                setNewDoctor({
                  ...newDoctor,
                  appointmentAllowed: e.target.value,
                })
              }
            >
              <option value="Y" className="AD-option">
                Appointments allowed
              </option>
              <option value="X" className="AD-option">
                Appointments not allowed
              </option>
            </select>


            <p className="AvailDays3">Please upload the photo of the doctor by choosing the file</p>
            <input
              type="file"
              className="AD-photo-input"
              onChange={handlePhotoUpload}
              accept="image/*"
            />
            {/* Available Days Checkbox */}
            <br />

            <p className="AvailDays3">Please select available days and corresponding timepoints for the doctor appointments</p>
            <p className="TPnote">NOTE: Please add atleast one timepoint for each available day.</p>
           
            {/* <label className="AD-checkbox-label">Please Select Available Days:</label> */}
            <div className="AD-checkbox-group">
              {daysOfWeek.map((day) => (
                <label key={day} className="AD-checkbox-label">
                  <input
                    type="checkbox"
                    value={day.toLowerCase()}
                    checked={newDoctor.availableDays.includes(day)}
                    onChange={() => handleCheckboxChange(day)}
                    className="AD-checkbox"
                  />
                  {day}
                </label>
              ))}
            </div>
            {/* Time Slots */}
            {newDoctor.timeSlots.map((timeSlot) => (
              <div key={timeSlot.day} className="AD-time-slots-container">
                <label className="AD-time-slot-label">
                  {timeSlot.day} Time Points:
                </label>
                {timeSlot.timeRanges.map((range, index) => (
                  <div key={index} className="AD-time-slot-item">
                    <label className="AD-time-label">Time Point:</label>
                    <input
                      type="time"
                      value={range.startTime}
                      onChange={(e) =>
                        updateStartTime(timeSlot.day, index, e.target.value)
                      }
                      className="AD-time-input"
                    />
                    {/* <label className="dLLM-time-label">End Time:</label>
                    <input
                      type="time"
                      value={range.endTime}
                      onChange={(e) =>
                        updateEndTime(timeSlot.day, index, e.target.value)
                      }
                      className="dLLM-time-input"
                    /> */}
                    <button
                      className="AD-remove-btn"
                      onClick={() => removeTimeSlot(timeSlot.day, index)}
                    >
                      Remove Time Point
                    </button>
                  </div>
                ))}
                <button
                  className="AD-add-btn"
                  onClick={() => addTimeSlot(timeSlot.day)}
                >
                  Add Time Point
                </button>
              </div>
            ))}
            <button
              className={`AD-add-btttn ${addingDoctor ? "disabled" : ""}`}
              onClick={addDoctor}
              disabled={loading || addingDoctor}
            >
              {" "}
              {/* Disable the button and change style when adding a doctor */}
              {loading ? "Adding Doctor...Please do not click any button or refresh the page" : "Add Doctor"}
            </button>
          </div>
          
        </>
      ) : (
        <ALogin />
      )}
    </div>
  );
};

export default DoctorsManager;
