import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import { useContext } from 'react';
import { HospitalContext } from '../ScreenForAdmin/HospitalContext';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

 

  const auth = getAuth();
  const navigate = useNavigate();

  const { updateHospitalData } = useContext(HospitalContext);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      // Sign in with email and password
      await signInWithEmailAndPassword(auth, email, password);
      setIsLoading(false);

         // Retrieve hospital data from Firestore based on the email
      const db = getFirestore();
      const hospitalQuery = query(collection(db, 'hospitals'), where('email', '==', email));
      const querySnapshot = await getDocs(hospitalQuery);

      if (!querySnapshot.empty) {
        const hospitalDoc = querySnapshot.docs[0].data();
        // Update the hospital data in the context and local storage
        updateHospitalData(hospitalDoc);
      }

      // Redirect to the dashboard page
      navigate('/VAP');

    } catch (error) {
      setIsLoading(false);
      alert('Invalid credentials. Please try again.');
      console.error('Error signing in:', error.message);
    }
  };

  return (
    <div>
      <h1>Login</h1>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Logging In...' : 'Log In'}
        </button>
      </form>
    </div>
  );
};

export default LoginPage;