import React, { useEffect, useState, useContext } from "react";
import { db } from "../../firebaseconfig";
import {
  collection,
  onSnapshot,
  doc,
  updateDoc,
  serverTimestamp,
  query,
  where,
} from "firebase/firestore";
import ALogin from "../ScreenForAdmin/ALogin";
import ALogout from "../ScreenForAdmin/ALogout";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { deleteDoc } from "firebase/firestore";
import { HospitalContext } from "../ScreenForAdmin/HospitalContext";
import { Link } from 'react-router-dom';
import StatisticsPage from "../Dashboard/Statics/Stat";
// import "./TRP.css";

const ScreenA = () => {
  const [patients, setPatients] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [user, setUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;

  const auth = getAuth();
  let whatsAppWindow = null;
  const phoneCode = hospitalData ? hospitalData.phoneCode : null;

  const generateLink = () => {
    if (HID) {
      const baseUrl = window.location.origin;
      const link = `${baseUrl}/Qrtok?HID=${HID}&phoneCode=${encodeURIComponent(
        phoneCode
      )}`;
      return link;
    }
    return "";
  };

  const openWhatsApp = (patient) => {
    const mobileNumber = patient.mobileNumber;
    const link = generateLink();
    const message = `Hello ${patient.name},\n\n` +
                    `*Your Token Number is ${patient.tokenNumber}*\n\n` +
                    `*Doctor's Name*: ${patient.doctorAssigned}\n\n` +
                    `*Doctor's Department*: ${patient.selectedDepartment}\n\n` +
                    `*Doctor's Room*: ${patient.doctorRoom}\n\n` +
                    `Please see your token status by following this link and entering your mobile number (${patient.mobileNumber})\n\n` +
                    `${link}\n\n` +
                    `in the Check Status area.\n\n` +
                    `Thank you for choosing ${hospitalData.HospName}.`;
    const isMobileDevice =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    if (isMobileDevice) {
      const whatsAppUrl = `whatsapp://send?phone=${mobileNumber}&text=${encodeURIComponent(
        message
      )}`;
      window.location.href = whatsAppUrl;
    } else {
      const whatsAppUrl = `https://web.whatsapp.com/send?phone=${mobileNumber}&text=${encodeURIComponent(
        message
      )}`;
      if (whatsAppWindow && !whatsAppWindow.closed) {
        whatsAppWindow.focus();
      } else {
        whatsAppWindow = window.open(whatsAppUrl, "_blank");
      }
    }
  };

  const sendSMS = (patient) => {
    const {
      name,
      mobileNumber,
      doctorAssigned,
      selectedDepartment,
      doctorRoom,
      tokenNumber,
    } = patient;
    const message = `Hello ${name},\n\nYour Token Number is ${tokenNumber}\n\nDoctor's Name: ${doctorAssigned}\n\nDoctor's Department: ${selectedDepartment}\n\nDoctor's Room: ${doctorRoom}\n\nThank you for choosing ${hospitalData.HospName}`;
    const mobileUrl = `sms:${mobileNumber}?body=${encodeURIComponent(message)}`;
    window.open(mobileUrl);
  };

  useEffect(() => {
    const unsubscribePatients = onSnapshot(
      collection(db, "patients"),
      (snapshot) => {
        const sortedPatients = snapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((patient) => patient.HID === HID)
          .sort((a, b) => {
            const timeA = a.requestTime ? a.requestTime.seconds : 0;
            const timeB = b.requestTime ? b.requestTime.seconds : 0;
            return timeA - timeB;
          });
        setPatients(sortedPatients);
      }
    );

    const unsubscribeDoctors = onSnapshot(
      query(collection(db, "doctors"), where("hospitalData.HID", "==", HID)),
      (snapshot) => {
        setDoctors(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      }
    );

    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribePatients();
      unsubscribeDoctors();
      unsubscribeAuth();
    };
  }, [auth, HID]);

  const updatePatientStatus = async (patientId, newStatus) => {
    const patientDocRef = doc(db, "patients", patientId);
    await updateDoc(patientDocRef, {
      status: newStatus,
    });
  };

  const assignDoctor = async (patientId, doctorId) => {
    const doctor = doctors.find((doc) => doc.id === doctorId);
    if (doctor) {
      const patientDocRef = doc(db, "patients", patientId);
      await updateDoc(patientDocRef, {
        doctorAssigned: doctor.name,
        doctorRoom: doctor.room,
      });
    }
  };

  const handleCallToReception = async (patientId) => {
    await updatePatientStatus(patientId, "B");

    const patientDocRef = doc(db, "patients", patientId);
    await updateDoc(patientDocRef, {
      tokenGenerationTime: serverTimestamp(),
    });
  };

  const handlePaymentDone = async (patient) => {
    if (!patient.doctorAssigned) {
      alert("Please select a doctor for the patient before proceeding.");
      return;
    }

    await updatePatientStatus(patient.id, "C");

    const assignedDoctor = doctors.find(
      (doctor) => doctor.name === patient.doctorAssigned
    );
    if (assignedDoctor) {
      const doctorRef = doc(db, "doctors", assignedDoctor.id);
      await updateDoc(doctorRef, { counter: assignedDoctor.counter + 1 });

      const patientDocRef = doc(db, "patients", patient.id);
      await updateDoc(patientDocRef, {
        tokenNumber: assignedDoctor.counter + 1,
        tokenGenerationTime: serverTimestamp(),
      });
    }
  };

  const renderStatus = (patient) => {
    switch (patient.status) {
      case "A":
        return "Awaiting Call to Reception";
      case "B":
        return "Called to Reception";
      case "C":
        return "Doctor Assigned & Token Generated";
      case "D":
        return "Doctor called the patient";
      case "E":
        return "Consultation Completed";
      case "F":
        return "No Show";
      default:
        return "N/A";
    }
  };

  const formatAppointmentDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const deletePatient = async (patientId) => {
    if (window.confirm("Are you sure you want to delete this patient record?")) {
      try {
        const patientDocRef = doc(db, "patients", patientId);
        await deleteDoc(patientDocRef);
        console.log("Patient record deleted successfully");
      } catch (error) {
        console.error("Error deleting patient record:", error);
      }
    }
  };

  const renderPatientAction = (patient) => {
    if (patient.tokenNumber) {
      return <span>Token Generated</span>;
    } else if (patient.status === "A") {
      return (
        <>
          <button className="TRPaction-button" onClick={() => handleCallToReception(patient.id)}>
            Call the patient to Reception
          </button>
          <button className="TRPaction-button delete-button" onClick={() => deletePatient(patient.id)}>
            Delete Request
          </button>
        </>
      );
    } else {
      return (
        <button className="TRPaction-button" onClick={() => handlePaymentDone(patient)}>
          Payment Done and Generate Token
        </button>
      );
    }
  };

  const patientsWithToken = patients
    .filter((patient) => patient.tokenNumber && patient.tokenGenerationTime)
    .sort((a, b) => {
      const timeA = a.tokenGenerationTime ? a.tokenGenerationTime.seconds : 0;
      const timeB = b.tokenGenerationTime ? b.tokenGenerationTime.seconds : 0;
      return timeB - timeA;
    });

  const patientsWithoutToken = patients
    .filter((patient) => !patient.tokenNumber)
    .sort((a, b) => {
      const timeA = a.requestTime ? a.requestTime.seconds : 0;
      const timeB = b.requestTime ? b.requestTime.seconds : 0;
      return timeA - timeB;
    });

  const patientsWithoutTokenQR = patientsWithoutToken.filter(
    (patient) =>
      patient.tag === "QR" && (patient.status === "A" || patient.status === "B")
  );

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterPatients = (patients) => {
    return patients.filter((patient) =>
      patient.mobileNumber.includes(searchTerm)
    );
  };

  return (
    <div className="TRPscreen-APWU">

<div>
        <Link to="/Dashboard" className="button2">
      Go to Dashboard
    </Link>
        </div>

      <div className="ARPSTATU">
        <StatisticsPage />
      </div>
      {user ? (
        <>
       <h3 className='ARPtitleMain'>List of Walk-In Patients to Make Payment</h3>
          <div className="ssearch-bar">
            <input
              type="text"
              placeholder={`Search by mobile number without [ ${
                hospitalData?.phoneCode || ""
              } ]`}
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          

          <div>
          {/* <h3 className='CategoryTitle'>Waiting for Token Number</h3> */}
            <div className="TRPadmin-patient-list">
              {filterPatients(patientsWithoutTokenQR).map((patient) => (
                <div key={patient.id} className="TRPtile">
                  <div className="TRPpatient-info">
                    <p>
                      <span className="field">Patient Name:</span>
                      <span className="data">{patient.name}</span>
                    </p>
                    <p>
                      <span className="field">Mobile No:</span>
                      <span className="data">{patient.mobileNumber}</span>
                    </p>
                    <p>
                      <span className="field">Token Request Date:</span>
                      <span className="data">
                        {formatAppointmentDate(patient.appointmentDate)}
                      </span>
                    </p>
                    <p>
                      <span className="field">Doctor's Department:</span>
                      <span className="data">{patient.selectedDepartment}</span>
                    </p>
                    <p>
                      <span className="field">Doctor:</span>
                      <span className="data">{patient.doctorAssigned}</span>
                    </p>
                    <p>
                      <span className="field">Status:</span>
                      <span className="data">{renderStatus(patient)}</span>
                    </p>

                    <p>
                      {renderPatientAction(patient)}
                    </p>
                    {/* <button
                      className="TRPaction-button"
                      onClick={() => openWhatsApp(patient)}
                    >
                      Send to WhatsApp
                    </button>
                    <button
                      className="TRPaction-button"
                      onClick={() => sendSMS(patient)}
                    >
                      Send SMS
                    </button> */}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
          <h3 className='CategoryTitle'>List of Patients Who Have Made Payment and Have a Token Number</h3>

            <div className="TRPadmin-patient-list">
              {filterPatients(patientsWithToken).map((patient) => (
                <div key={patient.id} className="TRPtile">
                  <div className="TRPpatient-info">
                    <p>
                      <span className="field">Patient Name:</span>
                      <span className="data">{patient.name}</span>
                    </p>
                    <p>
                      <span className="field">Mobile No:</span>
                      <span className="data">{patient.mobileNumber}</span>
                    </p>
                    <p>
                      <span className="field">Token Request Date:</span>
                      <span className="data">
                        {formatAppointmentDate(patient.appointmentDate)}
                      </span>
                    </p>
                    <p>
                      <span className="field">Doctor's Department:</span>
                      <span className="data">{patient.selectedDepartment}</span>
                    </p>
                    <p>
                      <span className="field">Doctor:</span>
                      <span className="data">{patient.doctorAssigned}</span>
                    </p>
                    <p>
                      <span className="field">Token Number:</span>
                      <span className="data">{patient.tokenNumber}</span>
                    </p>
                    <p>
                      <span className="field">Status:</span>
                      <span className="data">{renderStatus(patient)}</span>
                    </p>

                    <button
                      className="TRPaction-button"
                      onClick={() => openWhatsApp(patient)}
                    >
                      WhatsApp Token Details to the Patient
                    </button>
                    <button
                      className="TRPaction-button"
                      onClick={() => sendSMS(patient)}
                    >
                      SMS Token Details to the Patient
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <ALogin />
      )}
    </div>
  );
};

export default ScreenA;
