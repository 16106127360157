// pubsub.js
let subscribers = {};

export function subscribe(event, callback) {
  if (!subscribers[event]) {
    subscribers[event] = [];
  }
  subscribers[event].push(callback);
  return () => {
    subscribers[event] = subscribers[event].filter(sub => sub !== callback);
  };
}

export function publish(event) {
  if (subscribers[event]) {
    subscribers[event].forEach(callback => callback());
  }
}
