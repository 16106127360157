import React, { useEffect, useState, useContext } from "react";
import { db } from "../../firebaseconfig";
import { collection, onSnapshot, where, query, doc, updateDoc, serverTimestamp, writeBatch, getDocs } from "firebase/firestore";
import ALogin from "../ScreenForAdmin/ALogin";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { HospitalContext } from "../ScreenForAdmin/HospitalContext";
import BillPayingPage from "./BillPaying2KC";
import "./Billing.css";

const ScreenA = () => {
  const [consultationCount, setConsultationCount] = useState({});
  const [user, setUser] = useState(null);
  const [pfee, setPfee] = useState(0);
  const [currency, setCurrency] = useState("");
  const [grandTotal, setGrandTotal] = useState(0);
  const [grandTotalPfee, setGrandTotalPfee] = useState(0);
  const [totalKCPPatients, setTotalKCPPatients] = useState(0);
  const [grandTotalCombined, setGrandTotalCombined] = useState(0);

  const auth = getAuth();
  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;

  const fetchConsultationCount = () => {
    if (!HID) return;
  
    const unsubscribe = onSnapshot(
      query(
        collection(db, "patients"),
        where("HID", "==", HID),
        where("PKC", "==", "A")
      ),
      (snapshot) => {
        const consultationCountByDoctor = {};
        let latestPfee = 0;
        let latestCurrency = "";
        let totalGrandPayableAmount = 0;
        let totalGrandPayableAmountPfee = 0;
        let totalKCPPatientsCount = 0;
  
        snapshot.docs.forEach((doc) => {
          const patient = doc.data();
          const doctorAssigned = patient.doctorAssigned;
  
          const patientHospitalData = patient || {};
          const patientPFEE = patientHospitalData.PFEE || 0;
  
          latestPfee = patientPFEE;
          latestCurrency = patientHospitalData.currency || "";
  
          if (!consultationCountByDoctor[doctorAssigned]) {
            consultationCountByDoctor[doctorAssigned] = {
              count: 0,
              payableAmount: 0,
              payableAmountPfee: 0,
              consultationFee: Number(patient.consultationFee) || 0,
              KCC: patient.KCC || 0,
              kcpPatients: 0,
            };
          }
  
          const consultationFee = Number(patient.consultationFee) || 0;
          const KCC = patient.KCC || 0;
  
          let payableAmountPerConsultation = 0;
          let payableAmountPerConsultationPfee = 0;
  
          if (
            ["B", "C", "D", "E", "F"].includes(patient.status) &&
            patient.PKC === "A" &&
            patient.type === "KCP" &&
            patient.HID === HID
          ) {
            payableAmountPerConsultation = KCC * consultationFee;
            consultationCountByDoctor[doctorAssigned].kcpPatients++;
            totalKCPPatientsCount++;
          }
  
          if (
            ["B", "C", "D", "E", "F"].includes(patient.status) &&
            patient.PKC === "A" &&
            patient.HID === HID
          ) {
            payableAmountPerConsultationPfee = latestPfee;
            totalGrandPayableAmountPfee += payableAmountPerConsultationPfee;
          }
  
          consultationCountByDoctor[doctorAssigned].count++;
          consultationCountByDoctor[doctorAssigned].payableAmount +=
            payableAmountPerConsultation;
          consultationCountByDoctor[doctorAssigned].payableAmountPfee +=
            payableAmountPerConsultationPfee;
  
          totalGrandPayableAmount += payableAmountPerConsultation;
        });
  
        setConsultationCount(consultationCountByDoctor);
        setPfee(latestPfee);
        setCurrency(latestCurrency);
        setGrandTotal(totalGrandPayableAmount);
        setGrandTotalPfee(totalGrandPayableAmountPfee);
        setTotalKCPPatients(totalKCPPatientsCount);
        setGrandTotalCombined(
          totalGrandPayableAmount + totalGrandPayableAmountPfee
        );
      }
    );
  
    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    const unsubscribeConsultationCount = fetchConsultationCount();

    return () => {
      unsubscribeConsultationCount();
      unsubscribeAuth();
    };
  }, [auth, HID]);

  const formatWhatsAppMessage = (grandTotalCombined) => {
    let message = `Hospital Name: ${hospitalData.name}\n`;
    message += `HID: ${hospitalData.HID}\n`;
    message += `Country: ${hospitalData.country}\n`;

    Object.keys(consultationCount).forEach((monthYear) => {
      message += `${monthYear}\n`;
    });

    message += `Grand Total Payable Amount (combined): ${currency} ${grandTotalCombined.toFixed(3)}\n`;

    return message;
  };

  const openWhatsApp = (grandTotalCombined) => {
    const message = formatWhatsAppMessage(grandTotalCombined);
    const phoneNumber = "+96871198835";
    const url = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  const openWhatsAppMobile = (grandTotalCombined) => {
    const message = formatWhatsAppMessage(grandTotalCombined);
    const phoneNumber = "+96871198835";
    const url = `whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(url);
  };

  const updatePatientRecords = async () => {
    if (!HID) return;

    const patientsRef = collection(db, "patients");
    const q = query(
      patientsRef,
      where("HID", "==", HID),
      where("PKC", "==", "A"),
      where("status", "in", ["B", "C", "D", "E", "F"])
    );

    const querySnapshot = await getDocs(q);

    const batch = writeBatch(db);

    querySnapshot.forEach((docSnapshot) => {
      const docRef = doc(db, "patients", docSnapshot.id);
      batch.update(docRef, {
        PKC: "B",
        PKCtoBTimeStamp: serverTimestamp()
      });
    });

    await batch.commit();
  };

  

  return (
    <div className="BLL-billing">
      {user ? (
        <>
          <div className="BLL-content">
            <h2 className="BLL-title">{hospitalData.HospName}</h2>
            {grandTotalCombined === 0 ? (
  <p className="BLL-no-payments">No Bills</p>
) : (
  <div className="BLL-table-wrapper">
    <table className="BLL-table">
      <thead>
        <tr>
          <th>Doctor Name</th>
          <th>Total Patients</th>
          <th>
            Krysta.Care Patients
            <br/>
            <a href="https://krysta.care" className="coming-soon" target="_blank" rel="noopener noreferrer">coming soon</a>
          </th>
          <th>Consultation Fee </th>
          <th> Krysta Care Commission(%)</th>
          <th>Krysta Care Commission</th>
          <th>Platform Fee</th>
          <th>Total Payable</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(consultationCount).map(
          ([
            doctorName,
            {
              count,
              payableAmount,
              consultationFee,
              KCC,
              payableAmountPfee,
              kcpPatients,
            },
          ]) => (
            <tr key={doctorName}>
              <td>{doctorName}</td>
              <td>{count}</td>
              <td>{kcpPatients}</td>
              <td>
                {currency} {consultationFee.toFixed(2)}
              </td>
              <td>{(KCC * 100).toFixed(0)}</td>
              <td>
                {currency} {payableAmount.toFixed(3)}
              </td>
              <td>
                {currency} {payableAmountPfee.toFixed(3)}
              </td>
              <td>
                {currency}{" "}
                {(payableAmount + payableAmountPfee).toFixed(3)}
              </td>
            </tr>
          )
        )}
      </tbody>
    </table>
  </div>
)}
            {grandTotalCombined !== 0 && (
              <div className="BLL-grand-total">
                <h3 className="BLL-total-title">Krysta Care Commission</h3>
                <p className="BLL-total-amount">
                  {currency} {grandTotal.toFixed(3)}
                </p>
                <h3 className="BLL-total-title">Platform Fee</h3>
                <p className="BLL-total-amount">
                  {currency} {grandTotalPfee.toFixed(3)}
                </p>
                <h3 className="BLL-total-title">Grand Total</h3>
                <p className="BLL-total-amount">
                  {currency} {grandTotalCombined.toFixed(3)}
                </p>
                <button
                  className="BLL-pay-button"
                  onClick={async () => {
                    await updatePatientRecords();
                    // const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                    // if (/android/i.test(userAgent)) {
                    //   openWhatsAppMobile(grandTotalCombined);
                    // } else {
                    //   openWhatsApp(grandTotalCombined);
                    // }
                  }}
                >
                  Allocate to Pay
                </button>
              </div>
            )}
            
          </div>
         
          <BillPayingPage/>
        </>
        
        
      ) : (
        
        <ALogin />
      )}
    </div>
  );
};

export default ScreenA;