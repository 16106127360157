import React, { useState, useEffect, useContext } from 'react';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { HospitalContext } from './HospitalContext';
import { getFirestore, collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import './ALogin.css';

const LoginPage = () => {
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);

  const auth = getAuth();
  const navigate = useNavigate();
  const { updateHospitalData } = useContext(HospitalContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      // Sign in with email and password
      const userCredential = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);

      // Update the loading message
      setIsLoading('please wait');

      // Retrieve hospital data from Firestore based on the email
      const db = getFirestore();
      const hospitalQuery = query(collection(db, 'hospitals'), where('email', '==', loginEmail));
      const querySnapshot = await getDocs(hospitalQuery);
      if (!querySnapshot.empty) {
        const hospitalDoc = querySnapshot.docs[0].data();
        // Update the hospital data in the context and local storage
        updateHospitalData({ ...hospitalDoc, HID: userCredential.user.uid });
        // Store HID in local storage
        localStorage.setItem('HID', userCredential.user.uid);
      }

      // Redirect to the dashboard page
      navigate('/Dashboard');
    } catch (error) {
      setIsLoading(false);
      alert('Invalid credentials. Please try again.');
      console.error('Error signing in:', error.message);
    }
  };

  const handleResetPassword = async () => {
    if (!resetEmail) {
      alert('Please enter your email address');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, resetEmail);
      alert('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error.message);
      alert('Error sending password reset email. Please try again later.');
    }
  };

  return (
    <div className="Alogin-container">
      <h4 className='LoginFormTitle'>Login Form</h4>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={loginEmail}
          onChange={(e) => setLoginEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={loginPassword}
          onChange={(e) => setLoginPassword(e.target.value)}
        />
        <button type="submit" disabled={isLoading}>
          <span>
            {isLoading === true
              ? 'Logging In...'
              : isLoading === 'please wait'
              ? 'please wait'
              : 'Log In'}
          </span>
        </button>
        <button
          type="button"
          className="Aforgot-password"
          onClick={() => setIsResetPasswordModalOpen(true)}
        >
          Forgot Password?
        </button>
        <Link to="/signup" className="Asignup-link">
          Don't have an account? Sign up
        </Link>
      </form>

      {isResetPasswordModalOpen && (
        <div className="reset-password-modal">
          <input
            type="email"
            placeholder="Enter your email address"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
          />
          <button onClick={handleResetPassword}>Reset Password</button>
          <button
            className="cancel-button"
            onClick={() => setIsResetPasswordModalOpen(false)}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
