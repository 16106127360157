import React from "react";
import CreateTokenPagefrom from "./Screen4de4Admin";
import TokenRequestsandGenerated from "../TokenRequestsPage/TRP";
import StatisticsPage from "../Dashboard/Statics/Stat";


const Home = () => {
  
  return (
    <div>
      
        <>
        
          <CreateTokenPagefrom/>
          <TokenRequestsandGenerated />
        </>
    
    </div>
  );
};

export default Home;
