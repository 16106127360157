import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { db } from '../../firebaseconfig';
import { collection, query, where, onSnapshot, doc, getDoc } from 'firebase/firestore';
import QRcodeForAppointmentLink from "./QRcodeForAppointment";
import './tokenDetails.css';

const soundA = require('../ScreenForPatient/path_to_sound_A.mp3'); // Update this path

const TokenDetails = () => {
  const location = useLocation();
  const [doctors, setDoctors] = useState([]);
  const [videoLink, setVideoLink] = useState('');
  const [showVideo, setShowVideo] = useState(false);
  const [servingPatients, setServingPatients] = useState({});
  const [selectedVideoLinks, setSelectedVideoLinks] = useState([]);
  const [highlightedTokens, setHighlightedTokens] = useState({});
  const audioContextRef = useRef(null);
  const audioBufferRef = useRef(null);
  const isVibrationSupportedRef = useRef(false);

  const vibrateAndPlaySound = async () => {
    if (isVibrationSupportedRef.current && 'vibrate' in navigator) {
      navigator.vibrate(500);
    }

    if (audioBufferRef.current) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBufferRef.current;
      source.connect(audioContextRef.current.destination);
      source.start();
    }
  };

  useEffect(() => {
    const initializeAudio = async () => {
      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      }

      isVibrationSupportedRef.current = 'vibrate' in navigator;

      try {
        audioBufferRef.current = await fetch(soundA)
          .then(response => response.arrayBuffer())
          .then(buffer => audioContextRef.current.decodeAudioData(buffer));
      } catch (error) {
        console.error('Failed to load audio:', error);
      }
    };

    initializeAudio();

    const queryParams = new URLSearchParams(location.search);
    const selectedDoctors = queryParams.get('doctors')?.split(',') || [];
    const showVideo = queryParams.get('showVideo') === 'true';
    const selectedVideoLinkIds = queryParams.get('videoLinks')?.split(',') || [];

    setDoctors(selectedDoctors);
    setShowVideo(showVideo);
    setSelectedVideoLinks(selectedVideoLinkIds);

    const fetchServingPatients = () => {
      const q = query(collection(db, 'patients'), where('status', '==', 'D'));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const newServingPatients = snapshot.docs.reduce((acc, doc) => {
          const data = doc.data();
          const doctorName = data.doctorAssigned;

          if (selectedDoctors.includes(doctorName)) {
            const doctorPatients = acc[doctorName] || [];
            acc[doctorName] = [...doctorPatients, { ...data, id: doc.id }];
          }
          return acc;
        }, {});

        const newTokens = {};
        const updatedSnapshot = snapshot.docChanges().filter(change => change.type === 'added' && change.doc.data().status === 'D');

        updatedSnapshot.forEach(change => {
          const patient = change.doc.data();
          const doctorName = patient.doctorAssigned;

          if (selectedDoctors.includes(doctorName)) {
            newTokens[patient.id] = true;
            setTimeout(() => {
              setHighlightedTokens(prev => ({ ...prev, [patient.id]: false }));
            }, 20000); // Reset highlight after 20 seconds
          }
        });

        if (Object.keys(newTokens).length > 0) {
          vibrateAndPlaySound();
        }

        setHighlightedTokens(prevHighlighted => ({
          ...prevHighlighted,
          ...newTokens
        }));

        setServingPatients(newServingPatients);
      });
      return unsubscribe;
    };

    const fetchVideoLinks = async () => {
      const videoLinksCollection = collection(db, 'videoLinks');
      const videoLinkPromises = selectedVideoLinkIds
        .filter((id) => id !== '') // Filter out empty IDs
        .map(async (id) => {
          const videoLinkRef = doc(videoLinksCollection, id);
          const snapshot = await getDoc(videoLinkRef);
          if (snapshot.exists()) {
            return snapshot.data().link;
          }
          return null;
        });

      const links = await Promise.all(videoLinkPromises);
      const validLinks = links.filter((link) => link !== null);
      setVideoLink(validLinks.join(','));
    };

    const unsubscribePatients = fetchServingPatients();
    fetchVideoLinks();

    return () => {
      unsubscribePatients();
    };
  }, [location.search]);


  const EncryptName = (name) => {
    return name
      .split(' ')
      .map(word => {
        if (word.length <= 2) return word;
        const firstChar = word[0];
        const lastChar = word[word.length - 1];
        const middle = '*'.repeat(word.length - 2);
        return `${firstChar}${middle}${lastChar}`;
      })
      .join(' ');
  };


  return (
    <div className="TOK-details-container">
      <div className="TOKleft-container">
        {Object.entries(servingPatients).length > 0 ? (
          Object.entries(servingPatients).map(([doctorName, patients]) => (
            <div key={doctorName} className="TOKdoctor-container">
              <h2 className="TOKdoctor-name">{doctorName}</h2>
              <div className="TOKpatient-list">
                {patients.map((patient, index) => (
                  <div key={index} className={`TOKpatient-item ${highlightedTokens[patient.id] ? 'highlight' : ''}`}>
                    <span className="TOKtokenNum patient-infoN">
                      {patient.tokenNumber ? `Token Number: ${patient.tokenNumber}` : 'Appointment Patient'}
                    </span>
                    <span className="TOKtokenName patient-info">
                      Patient Name: <strong className="TOKtokenNameF">{EncryptName(patient.name)}</strong>
                    </span>
                    <span className="TOKdoctor-info">
                      <span className="TOKdocTok">
                        Doctor: <strong className="TOKtokenNameF">{patient.doctorAssigned}</strong>
                      </span>
                      <span className="TOKroomInfo">
                        Room: <strong className="TOKtokenNameF">{patient.doctorRoom}</strong>
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <div className="TOKno-tokens">
            <p>No Serving Tokens To Display</p>
          </div>
        )}
      </div>
      <div className="TOKright-container">
        {showVideo && (
          <div className="TOKvideo-container">
            {videoLink ? (
              <iframe
                title="Video Player"
                src={`https://www.youtube.com/embed/${videoLink}?autoplay=1&mute=1&loop=1&playlist=${videoLink}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <p>....</p>
            )}
          </div>
        )}
        
          <QRcodeForAppointmentLink/>
      
      </div>
    </div>
  );
};

export default TokenDetails;