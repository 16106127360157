import React, { useState, useEffect, useContext } from 'react';
import { db, auth } from '../../firebaseconfig';
import { collection, doc, getDoc, setDoc, updateDoc, deleteDoc, query, where, onSnapshot } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { HospitalContext } from '../ScreenForAdmin/HospitalContext';
import ALogin from '../ScreenForAdmin/ALogin';
import './VideoLinkManager.css';

const VideoLinkManager = () => {
  const [newVideoLink, setNewVideoLink] = useState('');
  const [newLinkLabel, setNewLinkLabel] = useState('');
  const [user, setUser] = useState(null);
  const [videoLinks, setVideoLinks] = useState([]);
  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    const fetchVideoLinks = async () => {
      if (HID) {
        const videoLinksCollection = collection(db, 'videoLinks');
        const q = query(videoLinksCollection, where('HID', '==', HID));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const links = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setVideoLinks(links);
        });

        return () => {
          unsubscribe();
        };
      }
    };

    fetchVideoLinks();

    return () => {
      unsubscribeAuth();
    };
  }, [HID]);

  const handleLogin = () => {
    setUser(auth.currentUser);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log('User signed out successfully');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleVideoLinkAdd = async () => {
    if (user && HID) {
      try {
        const videoId = validateAndExtractVideoId(newVideoLink);
        const videoLinksCollection = collection(db, 'videoLinks');
        await setDoc(doc(videoLinksCollection), { link: videoId, label: newLinkLabel, HID });
        setNewVideoLink('');
        setNewLinkLabel('');
        alert('Video link added successfully!');
      } catch (error) {
        alert(`Failed to add video link: ${error.message}`);
      }
    } else {
      console.warn('User or HID is undefined');
    }
  };

  const handleVideoLinkDelete = async (id) => {
    if (user && HID) {
      try {
        const videoLinkRef = doc(db, 'videoLinks', id);
        await deleteDoc(videoLinkRef);
        alert('Video link deleted successfully!');
      } catch (error) {
        alert(`Failed to delete video link: ${error.message}`);
      }
    } else {
      console.warn('User or HID is undefined');
    }
  };

  const validateAndExtractVideoId = (link) => {
    const regex = /^(?:(?:https?:\/\/)?(?:www\.)?youtu\.be\/|https?:\/\/(?:www\.)?(?:m\.)?youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)|.*[?&]v=))([^"&?/ ]{11})/;
    const match = link.match(regex);
    if (match) {
      return match[1];
    } else {
      throw new Error('Invalid YouTube video link. Please enter a valid link.');
    }
  };

  return (
    <div className="VLYTB-videoLinkManager">
      {user ? (
        <>
          <h2>Manage YouTube Videos - Played on Right Side of TV screen</h2>
    
          {/* <button className="VLYTB-logout-btn" onClick={handleLogout}>
            Logout
          </button> */}
          <div className="VLYTB-add-link-container">
            <input
              type="text"
              value={newVideoLink}
              onChange={(e) => setNewVideoLink(e.target.value)}
              placeholder="Enter YouTube video link from your browser's address bar ONLY"
            />
            <input
              type="text"
              value={newLinkLabel}
              onChange={(e) => setNewLinkLabel(e.target.value)}
              placeholder="Enter label or name for the above entered YouTube video link"
            />
            <button onClick={handleVideoLinkAdd}>Add Video Link</button>
          </div>
          <p className='VLYTB-GuideLine'>
  Guidelines for uploading YouTube video links <br/><br/>
  1. Copy and paste the YouTube video link directly from your browser's address bar.
  <br/>
  2. Avoid using the link obtained from the share button located below the YouTube video.
  <br/>
  3. Ideal video link will look like this: https://www.youtube.com/watch?v=0diO_ncnpNs
</p>

          <div className="VLYTB-video-links-container">
            <h3>List of stored YouTube video links</h3>
            {videoLinks.map((link) => (
              <div key={link.id} className="VLYTB-video-link-item">
                {/* <span>{link.label}: {link.link}</span> */}
                <span>Name of the YouTube video link: {link.label}</span>
                <button onClick={() => handleVideoLinkDelete(link.id)}>Delete</button>
              </div>
            ))}
          </div>
        </>
      ) : (
        <ALogin onLogin={handleLogin} />
      )}
    </div>
  );
};

export default VideoLinkManager;