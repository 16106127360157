import React, { useEffect, useState, useContext} from 'react';
import { HospitalContext } from '../ScreenForAdmin/HospitalContext';
import { db, auth } from '../../firebaseconfig';
import { collection, onSnapshot, query, where, doc, updateDoc } from 'firebase/firestore';
import { publish } from '../../services/pubsub';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import ALogin from '../ScreenForAdmin/ALogin';
import './screenD.css';

const ScreenD = () => {
  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;

  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [doctors, setDoctors] = useState([]);
  const [patients, setPatients] = useState([]);
  const [consultedPatients, setConsultedPatients] = useState([]);
  const [noShowPatients, setNoShowPatients] = useState([]);
  const [selectedDoctorAvailability, setSelectedDoctorAvailability] = useState('');
  const [user, setUser] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());

  const [selectedDoctorData, setSelectedDoctorData] = useState(null);

  useEffect(() => {
    if (selectedDoctor) {
      const doctorData = doctors.find(doctor => doctor.name === selectedDoctor);
      setSelectedDoctorData(doctorData);
    }
  }, [selectedDoctor, doctors]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatDate = (date) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const formatTime = (time) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return time.toLocaleTimeString('en-US', options);
  };

  const calculateTimeRemaining = (appointmentTime) => {
    const timeDiff = appointmentTime.getTime() - currentTime.getTime();
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    const unsubscribeDoctors = onSnapshot(
      query(collection(db, "doctors"), where("hospitalData.HID", "==", HID)),
      (snapshot) => {
        setDoctors(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      }
    );

    const storedSelectedDoctor = localStorage.getItem('selectedDoctor');
    if (storedSelectedDoctor) {
      setSelectedDoctor(storedSelectedDoctor);
    }

    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => {
      unsubscribeDoctors();
      unsubscribeAuth();
    };
  }, []);

  useEffect(() => {
    if (selectedDoctor && user) {
      const q = query(collection(db, 'patients'), where('doctorAssigned', '==', selectedDoctor));
      const unsubscribePatients = onSnapshot(q, (snapshot) => {
        const allPatients = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const sortedPatients = allPatients.sort((a, b) => {
          if (a.tokenNumber === 1) return -1;
          if (b.tokenNumber === 1) return 1;
          return a.tokenNumber - b.tokenNumber;
        });
        const activePatients = sortedPatients.filter(p => p.status !== 'E' && p.status !== 'F');
        const consulted = sortedPatients.filter(p => p.status === 'E');
        const noShow = sortedPatients.filter(p => p.status === 'F');
        setPatients(activePatients);
        setConsultedPatients(consulted);
        setNoShowPatients(noShow);

        const selectedDoctorData = doctors.find(doctor => doctor.name === selectedDoctor);
        setSelectedDoctorAvailability(selectedDoctorData?.availability || '');
      });

      localStorage.setItem('selectedDoctor', selectedDoctor);

      return () => unsubscribePatients();
    }
  }, [selectedDoctor, doctors, user]);

  // const handleUpdateStatus = async (patientId, newStatus) => {
  //   if (user) {
  //     const patientDocRef = doc(db, 'patients', patientId);
      
  //     const doctorData = doctors.find(doctor => doctor.name === selectedDoctor);
      
  //     const data = {
  //       status: newStatus,
  //       PKC: newStatus === 'E' ? 'A' : '',
  //       consultedAt: newStatus === 'E' ? new Date().toISOString() : '',
  //       doctorAssigned: selectedDoctor,
  //       doctorRoom: doctorData ? doctorData.room : '',
  //       hospitalData: newStatus === 'E' ? {
  //         ...hospitalData,
  //         PFEE: doctorData ? doctorData.hospitalData.PFEE : null,
  //         consultationFee: doctorData ? doctorData.consultationFee : null,
  //         KCC: newStatus === 'E' ? (doctorData ? doctorData.hospitalData.KCC : null) : null,
  //       } : null,
  //     };
  
  //     await updateDoc(patientDocRef, data);
  //     if (newStatus === 'D') {
  //       publish('patientCalled');
  //     }
  //   }
  // };


  // This version will update the patient record with all components of hospitalData 
  //except for KCC, PFEE, consultationFee, and consultationFeeComments when the "Consulted" button is clicked. 
  //It will preserve any existing values for these fields in the patient record.

  const handleUpdateStatus = async (patientId, newStatus) => {
    if (user) {
      const patientDocRef = doc(db, 'patients', patientId);
      
      const doctorData = doctors.find(doctor => doctor.name === selectedDoctor);
      
      const data = {
        status: newStatus,
        PKC: newStatus === 'E' ? 'A' : '',
        consultedAt: newStatus === 'E' ? new Date().toISOString() : '',
        doctorAssigned: selectedDoctor,
        doctorRoom: doctorData ? doctorData.room : '',
      };
  
      if (newStatus === 'E') {
        // Only update hospitalData fields that are not KCC, PFEE, consultationFee, or consultationFeeComments
        const updatedHospitalData = Object.entries(hospitalData).reduce((acc, [key, value]) => {
          if (!['KCC', 'PFEE', 'consultationFee', 'consultationFeeComments'].includes(key)) {
            acc[key] = value;
          }
          return acc;
        }, {});
  
        // Add the updated hospitalData to the patient record
        data.hospitalData = updatedHospitalData;
      }
  
      await updateDoc(patientDocRef, data);
      if (newStatus === 'D') {
        publish('patientCalled');
      }
    }
  };

  const renderPatientAction = (patient) => {
    if (patient.status === 'C') {
      return <button className='DV-button' onClick={() => handleUpdateStatus(patient.id, 'D')}>Call Patient</button>;
    } else if (patient.status === 'D') {
      return (
        <>
        <div className='DVbuttons'>
          <button className='DV-button' onClick={() => handleUpdateStatus(patient.id, 'E')}>Consulted</button>
          <button className='DV-buttonNN' onClick={() => handleUpdateStatus(patient.id, 'F')}>No Show</button>
        </div>
        </>
      );
    }
  };

  const renderPatientList = (patientList) => {
    const appointmentsPatients = patientList.filter(patient => patient.tag === 'PWU');
    const today = new Date();
    const appointmentsToday = appointmentsPatients.filter(patient => {
      const appointmentDate = new Date(patient.selectedTimeslot);
      return (
        appointmentDate.getDate() === today.getDate() &&
        appointmentDate.getMonth() === today.getMonth() &&
        appointmentDate.getFullYear() === today.getFullYear()
      );
    });

    // Categorize appointments
    const category1 = [];
    const category2 = [];

    appointmentsToday.forEach(patient => {
      const appointmentTime = new Date(patient.selectedTimeslot);
      const timeDiff = (appointmentTime.getTime() - currentTime.getTime()) / (1000 * 60); // difference in minutes

      if (timeDiff <= 15) {
        category1.push(patient);
      } else {
        category2.push(patient);
      }
    });

    // Sort both categories by appointment time
    const sortByTime = (a, b) => new Date(a.selectedTimeslot).getTime() - new Date(b.selectedTimeslot).getTime();
    category1.sort(sortByTime);
    category2.sort(sortByTime);

    const walkinPatients = patientList.filter(patient => patient.tokenNumber);




    if (category1.length === 0 && category2.length === 0 && walkinPatients.length === 0) {
      return <p className="DV-no-patients">No patients awaiting consultation</p>;
    }





    return (
      <>
        {/* <h3>Patients List</h3> */}
        
        {category1.length > 0 && (
          <>
            <h4>Appointment Patients - Category 1 (Consultation within 15 minutes or less)</h4>
            <div className="DV-patient-section">
              <div className="DV-patient-header">
                <span>Name</span>
                <span>Appointment Time</span>
                <span>Time Remaining</span>
                <span>Action</span>
              </div>
              {category1.map((patient) => (
                <div
                  className={`DV-patient-row ${
                    (new Date(patient.selectedTimeslot).getTime() - currentTime.getTime()) <= 0 ? 'negative-time' : ''
                  }`}
                  key={patient.id}
                >
                  <span>{patient.name}</span>
                  <span>{formatTime(new Date(patient.selectedTimeslot))}</span>
                  <span>{calculateTimeRemaining(new Date(patient.selectedTimeslot))}</span>
                  <span>{renderPatientAction(patient)}</span>
                </div>
              ))}
            </div>
          </>
        )}
        
        {walkinPatients.length > 0 && (
          <>
            <h4>Walk-In Patients</h4>
            <div className="DV-patient-section">
              <div className="DV-patient-header">
                <span>Name</span>
                <span>Token</span>
                <span>Action</span>
              </div>
              {walkinPatients.map((patient) => (
                <div className="DV-patient-row" key={patient.id}>
                  <span>{patient.name}</span>
                  <span>{patient.tokenNumber}</span>
                  <span>{renderPatientAction(patient)}</span>
                </div>
              ))}
            </div>
          </>
        )}

        {category2.length > 0 && (
          <>
            <h4>Appointment Patients - Category 2 (More than 15 minutes away to consult)</h4>

            <div className="DV-patient-section">
              <div className="DV-patient-header">
                <span>Name</span>
                <span>Appointment Time</span>
                <span>Time Remaining</span>
                <span>Action</span>
              </div>
              {category2.map((patient) => (
                <div className="DV-patient-row" key={patient.id}>
                  <span>{patient.name}</span>
                  <span>{formatTime(new Date(patient.selectedTimeslot))}</span>
                  <span>{calculateTimeRemaining(new Date(patient.selectedTimeslot))}</span>
                  <span>{renderPatientAction(patient)}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </>
    );
  };

  const handleDoctorAvailability = async (doctorId) => {
    if (user) {
      const selectedDoctorObject = doctors.find(doctor => doctor.name === selectedDoctor);
      if (selectedDoctorObject) {
        const doctorRef = doc(db, 'doctors', doctorId);
        const newAvailability = selectedDoctorAvailability === 'Y' ? 'X' : 'Y';
        await updateDoc(doctorRef, { availability: newAvailability });
        setSelectedDoctorAvailability(newAvailability);
      } else {
        console.error('No doctor found with the selected name');
      }
    }
  };

  const handleLogin = () => {
    setUser(auth.currentUser);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log('User signed out successfully');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="DV-screen-d-container">
      <div className="DV-screen-d">
        <div className="current-date-time">{formatDate(currentTime)} {formatTime(currentTime)}</div>
        {user ? (
          <>
            <h2 className='DV-dHead'>Patient Calling Module</h2>
            
            <select onChange={(e) => setSelectedDoctor(e.target.value)} value={selectedDoctor}>
              <option value="">Select Doctor</option>
              {doctors.map((doctor) => (
                <option key={doctor.id} value={doctor.name}>
                  {doctor.name}
                </option>
              ))}
            </select>

            {selectedDoctorData && (
              <div className="DV-doctor-info">
                {/* <img src={selectedDoctorData.photoURL} alt={selectedDoctorData.name} className="DV-doctor-photo" /> */}

                {selectedDoctorData.photoURL ? (
  <img src={selectedDoctorData.photoURL} alt={selectedDoctorData.name} className="DV-doctor-photo" />
) : (
  <div className="DV-no-photo">No Photo</div>
)}
                <div className="DV-doctor-details">
                  <h3 className="DV-doctor-name">{selectedDoctorData.name}</h3>
                  <p className="DV-doctor-department">{selectedDoctorData.departmentName}</p>
                </div>
              </div>
            )}
    
            {selectedDoctor && (
              <div className="DV-doctor-availability">
                <h3>Doctor Availability</h3>
                <label className="DV-toggle-switch">
                  <input 
                    type="checkbox" 
                    checked={selectedDoctorAvailability === 'Y'} 
                    onChange={() => handleDoctorAvailability(doctors.find(doctor => doctor.name === selectedDoctor).id)}
                  />
                  <span className="DV-slider"></span>
                </label>
              </div>
            )}
    
            {renderPatientList(patients)}

            <div>
              <button className="DV-logout-btn" onClick={handleLogout}>
                Logout
              </button>
            </div>

            <div>
    <a href='/All-Appointments' class='P4H-UPAlink'>View Upcoming Appointments</a>
</div>

            
          </>
        ) : (
          <ALogin onLogin={handleLogin} />
        )}
      </div>
    </div>
  );
};

export default ScreenD;