import React, { useEffect, useState } from "react";
import { db } from "../../firebaseconfig";
import {
  collection,
  addDoc,
  doc,
  query,
  where,
  onSnapshot,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import soundA from "./Sound2.mp3";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import "./screenP.css";

const ScreenP = () => {
  const [hids, setHids] = useState([]);

  const [HID, setHid] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [existingMobileNumber, setExistingMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [patientId, setPatientId] = useState(null);
  const [patientDetails, setPatientDetails] = useState(null);
  const [nowServingToken, setNowServingToken] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [proceedClicked, setProceedClicked] = useState(false);

  const [availableDepartments, setAvailableDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());

  const [phoneCode, setPhoneCode] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hidValue = urlParams.get("HID");
    const phoneCodeValue = urlParams.get("phoneCode");
    setHid(hidValue);
    setPhoneCode(decodeURIComponent(phoneCodeValue) || ""); // Decode and set the phoneCode
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hidValue = urlParams.get("HID");
    setHid(hidValue);
  }, []);

  const fetchHIDs = async () => {
    try {
      const doctorsRef = collection(db, "doctors");
      const querySnapshot = await getDocs(doctorsRef);

      const ids = new Set();
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.hospitalData && data.hospitalData.HID) {
          ids.add(data.hospitalData.HID);
        }
      });

      setHids(Array.from(ids));
    } catch (error) {
      console.error("Error fetching HID values:", error);
    }
  };

  useEffect(() => {
    fetchHIDs();
  }, []);

  const fetchDepartmentsAndDoctors = () => {
    const departmentsQuery = query(
      collection(db, "doctors"),
      where("hospitalData.HID", "==", HID)
    );

    const unsubscribeDepartments = onSnapshot(departmentsQuery, (snapshot) => {
      console.log("Snapshot size:", snapshot.size);
      const departments = new Set();
      snapshot.forEach((doc) => {
        const doctorData = doc.data();
        if (doctorData.departmentName) {
          departments.add(doctorData.departmentName);
        }
      });
      setAvailableDepartments(Array.from(departments));
    });

    return () => unsubscribeDepartments();
  };

  useEffect(() => {
    const unsubscribe = fetchDepartmentsAndDoctors();

    return () => unsubscribe();
  }, [HID]);

  const fetchDoctorsByDepartment = async (department) => {
    const doctorsQuery = query(
      collection(db, "doctors"),
      where("departmentName", "==", department),
      where("availability", "==", "Y"),
      where("hospitalData.HID", "==", HID)
    );

    const unsubscribeDoctors = onSnapshot(doctorsQuery, (snapshot) => {
      const doctors = snapshot.docs.map((doc) => doc.data());
      setDoctorsList(doctors);
    });

    return () => unsubscribeDoctors();
  };

  const handleDepartmentChange = (event) => {
    const selectedDepartment = event.target.value;
    setSelectedDepartment(selectedDepartment);
    setSelectedDoctor("");
    const unsubscribe = fetchDoctorsByDepartment(selectedDepartment);

    return () => unsubscribe();
  };

  const handleDoctorChange = (event) => {
    const selectedDoctor = event.target.value;
    setSelectedDoctor(selectedDoctor);
  };

  useEffect(() => {
    fetchDepartmentsAndDoctors();
  }, [HID]);

  useEffect(() => {
    if (patientId) {
      const patientRef = doc(db, "patients", patientId);

      const unsubscribe = onSnapshot(patientRef, (doc) => {
        if (doc.exists()) {
          const patientData = doc.data();
          setPatientDetails(patientData);

          if (patientData.status !== status) {
            setStatus(patientData.status);
            vibrateAndPlaySound();
          }

          if (
            patientData.doctorAssigned &&
            ["C", "D", "E", "F"].includes(patientData.status)
          ) {
            const servingTokenQuery = query(
              collection(db, "patients"),
              where("status", "==", "D"),
              where("doctorAssigned", "==", patientData.doctorAssigned)
            );
            const unsubscribeServingToken = onSnapshot(
              servingTokenQuery,
              (snapshot) => {
                const serving = snapshot.docs
                  .map((doc) => doc.data())
                  .find((p) => p.doctorAssigned === patientData.doctorAssigned);
                if (serving) {
                  setNowServingToken(serving.tokenNumber);
                } else {
                  setNowServingToken("");
                }
              }
            );

            return () => unsubscribeServingToken();
          }
        }
      });

      return () => unsubscribe();
    }
  }, [patientId, status]);

  const vibrateAndPlaySound = () => {
    if ("vibrate" in navigator) {
      navigator.vibrate(500);
    }
    const audio = new Audio(soundA);
    audio
      .play()
      .catch((error) => console.error("Audio playback failed:", error));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (!mobileNumber || !name || !selectedDepartment || !selectedDoctor) {
      alert("Please fill in all the required fields.");
      return;
    }

    try {
      setProceedClicked(true);
      setIsProcessing(true);

      const selectedDoctorData = doctorsList.find(
        (doctor) => doctor.name === selectedDoctor
      );

      // Extract the required fields from the selected doctor's data
    const { KCC, PFEE, HospName, currency } = selectedDoctorData.hospitalData || {};
    const { consultationFee, consultationFeeComments } = selectedDoctorData || {};

      const docRef = await addDoc(collection(db, "patients"), {
        mobileNumber: `${phoneCode}${mobileNumber}`,
        name,
        status: "A",
        PKC:"A",
        requestTime: new Date(),
        tag: "QR",
        selectedDepartment,
        selectedDoctor: selectedDoctor,
        doctorAssigned: selectedDoctor,
        doctorRoom: selectedDoctorData.room, // Include doctor's room
        appointmentDate: new Date().toISOString(), // Current appointment date
        HID, // Include the HID value here
        // Add the new fields here
      KCC: KCC || 0,
      PFEE: PFEE || 0,
      consultationFee: consultationFee || "",
      consultationFeeComments: consultationFeeComments || "",
      currency: currency || "",
      hospitalName: HospName || "",
      });

      setPatientId(docRef.id);
      setStatus("A");
      vibrateAndPlaySound();
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setIsProcessing(false);
    }
  };

  // Modify fetchLatestToken to include phoneCode when searching
  const fetchLatestToken = async () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const fullMobileNumber = `${phoneCode}${existingMobileNumber}`; // Add this line
    const patientsQuery = query(
      collection(db, "patients"),
      where("mobileNumber", "==", fullMobileNumber), // Use fullMobileNumber here
      where("requestTime", ">=", today),
      orderBy("requestTime", "desc"),
      limit(1)
    );

    const querySnapshot = await getDocs(patientsQuery);
    const latestPatient = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))[0];

    if (latestPatient) {
      setPatientDetails(latestPatient);
      setPatientId(latestPatient.id);
      setStatus(latestPatient.status);
      setName(latestPatient.name);
      setMobileNumber(latestPatient.mobileNumber);
      setSelectedDepartment(latestPatient.selectedDepartment);
      setSelectedDoctor(latestPatient.selectedDoctor);
    } else {
      alert("No token found for the given mobile number today.");
    }
  };

  const takeScreenshot = () => {
    const patientDetailsElement = document.querySelector(".patient-details");
    domtoimage
      .toBlob(patientDetailsElement)
      .then(function (blob) {
        saveAs(blob, "token-details.png");
      })
      .catch(function (error) {
        console.error("Error taking screenshot:", error);
      });
  };

  const displayMessage = () => {
    if (!patientDetails) return null;

    let messageClass = "message " + "status-" + patientDetails.status;
    switch (patientDetails.status) {
      case "A":
        return (
          <div className={messageClass}>
            Kindly wait. <br />
            We are processing your request
          </div>
        );
      case "B":
        return (
          <div className={messageClass}>
            Kindly go to the Reception to make the payment.
          </div>
        );
      case "C":
        return (
          <div className="message-container">
            <span className="token-number">
              Your Token Number: {patientDetails.tokenNumber}
            </span>
            <br />

            <span className="doctor-room">
              Doctor's Room: {patientDetails.doctorRoom}
            </span>
            <br />
            <span className="doctor-assigned">
              Doctor Assigned: {patientDetails.doctorAssigned}
            </span>
            <br />
            <div className="wait-message">Wait for your Token Number</div>

            {/* <div className='now-serving-token'>Now Serving Token: {nowServingToken}</div> */}

            <div className="now-serving-token">
              {nowServingToken
                ? `Now Serving Token: ${nowServingToken}`
                : "Now Serving Appointment Patient"}
            </div>

            <div className="messageAPT">
              NOTE: Please click on the button below to view the upcoming
              scheduled appointments by selecting the name of the assigned
              doctor. Your token number will be called during the available gaps
              between the scheduled appointment time points. Kindly wait for
              your token number
            </div>
            <div>
              {/* <a href="/A4Ad" className="button-APTVW">
                Check
              </a> */}

{/* <a href={`/A4Ad?HID=${HID}`} className="button-APTVW">
  Check
</a> */}

{/* <a 
  href={`/A4Ad?HID=${HID}`} 
  className="button-APTVW" 
  target="_blank" 
  rel="noopener noreferrer"
>
  Check
</a> */}

<a 
  href={`/A4Ad?HID=${HID}&doctor=${encodeURIComponent(patientDetails.doctorAssigned)}`} 
  className="button-APTVW" 
  target="_blank" 
  rel="noopener noreferrer"
>
  Check
</a>
            </div>

            {/* <button onClick={takeScreenshot}>Take Screenshot</button> */}
          </div>
        );

      case "D":
        return (
          <div className={messageClass}>
            <span className="token-number">
              Your Token Number {patientDetails.tokenNumber} is called now
            </span>
            <br />
            {/* <p className='called'>The doctor has called you.</p> */}
            <p className="wait-message">Please go to {patientDetails.doctorAssigned} <br/>Room {patientDetails.doctorRoom} now</p>

            {/* <p className="wait-message">
              
              <br />
              <br />
              Doctor's Room: {patientDetails.doctorRoom}
            </p> */}

            <div className="now-servingNOW-token">
              Now Serving Token: {nowServingToken}
            </div>
          </div>
        );

      case "E":
        return (
          <div className={`messageConsulted ${messageClass}`}>
            <span className="token-number">
              {" "}
              Your Token Number: {patientDetails.tokenNumber}
            </span>
            <br />
            <br />
            <span className="consultation-message">
              Consultation Completed{" "}
            </span>
            <br />
            <br />
            <strong className="DRCONSUL">Doctor consulted:</strong>{" "}
<span className="DRCONSUL">{patientDetails.doctorAssigned}</span>

            <br />
            <br />
            <span className="thank-you">Thank you for choosing us!</span>
            <br />
            <br />
            <div>
              {" "}
              {/* <a href="/A4Ad" className="button-APTVW">
                Book another appointment
              </a> */}
            </div>
          </div>
        );


        case "X":
          return (
            <div className={`messageConsulted ${messageClass}`}>
              
              <span className="thank-you">Your request was deleted. Please contact the Reception</span>
             
              
            </div>
          );


      case "F":
        return (
          <div className={messageClass}>
            You didn't show up for the consultation. Please contact the
            Reception.
            <br />
            <br />
            <div>
              {" "}
              {/* <a href="/A4Ad" className="button-APTVW">
                Book An Appointment
              </a> */}
            </div>
          </div>
        );
      default:
        return <div className={messageClass}>Status not recognized.</div>;
    }
  };

  return (
    <div className="screenP-container">
      <h3 className="APTLINK-heading">Token Number Request Form</h3>
      {patientId ? (
        <div className="patient-details">
          {/* <h3 className='detailsBelow'>Please find the details below</h3> */}

          <p class="SP-P1-info">
            <strong class="SP-P1-label">Your Name:</strong>{" "}
            <span class="SP-P1-name">{name}</span>
          </p>
          <p class="SP-P1-info">
            <strong class="SP-P1-label">Your Mobile Number:</strong>{" "}
            <span class="SP-P1-mobile">{mobileNumber}</span>
          </p>
          <p class="SP-P1-info">
            <strong class="SP-P1-label">Selected Department:</strong>{" "}
            <span class="SP-P1-department">{selectedDepartment}</span>
          </p>
          <p class="SP-P1-info">
            <strong class="SP-P1-label">Selected Doctor:</strong>{" "}
            <span class="SP-P1-doctor">{selectedDoctor}</span>
          </p>

          {displayMessage()}
        </div>
      ) : (
        <div>
          <form className="form-container" onSubmit={handleSubmit}>
            {/* <p><strong className='APPDATE'>Appointment Date: {currentDate.toDateString()}</strong></p> */}
            {/* <input
              className="name-input-field"
              type="text"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              placeholder={`Enter your mobile number without ${phoneCode}`}
              required
            /> */}

<input
                className="name-input-field"
                type="text"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                onBlur={(e) => {
                  const pattern =
                    phoneCode === "+91"
                      ? /^\d{10}$/
                      : phoneCode === "+968"
                      ? /^\d{8}$/
                      : null;
                  if (pattern && !pattern.test(e.target.value)) {
                    setMobileNumberError(
                      phoneCode === "+91"
                        ? "Please enter exactly 10 digits for the mobile number"
                        : "Please enter exactly 8 digits for the mobile number"
                    );
                  } else {
                    setMobileNumberError("");
                  }
                }}
                placeholder={`Your mobile number without ${phoneCode}`}
                required
                pattern={
                  phoneCode === "+91"
                    ? "\\d{10}"
                    : phoneCode === "+968"
                    ? "\\d{8}"
                    : null
                }
                title={
                  phoneCode === "+91"
                    ? "Please enter exactly 10 digits for the mobile number"
                    : phoneCode === "+968"
                    ? "Please enter exactly 8 digits for the mobile number"
                    : "Please enter a valid mobile number"
                }
              />
              {mobileNumberError && (
                <p className="error-message">{mobileNumberError}</p>
              )}
            







            <input
              className="name-input-field"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Name of the patient"
              required
            />

            <select
              className="dept-input-field"
              value={selectedDepartment}
              onChange={(e) => handleDepartmentChange(e)}
            >
              <option value="" disabled>
                Select Department
              </option>
              {availableDepartments.map((department, index) => (
                <option key={index} value={department}>
                  {department}
                </option>
              ))}
            </select>

            <select
              className="dept-input-field"
              value={selectedDoctor}
              onChange={(e) => handleDoctorChange(e)}
            >
              <option value="" disabled>
                Select available doctors from the list
              </option>
              {doctorsList.map((doctor, index) => (
                <option key={index} value={doctor.name}>
                  {doctor.name}
                </option>
              ))}
            </select>

            {/* For displaying available doctors */}

            {/* {selectedDepartment && doctorsList.length > 0 && (
              <div>
                <h3>Doctors in {selectedDepartment}</h3>
                <ul>
                  {doctorsList.map((doctor, index) => (
                    <li key={index}>
                      {doctor.name} - Room {doctor.room}
                    </li>
                  ))}
                </ul>
              </div>
            )} */}

            {!proceedClicked && (
              <button
                className="token-proceed-button"
                type="submit"
                disabled={isProcessing} // Disable the button if processing
              >
                {isProcessing ? "Processing" : "Proceed"}
              </button>
            )}
          </form>
          {proceedClicked && isProcessing && (
            <div className="processing-message">Processing...</div>
          )}
          <div className="form-container">
            <p className="alreadytext">
              Already have a token? <br />
              Enter your mobile number to check status.
            </p>

            <input
              className="name-input-field"
              type="text"
              value={existingMobileNumber}
              onChange={(e) => setExistingMobileNumber(e.target.value)}
              placeholder={`Enter your mobile number without ${phoneCode}`}
            />
            <button className="checkk-status-button" onClick={fetchLatestToken}>
              Check Token Status
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScreenP;
