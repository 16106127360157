import React, { useContext, useEffect, useState } from 'react';
import './AppBar.css';
import { db } from '../../firebaseconfig'; // Import the firebase config
import { collection, query, where, getDocs } from 'firebase/firestore';
import { HospitalContext } from '../ScreenForAdmin/HospitalContext';
import { Link } from 'react-router-dom';
import krystaLogo from './KrystaLogo.png';

const AppBar = () => {
  const { hospitalData } = useContext(HospitalContext);
  const [HID, setHid] = useState("");
  const [hospitalLogo, setHospitalLogo] = useState("");
  const [hospitalName, setHospitalName] = useState("");

  useEffect(() => {
    // Fetch HID from URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const hidValue = urlParams.get("HID");
    if (hidValue) {
      setHid(hidValue);
      fetchHospitalDataByHID(hidValue);
    }
  }, []);

  const fetchHospitalDataByHID = async (hid) => {
    try {
      const hospitalsRef = collection(db, "hospitals");
      const hospitalsQuery = query(hospitalsRef, where("HID", "==", hid));
      const querySnapshot = await getDocs(hospitalsQuery);

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.logoURL) {
          setHospitalLogo(data.logoURL);
        }
        if (data.name) {
          setHospitalName(data.name);
        }
      });
    } catch (error) {
      console.error("Error fetching hospital data:", error);
    }
  };

  return (
    <div className="app-container">
      <div className="app-bar">
        <div className="app-logo">
          {hospitalLogo ? (
            <img src={hospitalLogo} alt="Hospital Logo" />
          ) : (
            hospitalData && hospitalData.logoURL && (
              <Link to="/Dashboard"> 
              <img src={hospitalData.logoURL} alt="Hospital Logo" />
              </Link>
            )
          )}
          {/* <div>
            <h1 className="AppBartitle">
              {hospitalName || (hospitalData && hospitalData.name) || ''}
            </h1>
            {HID && <div>HID: {HID}</div>}
          </div> */}
        </div>
        <div>
        <a href="https://app.krysta.care" className="krysta" rel="noopener noreferrer">
            krysta.care
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppBar;
