import React, { useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, collection, addDoc, getDocs, doc, getDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useNavigate, Link } from 'react-router-dom';
import Geolocation from 'react-native-geolocation-service';
import './ASignUp.css';

const SignupPage = () => {
  const [formData, setFormData] = useState({
    hospitalName: '',
    address: '',
    email: '',
    password: '',
    country: '',
    state: '',
    governorate: '',
    district: '',
    place: '',
    contactPerson: '',
    telephoneNumber: '',
    contactNumber: '',
    website: '',
    location: '',
  });
  const [logoFile, setLogoFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [governorates, setGovernorates] = useState([]);
  const [selectedCountryDetails, setSelectedCountryDetails] = useState({});
  const [error, setError] = useState(null);

  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchCollection = async (collectionName) => {
          const collectionRef = collection(db, collectionName);
          const snapshot = await getDocs(collectionRef);
          return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        };

        const [countriesData, statesData, governoratesData] = await Promise.all([
          fetchCollection('countries'),
          fetchCollection('states'),
          fetchCollection('governorates'),
        ]);

        setCountries(countriesData);
        setStates(statesData);
        setGovernorates(governoratesData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load necessary data. Please try again later.');
      }
    };

    fetchData();
  }, [db]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
    setError(null);
  };

  const handleCountryChange = async (selectedCountry) => {
    setFormData(prevData => ({
      ...prevData,
      country: selectedCountry,
      state: '',
      governorate: '',
      district: '',
    }));

    const countryDoc = countries.find(c => c.name === selectedCountry);
    if (countryDoc) {
      try {
        const docRef = doc(db, 'countries', countryDoc.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setSelectedCountryDetails(docSnap.data());
        } else {
          setSelectedCountryDetails({});
        }
      } catch (error) {
        console.error('Error fetching country details:', error);
        setError('Failed to fetch country details. Please try again.');
      }
    } else {
      setSelectedCountryDetails({});
    }
  };

  const handleLogoUpload = async (file) => {
    if (!file) return null;
    const storageRef = ref(storage, `logos/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    
    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Handle progress
        },
        (error) => reject(error),
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  };

  // const validateForm = () => {
  //   const { hospitalName, address, email, password, country, state, governorate, district, place, contactPerson, telephoneNumber, contactNumber, location } = formData;
    
  //   const isMandatoryFieldsFilled =
  //     hospitalName && address && email && password && country &&
  //     ((country === 'India' && state && (state !== 'Kerala' || (state === 'Kerala' && district))) || 
  //      (country === 'Oman' && governorate)) &&
  //     place && contactPerson && telephoneNumber && contactNumber && location && logoFile;

  //   if (!isMandatoryFieldsFilled) {
  //     setError('Please fill all mandatory fields.');
  //     return false;
  //   }
  //   return true;
  // };


// Mandatory fields define

  const validateForm = () => {
    const { hospitalName, address, email, password, country, state, governorate, district, place, contactPerson, telephoneNumber, contactNumber, location } = formData;
    
    const isMandatoryFieldsFilled =
      hospitalName && address && email && password && country &&
      ((country === 'India' && state && (state !== 'Kerala' || (state === 'Kerala' && district))) || 
       (country === 'Oman' && governorate)) &&
      place && contactPerson && contactNumber && location ;

    if (!isMandatoryFieldsFilled) {
      setError('Please fill all mandatory fields.');
      return false;
    }
    return true;
  };



  const handleSignup = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    setError(null);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      const user = userCredential.user;

      const logoURL = await handleLogoUpload(logoFile);

      const selectedCountry = countries.find(c => c.name === formData.country);
      const phoneCode = selectedCountry?.phoneCode || '';

      const hospitalData = {
        HospName: formData.hospitalName,
        address: formData.address,
        email: formData.email,
        HID: user.uid,
        country: formData.country,
        state: formData.state,
        governorate: formData.governorate,
        district: formData.district,
        place: formData.place,
        contactPerson: formData.contactPerson,
        contactNumber: `${phoneCode}${formData.contactNumber}`,
        telephoneNumber: `${phoneCode}${formData.telephoneNumber}`,
        website: formData.website,
        location: formData.location,
        logoURL,
        VERIF: 'X',
        currency: selectedCountryDetails.currency,
        phoneCode: selectedCountryDetails.phoneCode,
        PFEE: selectedCountryDetails.PFEE,
        KCC: selectedCountryDetails.KCC,
        createdAt: serverTimestamp(),
      };

      await addDoc(collection(db, 'hospitals'), hospitalData);

      alert('Registration successful! Please login using your entered email ID and Password');
      navigate('/login');
    } catch (error) {
      console.error('Registration error:', error);
      let errorMessage = 'An error occurred during registration.';
      
      if (error.code) {
        switch (error.code) {
          case 'auth/email-already-in-use':
            errorMessage = 'This email is already registered. Please use a different email or try logging in.';
            break;
          case 'auth/invalid-email':
            errorMessage = 'The email address is not valid.';
            break;
          case 'auth/weak-password':
            errorMessage = 'The password is too weak. Please use a stronger password.';
            break;
          default:
            errorMessage = `Registration failed: ${error.message}`;
        }
      }
      
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchCoordinates = () => {
    Geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setFormData(prevData => ({ ...prevData, location: `${latitude},${longitude}` }));
      },
      (error) => {
        console.error('Error fetching coordinates:', error);
        setError('Failed to fetch coordinates. Please enter them manually.');
      },
      { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
    );
  };

  const keralaDists = [
    'Alappuzha', 'Ernakulam', 'Idukki', 'Kannur', 'Kasaragod', 'Kollam', 'Kottayam', 'Kozhikode',
    'Malappuram', 'Palakkad', 'Pathanamthitta', 'Thiruvananthapuram', 'Thrissur', 'Wayanad'
  ];

  return (
    <div className="signup-container">
      <h1 className="signup-header">Hospital or Clinic Registration</h1>
      {error && <div className="error-message">{error}</div>}
      <form className="signup-form" onSubmit={handleSignup}>
        <input
          className="signup-input"
          type="text"
          name="hospitalName"
          placeholder="Hospital or Clinic Name"
          value={formData.hospitalName}
          onChange={handleInputChange}
        />


<div className="photoUpload">Upload the logo of the hospital or clinic</div>
        <input
          className="signup-input"
          type="file"
          accept="image/*"
          onChange={(e) => setLogoFile(e.target.files[0])}
        />


        
        <select
          className="signup-input"
          name="country"
          value={formData.country}
          onChange={(e) => handleCountryChange(e.target.value)}
        >
          <option value="">Select Country</option>
          {countries.map((country) => (
            <option key={country.code} value={country.name}>
              {country.name}
            </option>
          ))}
        </select>
        {formData.country === 'India' && (
          <select
            className="signup-input"
            name="state"
            value={formData.state}
            onChange={handleInputChange}
          >
            <option value="">Select State</option>
            {states.map((state) => (
              <option key={state.code} value={state.name}>
                {state.name}
              </option>
            ))}
          </select>
        )}
        {formData.country === 'India' && formData.state === 'Kerala' && (
          <select
            className="signup-input"
            name="district"
            value={formData.district}
            onChange={handleInputChange}
          >
            <option value="">Select District</option>
            {keralaDists.map((dist) => (
              <option key={dist} value={dist}>
                {dist}
              </option>
            ))}
          </select>
        )}
        {formData.country === 'Oman' && (
          <select
            className="signup-input"
            name="governorate"
            value={formData.governorate}
            onChange={handleInputChange}
          >
            <option value="">Select Governorate</option>
            {governorates.map((governorate) => (
              <option key={governorate.code} value={governorate.name}>
                {governorate.name}
              </option>
            ))}
          </select>
        )}
        <input
          className="signup-input"
          type="text"
          name="place"
          placeholder="Place"
          value={formData.place}
          onChange={handleInputChange}
        />
        <input
          className="signup-input"
          type="text"
          name="address"
          placeholder="Address"
          value={formData.address}
          onChange={handleInputChange}
        />
        <input
          className="signup-input"
          type="text"
          name="contactPerson"
          placeholder="Contact Person"
          value={formData.contactPerson}
          onChange={handleInputChange}
        />
        <input
          className="signup-input"
          type="text"
          name="contactNumber"
          placeholder={`Contact no: without country code${selectedCountryDetails.phoneCode ? ` ${selectedCountryDetails.phoneCode}` : ''}`}
          value={formData.contactNumber}
          onChange={handleInputChange}
        />
        <input
          className="signup-input"
          type="text"
          name="telephoneNumber"
          placeholder={`Telephone no: without country code${selectedCountryDetails.phoneCode ? ` ${selectedCountryDetails.phoneCode}` : ''}`}
          value={formData.telephoneNumber}
          onChange={handleInputChange}
        />
        <input
          className="signup-input"
          type="text"
          name="website"
          placeholder="Website (If available)"
          value={formData.website}
          onChange={handleInputChange}
        />
        <div className="photoUpload">Click Get Location button to get the location of the hospital or clinic</div>
        <div className="signup-coordinates">
          <input
            className="signup-input signup-input-coordinates"
            type="text"
            name="location"
            placeholder="12.345,67.890"
            value={formData.location}
            onChange={handleInputChange}
          />
          <button
            className="fetchCord"
            type="button"
            onClick={handleFetchCoordinates}
          >
            Get Location
          </button>
        </div>


       


        <div className="photoUpload">NOTE: This email ID and password will be used for login</div>
        <input
          className="signup-input"
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleInputChange}
        />
        <input
          className="signup-input"
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleInputChange}
        />
        <button
          className="signup-button"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? 'Registering...Please Wait' : 'Register'}
        </button>
        <Link to="/login" className="Asignup-link">
          Already have an account? Log in
        </Link>
      </form>
    </div>
  );
};

export default SignupPage;