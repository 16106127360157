import React, { useEffect, useState } from "react";
import { db } from "../../firebaseconfig";
import {
  collection,
  addDoc,
  doc,
  query,
  where,
  onSnapshot,
  getDocs,
  setDoc,
  orderBy,
  serverTimestamp,
  writeBatch,
  updateDoc,
  limit,
} from "firebase/firestore";

import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";

import { useContext } from "react";
import { HospitalContext } from "../ScreenForAdmin/HospitalContext";
import { Link } from "react-router-dom";
import soundA from "./Sound2.mp3";

import "./ATDE.css";

const ScreenP = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [existingMobileNumber, setExistingMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [patientId, setPatientId] = useState(null);
  const [patientDetails, setPatientDetails] = useState(null);
  const [nowServingToken, setNowServingToken] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [proceedClicked, setProceedClicked] = useState(false);

  const [availableDepartments, setAvailableDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);

  const [currentDate, setCurrentDate] = useState(null); // Initialize currentDate state to null

  const [selectedDoctorAvailableDays, setSelectedDoctorAvailableDays] =
    useState([]);

  const [isCheckSuccessful, setIsCheckSuccessful] = useState(false); // Define isCheckSuccessful state
  const [availableTimeslots, setAvailableTimeslots] = useState([]); // Define availableTimeslots state
  const [isChecking, setIsChecking] = useState(false); // New state to track if checking is in progress

  const [isChecked, setIsChecked] = useState(false);

  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;

  const [consultationFee, setConsultationFee] = useState("");
  const [currency, setCurrency] = useState("");
  const [consultationFeeComments, setConsultationFeeComments] = useState("");

  const [mobileNumberError, setMobileNumberError] = useState("");

  useEffect(() => {
    setSelectedTimeslot(null); // Reset selected timeslot when date changes and show Proceed button
  }, [currentDate]);

  const fetchDepartments = async () => {
    const departmentsQuery = query(
      collection(db, "doctors"),
      where("hospitalData.HID", "==", HID) // Filter by HID
    );
    const querySnapshot = await getDocs(departmentsQuery);
    const departments = new Set();
    querySnapshot.forEach((doc) => {
      const doctorData = doc.data();
      if (doctorData.departmentName) {
        departments.add(doctorData.departmentName);
      }
    });
    return Array.from(departments);
  };

  const [hasCheckedOnce, setHasCheckedOnce] = useState(false); // New state to track if "Check" button has been clicked at least once

  const handleCheckAvailability = async (e) => {
    e.preventDefault();
    try {
      setIsProcessing(true);
      setIsChecking(true);
      setIsCheckSuccessful(false);
      setAvailableTimeslots([]);
      setSelectedTimeslot(null);
      setIsChecked(true);
      setHasCheckedOnce(true);

      const selectedDate = new Date(currentDate.toISOString());
      selectedDate.setHours(0, 0, 0, 0);

      // Create or update the appointment record
      const appointmentRecord = await createNewAppointmentRecord(selectedDate);

      // Filter and set available timeslots
      const availableTimeslots = appointmentRecord.availableTimeslots
        .filter((slot) => slot.status === "Y")
        .map((slot) => slot.startTime);

      setAvailableTimeslots(availableTimeslots);
      setIsCheckSuccessful(true);
    } catch (error) {
      console.error("Error checking availability: ", error);
    } finally {
      setIsChecking(false);
      setIsProcessing(false);
    }
  };

  // Add this useEffect hook to reset available timeslots when the date changes
  useEffect(() => {
    setAvailableTimeslots([]);
  }, [currentDate]);

  // Add this useEffect hook to create a new appointment record when date changes
  useEffect(() => {
    const handleDateChange = async () => {
      if (selectedDoctor && currentDate) {
        const selectedDate = new Date(currentDate.toISOString());
        selectedDate.setHours(0, 0, 0, 0);

        const appointmentsQuery = query(
          collection(db, "Appointments"),
          where("date", "==", selectedDate.toISOString()),
          where("doctor", "==", selectedDoctor)
        );
        const appointmentsSnapshot = await getDocs(appointmentsQuery);
        const existingAppointment = appointmentsSnapshot.docs[0];

        if (!existingAppointment) {
          await createNewAppointmentRecord(selectedDate);
        }
      }
    };

    handleDateChange();
  }, [selectedDoctor, currentDate]); // Run this effect whenever selectedDoctor or currentDate changes

  const createNewAppointmentRecord = async (selectedDate) => {
    const selectedDoctorData = doctorsList.find(
      (doctor) => doctor.name === selectedDoctor
    );

    // Check for existing appointment with doctor, HID, and department
    const existingAppointmentQuery = query(
      collection(db, "Appointments"),
      where("date", "==", selectedDate.toISOString()),
      where("doctor", "==", selectedDoctor),
      where("HID", "==", HID),
      where("department", "==", selectedDepartment)
    );
    const existingAppointmentSnapshot = await getDocs(existingAppointmentQuery);

    let appointmentRef;
    let existingTimeslots = [];

    if (!existingAppointmentSnapshot.empty) {
      appointmentRef = doc(
        db,
        "Appointments",
        existingAppointmentSnapshot.docs[0].id
      );
      existingTimeslots =
        existingAppointmentSnapshot.docs[0].data().availableTimeslots;
    } else {
      appointmentRef = doc(collection(db, "Appointments"));
    }

    const fetchedAvailableTimeslots =
      selectedDoctorData.timeSlots.find(
        (slot) =>
          slot.day.toLowerCase() ===
          selectedDate
            .toLocaleDateString("en-US", { weekday: "long" })
            .toLowerCase()
      )?.timeRanges || [];

    const updatedTimeslots = fetchedAvailableTimeslots.map((slot) => {
      const existingSlot = existingTimeslots.find(
        (es) => es.startTime === slot.startTime
      );
      return {
        startTime: slot.startTime,
        status: existingSlot ? existingSlot.status : "Y",
      };
    });

    const appointmentData = {
      HID: HID,
      doctor: selectedDoctor,
      department: selectedDepartment,
      date: selectedDate.toISOString(),
      availableTimeslots: updatedTimeslots,
    };

    await setDoc(appointmentRef, appointmentData, { merge: true });

    return appointmentData;
  };

  const checkDoctorAvailability = (doctorName, selectedDate) => {
    const doctor = doctorsList.find((doctor) => doctor.name === doctorName);
    if (doctor) {
      const dayOfWeek = selectedDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      return doctor.availableDays.includes(dayOfWeek);
    }
    return false;
  };

  const fetchDepartmentsAndDoctors = () => {
    const departmentsQuery = query(
      collection(db, "doctors"),
      where("hospitalData.HID", "==", HID)
    );

    const unsubscribeDepartments = onSnapshot(departmentsQuery, (snapshot) => {
      console.log("Snapshot size:", snapshot.size);
      const departments = new Set();
      snapshot.forEach((doc) => {
        const doctorData = doc.data();
        if (doctorData.departmentName) {
          departments.add(doctorData.departmentName);
        }
      });
      setAvailableDepartments(Array.from(departments));
    });

    return () => unsubscribeDepartments();
  };

  useEffect(() => {
    const unsubscribe = fetchDepartmentsAndDoctors();

    return () => unsubscribe();
  }, [HID]);

  const fetchDoctorsByDepartment = async (department) => {
    const doctorsQuery = query(
      collection(db, "doctors"),
      where("departmentName", "==", department),
      where("availability", "==", "Y"),
      where("appointmentAllowed", "!=", "X"), // Add this condition
      where("hospitalData.HID", "==", HID)
    );

    const unsubscribeDoctors = onSnapshot(doctorsQuery, (snapshot) => {
      const doctors = snapshot.docs.map((doc) => doc.data());
      setDoctorsList(doctors);
    });

    return () => unsubscribeDoctors();
  };

  const handleDepartmentChange = (event) => {
    const selectedDepartment = event.target.value;
    setSelectedDepartment(selectedDepartment);
    setSelectedDoctor(""); // Reset the selected doctor
    setSelectedTimeslot(null); // Reset the selected timeslot
    setAvailableTimeslots([]); // Reset available timeslots
    setIsChecked(false); // Reset isChecked
    setSelectedDoctorAvailableDays([]); // Reset available days
    setCurrentDate(null); // Reset selected date

    const unsubscribe = fetchDoctorsByDepartment(selectedDepartment);

    // Check if there are any available doctors for the selected department
    if (unsubscribe.length === 0) {
      setDoctorsList([]);
      alert("No doctors available for the selected department.");
    }

    // Cleanup function to unsubscribe from the previous snapshot listener - for realtime update
    return () => unsubscribe();
  };

  const handleDoctorChange = (event) => {
    const selectedDoctor = event.target.value;
    setSelectedDoctor(selectedDoctor);

    const doctor = doctorsList.find((doctor) => doctor.name === selectedDoctor);

    if (doctor) {
      setSelectedDoctor(selectedDoctor);
      setSelectedDoctorAvailableDays(doctor.availableDays);
      setSelectedTimeslot(null);
      setAvailableTimeslots([]);
      setIsChecked(false);
      setCurrentDate(null);
      setConsultationFee(doctor.consultationFee || "");
      setConsultationFeeComments(doctor.consultationFeeComments || "");
      setCurrency(doctor.hospitalData?.currency || ""); // Set the currency from hospitalData
    }
  };

  // Add this to reset isChecked when date changes
  useEffect(() => {
    setIsChecked(false);
  }, [currentDate]);

  useEffect(() => {
    fetchDepartmentsAndDoctors();
  }, []);

  useEffect(() => {
    if (patientId) {
      const patientRef = doc(db, "patients", patientId);

      const unsubscribe = onSnapshot(patientRef, (doc) => {
        if (doc.exists()) {
          const patientData = doc.data();
          setPatientDetails(patientData);

          if (patientData.status !== status) {
            setStatus(patientData.status);
            vibrateAndPlaySound();
          }

          if (
            patientData.doctorAssigned &&
            ["C", "D", "E", "F"].includes(patientData.status)
          ) {
            const servingTokenQuery = query(
              collection(db, "patients"),
              where("status", "==", "D"),
              where("doctorAssigned", "==", patientData.doctorAssigned)
            );
            const unsubscribeServingToken = onSnapshot(
              servingTokenQuery,
              (snapshot) => {
                const serving = snapshot.docs
                  .map((doc) => doc.data())
                  .find((p) => p.doctorAssigned === patientData.doctorAssigned);
                if (serving) {
                  setNowServingToken(serving.tokenNumber);
                } else {
                  setNowServingToken("");
                }
              }
            );

            return () => unsubscribeServingToken();
          }
        }
      });

      return () => unsubscribe();
    }
  }, [patientId, status]);

  const vibrateAndPlaySound = () => {
    if ("vibrate" in navigator) {
      navigator.vibrate(500);
    }
    const audio = new Audio(soundA);
    audio
      .play()
      .catch((error) => console.error("Audio playback failed:", error));
  };

  const formatTime = (time) => {
    if (!time || typeof time !== "string" || !time.includes(":")) {
      // Handle the case when time is undefined, not a string, or doesn't contain a colon
      return "";
    }

    const [hours, minutes] = time.split(":");
    const period = parseInt(hours, 10) >= 12 ? "PM" : "AM";
    const formattedHours = (((parseInt(hours, 10) + 11) % 12) + 1).toString();
    return `${formattedHours}:${minutes} ${period}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setProceedClicked(true);
      setIsProcessing(true);

      console.log("Selected Timeslot:", selectedTimeslot);

      const selectedDoctorData = doctorsList.find(
        (doctor) => doctor.name === selectedDoctor
      );

      const selectedDate = new Date(currentDate.toISOString());
      selectedDate.setHours(0, 0, 0, 0);
      const appointmentsQuery = query(
        collection(db, "Appointments"),
        where("date", "==", selectedDate.toISOString()),
        where("doctor", "==", selectedDoctor)
      );
      const appointmentsSnapshot = await getDocs(appointmentsQuery);
      const existingAppointment = appointmentsSnapshot.docs[0];
      const appointmentId = existingAppointment ? existingAppointment.id : null;

      const apid = `${
        appointmentId || "new"
      }_${selectedDate.toISOString()}_${selectedDoctor}_${selectedTimeslot}`;
      const phoneCode = hospitalData ? hospitalData.phoneCode : "";

       // Extract additional doctor details
    const { KCC, PFEE, HospName} = selectedDoctorData.hospitalData || {};

      const docRef = await addDoc(collection(db, "patients"), {
        mobileNumber: `${phoneCode}${mobileNumber}`,
        name,
        status: "B",
        PKC:"A",
        requestTime: new Date(),
        tag: "PWU",
        type: "ATDE",
        selectedDepartment,
        doctorAssigned: selectedDoctor,
        doctorRoom: selectedDoctorData?.room || "",
        appointmentDate: currentDate.toISOString(),
        selectedTimeslot: selectedTimeslot || "",
        HID,
        APID: apid,
        tokenGenerationTime: serverTimestamp(),
        consultationFee,
        consultationFeeComments,
        currency, // Add the currency to the patient record

        // Add the new doctor-related fields here
      KCC: KCC || 0,
      PFEE: PFEE || 0,
      hospitalName: HospName || "",

      });

      setPatientId(docRef.id);
      setStatus("B");
      vibrateAndPlaySound();

      // Update the status of the selected timeslot to 'X'
      if (existingAppointment) {
        const existingAppointmentRef = doc(
          db,
          "Appointments",
          existingAppointment.id
        );
        const existingAppointmentData = existingAppointment.data();
        const updatedAvailableTimeslots =
          existingAppointmentData.availableTimeslots.map((slot) => ({
            ...slot,
            status: slot.startTime === selectedTimeslot ? "X" : slot.status,
          }));
        await updateDoc(existingAppointmentRef, {
          availableTimeslots: updatedAvailableTimeslots,
        });
      }
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const fetchLatestToken = async () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const patientsQuery = query(
      collection(db, "patients"),
      where("mobileNumber", "==", existingMobileNumber),
      where("requestTime", ">=", today),
      orderBy("requestTime", "desc"),
      limit(1)
    );

    const querySnapshot = await getDocs(patientsQuery);
    const latestPatient = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))[0];

    if (latestPatient) {
      setPatientDetails(latestPatient);
      setPatientId(latestPatient.id);
      setStatus(latestPatient.status);
      setName(latestPatient.name);
      setMobileNumber(latestPatient.mobileNumber);
    } else {
      alert("No token found for the given mobile number today.");
    }
  };

  const takeScreenshot = () => {
    const patientDetailsElement = document.querySelector(".patient-details");
    domtoimage
      .toBlob(patientDetailsElement)
      .then(function (blob) {
        saveAs(blob, "token-details.png");
      })
      .catch(function (error) {
        console.error("Error taking screenshot:", error);
      });
  };

  const displayMessage = () => {
    if (!patientDetails) return null;

    let messageClass = "message " + "status-" + patientDetails.status;
    switch (patientDetails.status) {
      // case 'A':
      //   return <div className={messageClass}> Appointment request created  <br/> <br/> Please click the below button to approve it
      //   <br/>

      //   <a href="/Admin" className="button-style">View All Appointment Requests</a>

      //   <p></p>

      //   <a href="/MakeAppointment" className="button-style">Create another appointment</a>

      //   </div>;
      case "B":
        return (
          <div className={messageClass}>
            <a href="/MakeAppointment" className="button-style">
              Create another appointment
            </a>
          </div>
        );
      case "C":
        return (
          <div className={messageClass}>
            {/* <span className="token-number">Your Token Number: {patientDetails.tokenNumber}</span><br />
            Doctor Assigned: {patientDetails.doctorAssigned}<br />
            Doctor's Room: {patientDetails.doctorRoom}<br />
            <div>Wait for your Token Number</div> */}
            <a href="/MakeAppointment" className="button-style">
              Create another appointment
            </a>
            {/* <div className='nowServingToken'>Now Serving Token: {nowServingToken}</div> */}
          </div>
        );
      case "D":
        return (
          <div className={messageClass}>
            {/* <span className="token-number">Your Token Number: {patientDetails.tokenNumber}</span><br />
                  <p>The doctor has called you.</p>
                  <p>Please go to the Doctor's room now</p> */}
            <a href="/MakeAppointment" className="button-style">
              Create another appointment
            </a>

            {/* <p>
                     
                      Doctor Assigned: {patientDetails.doctorAssigned}<br />
                      Doctor's Room: {patientDetails.doctorRoom}
                  </p>
                  
                  <div className='nowServingToken'>
                      Now Serving Token: {nowServingToken}
                  </div> */}
          </div>
        );

      case "E":
        return (
          <div className={messageClass}>
            {/* <span className="token-number">Your Token Number: {patientDetails.tokenNumber}</span><br />
          
          Consultation Completed. */}

            <a href="/MakeAppointment" className="button-style">
              Create another appointment
            </a>
          </div>
        );
      case "F":
        return (
          <div className={messageClass}>
            <a href="/MakeAppointment" className="button-style">
              Create another appointment
            </a>
          </div>
        );
      default:
        return (
          <div className={messageClass}>
            <a href="/MakeAppointment" className="button-style">
              Create another appointment
            </a>
          </div>
        );
    }
  };

  const formatAppointmentDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatTimeSlot = (timeSlot) => {
    if (!timeSlot) return ""; // Return an empty string if timeSlot is undefined
    const [start, end] = timeSlot.split("-");
    return `${formatTime(start)}  ${formatTime(end)}`;
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return ""; // Return an empty string if timestamp is undefined

    const date = new Date(timestamp.seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return date.toLocaleString("en-US", options);
  };

  const { location } = hospitalData || {};

  const getGoogleMapsUrl = (latitude, longitude) => {
    if (!latitude || !longitude) return "";
    return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
  };

  const shareOnWhatsApp = (patientDetails) => {
    const {
      name,
      mobileNumber,
      selectedDepartment,
      doctorAssigned,
      doctorRoom,
      appointmentDate,
      selectedTimeslot,
      currency,
      consultationFee,
    } = patientDetails;
    const formattedDate = formatAppointmentDate(appointmentDate);
    const formattedTimeSlot = formatTimeSlot(selectedTimeslot);
    const locationUrl = getGoogleMapsUrl(
      hospitalData?.location?.split(",")[0],
      hospitalData?.location?.split(",")[1]
    );

    const message = `Hello ${name},
  
  Your appointment is confirmed and the details are as follows:
  
  Name of the patient: ${name}
  Mobile Number used for appointment: ${mobileNumber}
  Selected Department: ${selectedDepartment}
  Assigned Doctor: ${doctorAssigned}
  Doctor's Room: ${doctorRoom}
  Appointment Date: ${formattedDate}
  Appointment Time: ${formattedTimeSlot}
  Consultation Fee: ${currency}${consultationFee}

  Please reach ${hospitalData.HospName} 15-20 minutes prior appointment time to make the payment.
  
  You can view your latest appointment status by visiting https://partner.krysta.care/AppointmentStatus
  
  Location of ${hospitalData.HospName}: ${locationUrl}
  
  Thank you for choosing ${hospitalData.HospName}.`;

    const encodedMessage = encodeURIComponent(message);
    const whatsappWebUrl = `https://web.whatsapp.com/send?phone=${mobileNumber}&text=${encodedMessage}`;
    const whatsappMobileUrl = `whatsapp://send?phone=${mobileNumber}&text=${encodedMessage}`;

    // Check if the device is a mobile device (phone or tablet)
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    if (isMobile) {
      // Open the WhatsApp mobile app directly
      window.open(whatsappMobileUrl, "_blank");
    } else {
      // Open WhatsApp Web
      window.open(whatsappWebUrl, "_blank");
    }
  };

  const sendSMS = (patientDetails) => {
    const {
      name,
      mobileNumber,
      selectedDepartment,
      doctorAssigned,
      doctorRoom,
      appointmentDate,
      selectedTimeslot,
      currency,
      consultationFee,
    } = patientDetails;
    const formattedDate = formatAppointmentDate(appointmentDate);
    const formattedTimeSlot = formatTimeSlot(selectedTimeslot);
    const locationUrl = getGoogleMapsUrl(
      hospitalData?.location?.split(",")[0],
      hospitalData?.location?.split(",")[1]
    );

    const message = `Hello ${name},
  
  Your appointment is confirmed and the details are as follows:
  
  Name of the patient: ${name}
  Mobile Number used for appointment registration: ${mobileNumber}
  Selected Department: ${selectedDepartment}
  Assigned Doctor: ${doctorAssigned}
  Doctor's Room: ${doctorRoom}
  Appointment Date: ${formattedDate}
  Appointment Time: ${formattedTimeSlot}
  Consultation Fee: ${currency}${consultationFee}

  Please reach ${hospitalData.HospName} 15-20 minutes prior appointment time to make the payment.
  
  You can view your latest appointment status by visiting https://partner.krysta.care/AppointmentStatus
  
  Location of ${hospitalData.HospName}: ${locationUrl}
  
  Thank you for choosing ${hospitalData.HospName}.`;

    const url = `sms:${mobileNumber}?body=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  return (
    <div className="WU-screenP-container">
      {patientId ? (
        <div className="WU-patient-details">
          <div className="tick-mark">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              viewBox="0 0 24 24"
            >
              <circle cx="12" cy="12" r="10" />
              <path d="M17 7L9 15L7 13" />
            </svg>
          </div>
          <h2 className="titleP">Appointment created</h2>

          {patientDetails && (
            <div>
              <p>
                <strong>Name of the Patient:</strong> {patientDetails.name}
              </p>
              <p>
                <strong>Mobile number of the patient:</strong>{" "}
                {patientDetails.mobileNumber}
              </p>
              <p>
                <strong>Selected Department:</strong>{" "}
                {patientDetails.selectedDepartment}
              </p>
              <p>
                <strong>Assigned Doctor:</strong>{" "}
                {patientDetails.doctorAssigned}
              </p>
              <p>
                <strong>Doctor's Room:</strong> {patientDetails.doctorRoom}
              </p>
              <p>
                <strong>Appointment Date:</strong>{" "}
                {formatAppointmentDate(patientDetails.appointmentDate)}
              </p>
              <p>
                <strong>Appointment Time:</strong>{" "}
                {formatTimeSlot(patientDetails.selectedTimeslot)}
              </p>

              <br />
              <p>
                <strong>Appointment Creation Time:</strong>{" "}
                {formatTimestamp(patientDetails.tokenGenerationTime)}
              </p>

              <div className="share-buttons">
                <button
                  className="check-button2"
                  onClick={() => shareOnWhatsApp(patientDetails)}
                >
                  Share on WhatsApp
                </button>
                <div className="tooltip">
                  <button
                    className="check-button2"
                    onClick={() => sendSMS(patientDetails)}
                  >
                    Send SMS
                  </button>
                  <div className="tooltiptext">
                    Install Google Messages for web or similar to send SMS from
                    a laptop or desktop computer
                  </div>
                </div>
              </div>
            </div>
          )}

          {displayMessage()}
        </div>
      ) : (
        <div>
          <h2 className="APTLINK-heading">Create Appointment</h2>

          {availableDepartments.length === 0 && (
            <div className="AvailDays3">
              <p>
                It looks like there are no departments and doctors available in
                your profile. Please add the same by following the below link
              </p>
              <Link className="linkStyle2" to="/AddDoctor">
                Add Doctor Details Link
              </Link>
            </div>
          )}

          <form className="AP-form-container" onSubmit={handleSubmit}>
            <div className="conditional-fields">
              {/* <input
                className="name-input-field"
                type="text"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                placeholder={`Your mobile number without ${hospitalData.phoneCode}`}
                required // Add 'required' attribute to make it mandatory
              /> */}

              <input
                className="name-input-field"
                type="text"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                onBlur={(e) => {
                  const pattern =
                    hospitalData.phoneCode === "+91"
                      ? /^\d{10}$/
                      : hospitalData.phoneCode === "+968"
                      ? /^\d{8}$/
                      : null;
                  if (pattern && !pattern.test(e.target.value)) {
                    setMobileNumberError(
                      hospitalData.phoneCode === "+91"
                        ? "Please enter exactly 10 digits for the mobile number"
                        : "Please enter exactly 8 digits for the mobile number"
                    );
                  } else {
                    setMobileNumberError("");
                  }
                }}
                placeholder={`Your mobile number without ${hospitalData.phoneCode}`}
                required
                pattern={
                  hospitalData.phoneCode === "+91"
                    ? "\\d{10}"
                    : hospitalData.phoneCode === "+968"
                    ? "\\d{8}"
                    : null
                }
                title={
                  hospitalData.phoneCode === "+91"
                    ? "Please enter exactly 10 digits for the mobile number"
                    : hospitalData.phoneCode === "+968"
                    ? "Please enter exactly 8 digits for the mobile number"
                    : "Please enter a valid mobile number"
                }
              />
              {mobileNumberError && (
                <p className="error-message">{mobileNumberError}</p>
              )}

              

              <input
                className="name-input-field"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name of the patient"
                required // Add 'required' attribute to make it mandatory
              />

              {/* {!mobileNumber || !name && (
  <p>Please enter your name and 8-digit mobile number.</p>
)} */}

              <select
                className="dept-input-field"
                value={selectedDepartment}
                onChange={(e) => handleDepartmentChange(e)}
                required // Add 'required' attribute to make it mandatory
              >
                <option value="" disabled>
                  Select Department
                </option>
                {availableDepartments.map((department, index) => (
                  <option key={index} value={department}>
                    {department}
                  </option>
                ))}
              </select>

              <select
                className="dept-input-field"
                value={selectedDoctor}
                onChange={(e) => handleDoctorChange(e)}
              >
                <option value="" disabled>
                  Select Doctor
                </option>
                {doctorsList.length === 0 ? (
                  <option disabled>
                    No doctors available for appointments. Please contact the
                    Reception
                  </option>
                ) : (
                  doctorsList.map((doctor, index) => (
                    <option key={index} value={doctor.name}>
                      {doctor.name}
                    </option>
                  ))
                )}
              </select>

              {selectedDoctor && (
                <div className="selected-infoAP">
                  <p>Selected Doctor: {selectedDoctor}</p>
                  <p>
                    Consultation Fee: {currency} {consultationFee}
                  </p>
                  {consultationFeeComments && (
                    <p>Comments: {consultationFeeComments}</p>
                  )}
                  <h3 className="AvailDays77">
                    Please see the available days below
                  </h3>
                  <ul className="timeslot-option2">
                    {selectedDoctorAvailableDays.map((day, index) => (
                      <li className="timeslot-label2" key={index}>
                        {day}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {selectedDoctor && (
                <div className="selectAPdtae">
                  <label>
                    Select the appointment date based on the available days:
                  </label>

                  <input
                    type="date"
                    className="APPselex"
                    value={
                      currentDate ? currentDate.toISOString().split("T")[0] : ""
                    }
                    min={new Date().toISOString().split("T")[0]}
                    onChange={(e) =>
                      setCurrentDate(
                        e.target.value ? new Date(e.target.value) : null
                      )
                    }
                  />
                </div>
              )}

              {selectedDoctor && currentDate && (
                <div>
                  {checkDoctorAvailability(selectedDoctor, currentDate) ? (
                    <div className="selectAdtae">
                      <p>
                        {selectedDoctor} is available on{" "}
                        {currentDate.toDateString()}.
                      </p>
                      <button
                        className="checkkk-button"
                        onClick={(e) => handleCheckAvailability(e)}
                        disabled={isChecking || isProcessing}
                      >
                        <span>
                          {isChecking ? "Checking..." : "Check Availability"}
                          <svg
                            viewBox="0 0 19.9 19.7"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            aria-labelledby="title desc"
                            className="svg-icon search-icon"
                          >
                            <title>Search Icon</title>
                            <desc id="desc">A magnifying glass icon.</desc>
                            <g
                              stroke="white"
                              fill="none"
                              className="search-path"
                            >
                              <path
                                d="M18.5 18.3l-5.4-5.4"
                                strokeLinecap="square"
                              ></path>
                              <circle r="7" cy="8" cx="8"></circle>
                            </g>
                          </svg>
                        </span>
                      </button>

                      {isChecking ? (
                        // <p>Checking available timeslots...</p>
                        <p></p>
                      ) : isCheckSuccessful && availableTimeslots.length > 0 ? (
                        <div>
                          <p>
                            Please select from the available timepoint(s) for{" "}
                            {currentDate.toDateString()}
                          </p>
                          <div className="timeslots-container">
                            {["Morning", "Afternoon", "Evening"].map(
                              (period) => {
                                const filteredSlots = availableTimeslots.filter(
                                  (slot) => {
                                    const hour = parseInt(slot.split(":")[0]);
                                    if (period === "Morning")
                                      return hour >= 6 && hour < 12;
                                    if (period === "Afternoon")
                                      return hour >= 12 && hour < 17;
                                    if (period === "Evening")
                                      return hour >= 17 || hour < 6;
                                  }
                                );

                                if (filteredSlots.length === 0) return null;

                                return (
                                  <div key={period} className="timeslot-period">
                                    <h3>{period}</h3>
                                    <div className="timeslot-options">
                                      {filteredSlots.map((timeslot, index) => (
                                        <label
                                          key={index}
                                          className="timeslot-option"
                                        >
                                          <input
                                            type="radio"
                                            name="timeslot"
                                            value={timeslot}
                                            checked={
                                              selectedTimeslot === timeslot
                                            }
                                            onChange={(e) =>
                                              setSelectedTimeslot(
                                                e.target.value
                                              )
                                            }
                                          />
                                          <span className="timeslot-label">
                                            {formatTime(timeslot)}
                                          </span>
                                        </label>
                                      ))}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>

                          {selectedTimeslot && (
                            <div className="appointment-summary">
                              <h4>Appointment Request Summary</h4>
                              <div className="summary-content">
                                <p>
                                  You are making an appointment request for{" "}
                                  {name} ({hospitalData.phoneCode}
                                  {mobileNumber}) with {selectedDoctor} of the{" "}
                                  {selectedDepartment} department. The
                                  appointment is scheduled for{" "}
                                  {currentDate.toDateString()} at{" "}
                                  {formatTime(selectedTimeslot)}. If correct,
                                  please click the Proceed button.
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : isChecked &&
                        isCheckSuccessful &&
                        availableTimeslots.length === 0 ? (
                        <p>
                          No available time slots for {selectedDoctor} on{" "}
                          {currentDate.toDateString()}. Please try again later.
                          If any patients cancel their appointments, time slots
                          will become available.
                        </p>
                      ) : null}
                    </div>
                  ) : (
                    <p>
                      {selectedDoctor} is not available on{" "}
                      {currentDate.toDateString()}.
                    </p>
                  )}
                </div>
              )}

              {selectedTimeslot &&
                mobileNumber &&
                name &&
                selectedDoctor &&
                selectedDepartment &&
                currentDate && (
                  // <button
                  //   className="AP-tokn-proceed-button"
                  //   type="submit"
                  //   disabled={isProcessing}
                  // >
                  //   {isProcessing ? "Processing" : "Proceed"}
                  // </button>

                  <button
                    className="AP-tokn-proceed-button"
                    type="submit"
                    disabled={isProcessing}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                      ></path>
                    </svg>
                    <div className="textPCD">
                      {isProcessing ? "Processing" : "Proceed"}
                    </div>
                  </button>
                )}

              {!selectedTimeslot && (
                <p className="noteAP">
                  Note: Please fill all the fields and select timeslot
                </p>
              )}
              {!mobileNumber && <p></p>}
              {!name && <p></p>}
              {!selectedDoctor && <p></p>}
              {!selectedDepartment && <p></p>}
              {!currentDate && <p></p>}
            </div>
          </form>
          {proceedClicked && isProcessing && (
            <div className="AP-processing-message"></div>
          )}

          {/* <div className="AP-mobile-number-lookup">
            <p className="AP-alreadytext">
              Already made an appointment request?
            </p>

            <div>
              {" "}
              <a href="/AppointmentStatus" className="AP-token-proceed-button">
                Check Appointment Status
              </a>
            </div>

 
          </div> */}
        </div>
      )}
    </div>
  );
};

export default ScreenP;
