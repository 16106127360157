// App.jsx
import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import logo from './KrystaLogo.png';
import Home from './components/Home/Home';
import './App.css';
import AppBar from './components/RepeatingElements/AppBar';
import OfflinePage from './components/OfflinePage/OfflinePage';

// Import other components/routes
import ScreenForAdmin from './components/ScreenForAdmin/screenA';
import ScreenForQRbasedToken from './components/ScreenForPatient/screenP';
import ScreenForDoctor from './components/ScreenForDoctors/screenD';
import ViewForAllPatientsServingTokens from './components/ScreenForAllPatients/screenVAP';
import Screen4DirectEntry from './components/Screen4DE/Screen4DEMain';
import Screen4DirectEntryForMakingAppointments from './components/AppointmentsThroughDirectEntry/ATDE';
import VAPvideoLinkManagerForServeringTokenScreen from './components/ScreenForAllPatients/VideoLinkManager';
import AppointmentStatusPageForPatients from './components/TokenStatusPage/TokenStatusPage';
import ScreenForPatientWebsite from './components/ScreenForPatientWebsite/ScreenPWebsite';
import AppointmentChatBot from './components/ScreenForPatientWebsite/ChatBot';
import AllAppointmentsViewPage from './components/allAppointmentsView/AAV';
import ScreenForAdminWebsiteBooking from './components/Screen4admin4WebsiteBooking/ScreenAdminPWU';
import ServingTokenDetailsPage from './components/TokenDetails/tokenDetails';
import AllAppointmentsForAdoctorPage from './components/ScreenForDoctors/AllAppointmentsForDoctor';
import Appointments4AssignedDoctor4PatientsView from './components/ScreenForPatient/UpcomingApppointments4D';
import Dashboard from './components/Dashboard/Main/Main';
import SignupPage from './components/ScreenForAdmin/ASignUp';
import LoginPage from './components/ScreenForAdmin/ALogin';
import { HospitalProvider } from './components/ScreenForAdmin/HospitalContext';
import NEWpageforAddingDoctor from './components/NEWdoctorListManagement/AddingDoctorNEW';
import NEWpageforViewingDoctor from './components/NEWdoctorListManagement/ViewingDoctorNEW';
import GenerateLinkForTokenViaQRpage from './components/ScreenForAdmin/GenerateLinkForQR';
import GenerateLinkForAppointmentPAGE from './components/ScreenForAdmin/GenerateLinkForAPT';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import PageForUpdatingHospitalProfile from './components/ProfileUpdate/ProfileUpdate';
import PageForBillsCombined from './components/Billing/BillingCombined';
import PageForBillsCombinedNewModel from './components/Billing/BillingNewModel';
import PageForBillsReadToPay from './components/Billing/BillingReadyToPay';
import PageForBillsPayingtoKC from './components/Billing/BillPaying2KC';
import PageforCreatingTokenByHospitalStaff from './components/ScreenForAdmin/CreateTokenMainPage';
import AppointmentRequestsPageBothLinkAndDirectEntryToHandlePayment from './components/AppointmentRequestsPage/ARP';
import LinkOrQRbasedTokenRequestsPage from './components/TokenRequestsPage/TRP';
import PatientToMakePaymentPageWithQRtag from './components/PatientsToMakePayment/PQRtagToMakePayment';
import PatientToMakePaymentPageWithPWUtag from './components/PatientsToMakePayment/PPWUtagToMakePayment';
import PatientToBeConsulatedPage from './components/PatientsTobeConsulted/PTBC';
import AllAppointmentsPageForFollowUpByStaff from './components/allAppointmentsView/AAV';
import FirstPageForMobileUsers from './components/FirstPageForMobileUsers/FPFMU';

const NoAppBarWrapper = ({ children }) => {
  return (
    <div className="App">
      {children}
    </div>
  );
};

function App() {
  const [showLogo, setShowLogo] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);

  useEffect(() => {
    const logoTimeout = setTimeout(() => {
      setShowLogo(false);
    }, 2000);

    return () => clearTimeout(logoTimeout);
  }, []);

  const handleSignup = () => {
    console.log('User signed up successfully');
  };

  return (
    <Router>
      <HospitalProvider>
        <div className="App">
          {isOnline ? (
            showLogo ? (
              <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <div className="spinner2">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              </header>
            ) : (
              <Routes>
                <Route 
                  path="/AIChatBotForAppointment" 
                  element={
                    <NoAppBarWrapper>
                      <AppointmentChatBot />
                    </NoAppBarWrapper>
                  } 
                />
                
                {/* Routes with AppBar */}
                <Route
                  path="*"
                  element={
                    <>
                      <AppBar />
                      <Routes>
                        <Route path="/signup" element={<SignupPage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="" element={<LoginPage />} />
                        <Route path="/Dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                        <Route path="/Profile" element={<PrivateRoute><PageForUpdatingHospitalProfile/></PrivateRoute>} />
                        <Route path="/AddDoctor" element={<PrivateRoute><NEWpageforAddingDoctor/></PrivateRoute>} />
                        <Route path="/ViewDoctors" element={<PrivateRoute><NEWpageforViewingDoctor/></PrivateRoute>} />
                        <Route path="/ForDoctors" element={<PrivateRoute><ScreenForDoctor/></PrivateRoute>} />
                        <Route path="/LinkForQRbasedTokens" element={<PrivateRoute><GenerateLinkForTokenViaQRpage/></PrivateRoute>} />
                        <Route path="/CreateToken" element={<PrivateRoute><PageforCreatingTokenByHospitalStaff/></PrivateRoute>} />
                        <Route path="/LinkForAppointment" element={<PrivateRoute><GenerateLinkForAppointmentPAGE/></PrivateRoute>} />
                        <Route path="/AppointmentRequests" element={<PrivateRoute><AppointmentRequestsPageBothLinkAndDirectEntryToHandlePayment/></PrivateRoute>} />
                        <Route path="/TokenRequests" element={<PrivateRoute><LinkOrQRbasedTokenRequestsPage/></PrivateRoute>} />
                        <Route path="/MakeAppointment" element={<PrivateRoute><Screen4DirectEntryForMakingAppointments/></PrivateRoute>} />
                        <Route path="/ST" element={<PrivateRoute><ViewForAllPatientsServingTokens/></PrivateRoute>} />
                        <Route path="/ViewAppointments" element={<PrivateRoute><AllAppointmentsViewPage/></PrivateRoute>} />
                        <Route path="/VideosForServingTokenPage" element={<PrivateRoute><VAPvideoLinkManagerForServeringTokenScreen/></PrivateRoute>} />
                        <Route path="/token-details" element={<PrivateRoute><ServingTokenDetailsPage/></PrivateRoute>} />
                        <Route path="/PatientPayments-QR" element={<PrivateRoute><PatientToMakePaymentPageWithQRtag/></PrivateRoute>} />
                        <Route path="/PatientPayments-AP" element={<PrivateRoute><PatientToMakePaymentPageWithPWUtag/></PrivateRoute>} />
                        <Route path="/PatientToBeConsulted" element={<PrivateRoute><PatientToBeConsulatedPage/></PrivateRoute>} />
                        <Route path="/All-Appointments" element={<PrivateRoute><AllAppointmentsForAdoctorPage/></PrivateRoute>} />
                        <Route path="/AAV" element={<PrivateRoute><AllAppointmentsPageForFollowUpByStaff/></PrivateRoute>} />
                        <Route path="/MobileUser" element={<PrivateRoute><FirstPageForMobileUsers/></PrivateRoute>} />
                        <Route path="/BillingNM" element={<PrivateRoute><PageForBillsCombinedNewModel/></PrivateRoute>} />
                        <Route path="/Billing" element={<PrivateRoute><PageForBillsReadToPay/></PrivateRoute>} />
                        <Route path="/BillPaying" element={<PrivateRoute><PageForBillsPayingtoKC/></PrivateRoute>} />
                        <Route path="/AppointmentLink" element={<ScreenForPatientWebsite />} />
                        <Route path="/AppointmentStatus" element={<AppointmentStatusPageForPatients/>} />
                        <Route path="/QR-Tokens" element={<ScreenForQRbasedToken />} />
                        <Route path="/A4Ad" element={<Appointments4AssignedDoctor4PatientsView/>} />
                      </Routes>
                    </>
                  }
                />
              </Routes>
            )
          ) : (
            <OfflinePage />
          )}
        </div>
      </HospitalProvider>
    </Router>
  );
}

createRoot(document.getElementById('root')).render(<App />);

export default App;