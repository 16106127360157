import React, { useEffect, useState, useRef } from "react";
import { db } from "../../firebaseconfig";
import {
  collection,
  addDoc,
  doc,
  query,
  where,
  onSnapshot,
  getDocs,
  setDoc,
  getDoc,
  orderBy,
  writeBatch,
  updateDoc,
  limit,
} from "firebase/firestore";
import emailjs from "emailjs-com";
import soundA from "./Sound2.mp3";
import soundB from "./message3.mp3";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import "./ChatBot.css";

const ScreenP = () => {
  const [hids, setHids] = useState([]);
  const [step, setStep] = useState('name');
  const [showConfirm, setShowConfirm] = useState(false);
  const [noAvailableTimeslots, setNoAvailableTimeslots] = useState(false);
  const chatMessagesRef = useRef(null);

  const [HID, setHid] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [existingMobileNumber, setExistingMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [patientId, setPatientId] = useState(null);
  const [patientDetails, setPatientDetails] = useState(null);
  const [nowServingToken, setNowServingToken] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [proceedClicked, setProceedClicked] = useState(false);

  const [availableDepartments, setAvailableDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);

  const [currentDate, setCurrentDate] = useState(null); // Initialize currentDate state to null

  const [selectedDoctorAvailableDays, setSelectedDoctorAvailableDays] =
    useState([]);

  const [isCheckSuccessful, setIsCheckSuccessful] = useState(false); // Define isCheckSuccessful state
  const [availableTimeslots, setAvailableTimeslots] = useState([]); // Define availableTimeslots state
  const [isChecking, setIsChecking] = useState(false); // New state to track if checking is in progress

  const [isChecked, setIsChecked] = useState(false);

  const [phoneCode, setPhoneCode] = useState("");

  const [email, setEmail] = useState("");

  const [consultationFee, setConsultationFee] = useState("");
  const [currency, setCurrency] = useState("");
  const [consultationFeeComments, setConsultationFeeComments] = useState("");

  const [mobileNumberError, setMobileNumberError] = useState("");
  const [dateError, setDateError] = useState('');

  const [botInfo, setBotInfo] = useState({
    name: "Appointment Assistant",
    photoURL: "",
    hospitalName: "",
  });

  const handleRestartClick = () => {
    setShowConfirm(true);
  };

  const confirmRestart = () => {
    window.location.reload();
  };

  const cancelRestart = () => {
    setShowConfirm(false);
  };

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [step, patientId, selectedTimeslot, isCheckSuccessful, noAvailableTimeslots]);

  useEffect(() => {
    // Fetch bot info from the doctors collection
    const fetchBotInfo = async () => {
      const doctorsQuery = query(
        collection(db, "doctors"),
        where("hospitalData.HID", "==", HID)
      );
      const doctorsSnapshot = await getDocs(doctorsQuery);
      if (!doctorsSnapshot.empty) {
        const doctorData = doctorsSnapshot.docs[0].data();
        setBotInfo({
          name: doctorData.hospitalData.HospName || "Appointment Assistant",
          photoURL: doctorData.hospitalData.chatBotLogoURL || "",
          hospitalName: doctorData.hospitalData.HospName || "",
        });
      }
    };

    fetchBotInfo();
  }, [HID]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hidValue = urlParams.get("HID");
    const phoneCodeValue = urlParams.get("phoneCode");
    const emailValue = urlParams.get("email"); // Add this line to get the email parameter
    setHid(hidValue);
    setPhoneCode(decodeURIComponent(phoneCodeValue) || ""); // Decode and set the phoneCode
    setEmail(emailValue); // Set the email state
  }, []);

  useEffect(() => {
    setSelectedTimeslot(null); // Reset selected timeslot when date changes and show Proceed button
  }, [currentDate]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hidValue = urlParams.get("HID");
    setHid(hidValue);
  }, []);

  const fetchHIDs = async () => {
    try {
      const doctorsRef = collection(db, "doctors");
      const querySnapshot = await getDocs(doctorsRef);

      const ids = new Set();
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.hospitalData && data.hospitalData.HID) {
          ids.add(data.hospitalData.HID);
        }
      });

      setHids(Array.from(ids));
    } catch (error) {
      console.error("Error fetching HID values:", error);
    }
  };

  useEffect(() => {
    fetchHIDs();
  }, []);

  const fetchDepartments = async () => {
    const departmentsQuery = query(collection(db, "doctors"));
    const querySnapshot = await getDocs(departmentsQuery);
    const departments = new Set();
    querySnapshot.forEach((doc) => {
      const doctorData = doc.data();
      if (doctorData.departmentName) {
        departments.add(doctorData.departmentName);
      }
    });
    return Array.from(departments);
  };

  const [isCheckingAvailability, setIsCheckingAvailability] = useState(false);
  const [hasCheckedOnce, setHasCheckedOnce] = useState(false);
  
  const handleCheckAvailability = async () => {
    if (!currentDate) {
      alert("Please select a valid date before checking availability.");
      return;
    }
  
    setIsCheckingAvailability(true);
    setIsProcessing(true);
    setIsChecking(true);
    setIsCheckSuccessful(false);
    setAvailableTimeslots([]);
    setSelectedTimeslot(null);
    setIsChecked(true);
    setHasCheckedOnce(true);
    setNoAvailableTimeslots(false);
  
    try {
      const selectedDate = new Date(currentDate.toISOString());
      selectedDate.setHours(0, 0, 0, 0);
  
      // Create or update the appointment record
      const appointmentRecord = await createNewAppointmentRecord(selectedDate);
  
      // Filter and set available timeslots
      const availableTimeslots = appointmentRecord.availableTimeslots
        .filter((slot) => slot.status === "Y")
        .map((slot) => slot.startTime);
  
      if (availableTimeslots.length === 0) {
        setNoAvailableTimeslots(true);
      } else {
        setAvailableTimeslots(availableTimeslots);
        setIsCheckSuccessful(true);
      }
    } catch (error) {
      console.error("Error checking availability: ", error);
    } finally {
      setIsChecking(false);
      setIsProcessing(false);
      setIsCheckingAvailability(false);
    }
  };
  
  useEffect(() => {
    setAvailableTimeslots([]);
  }, [currentDate]);
  
  useEffect(() => {
    const handleDateChange = async () => {
      if (selectedDoctor && currentDate) {
        setIsCheckingAvailability(true);
        const selectedDate = new Date(currentDate.toISOString());
        selectedDate.setHours(0, 0, 0, 0);
  
        try {
          const appointmentsQuery = query(
            collection(db, "Appointments"),
            where("date", "==", selectedDate.toISOString()),
            where("doctor", "==", selectedDoctor)
          );
          const appointmentsSnapshot = await getDocs(appointmentsQuery);
          const existingAppointment = appointmentsSnapshot.docs[0];
  
          if (!existingAppointment) {
            await createNewAppointmentRecord(selectedDate);
          }
        } catch (error) {
          console.error("Error checking appointment record:", error);
        } finally {
          setIsCheckingAvailability(false);
        }
      }
    };
  
    handleDateChange();
  }, [selectedDoctor, currentDate]);

  // const createNewAppointmentRecord = async (selectedDate) => {
  //   const newAppointmentRef = doc(collection(db, "Appointments"));
  //   const selectedDoctorData = doctorsList.find(
  //     (doctor) => doctor.name === selectedDoctor
  //   );
  //   const fetchedAvailableTimeslots =
  //     selectedDoctorData.timeSlots.find(
  //       (slot) =>
  //         slot.day.toLowerCase() ===
  //         selectedDate
  //           .toLocaleDateString("en-US", { weekday: "long" })
  //           .toLowerCase()
  //     )?.timeRanges || [];

  //   // Filter and set available timeslots with status 'Y'
  //   const availableTimeslots = fetchedAvailableTimeslots
  //     .filter((slot) => slot.status === "Y")
  //     .map((slot) => slot.startTime);

  //   // Create the new appointment document
  //   const batch = writeBatch(db);
  //   batch.set(newAppointmentRef, {
  //     HID: HID, // Add the HID value as a separate field
  //     doctor: selectedDoctor,
  //     date: selectedDate.toISOString(),
  //     availableTimeslots: fetchedAvailableTimeslots.map((slot) => ({
  //       startTime: slot.startTime,
  //       status: "Y",
  //     })),
  //   });
  //   await batch.commit();

  //   // Return the available timeslots from the newly created record
  //   return availableTimeslots;
  // };

  const createNewAppointmentRecord = async (selectedDate) => {
    const selectedDoctorData = doctorsList.find(
      (doctor) => doctor.name === selectedDoctor
    );

    // Check for existing appointment with doctor, HID, and department
    const existingAppointmentQuery = query(
      collection(db, "Appointments"),
      where("date", "==", selectedDate.toISOString()),
      where("doctor", "==", selectedDoctor),
      where("HID", "==", HID),
      where("department", "==", selectedDepartment)
    );
    const existingAppointmentSnapshot = await getDocs(existingAppointmentQuery);

    let appointmentRef;
    let existingTimeslots = [];

    if (!existingAppointmentSnapshot.empty) {
      appointmentRef = doc(
        db,
        "Appointments",
        existingAppointmentSnapshot.docs[0].id
      );
      existingTimeslots =
        existingAppointmentSnapshot.docs[0].data().availableTimeslots;
    } else {
      appointmentRef = doc(collection(db, "Appointments"));
    }

    const fetchedAvailableTimeslots =
      selectedDoctorData.timeSlots.find(
        (slot) =>
          slot.day.toLowerCase() ===
          selectedDate
            .toLocaleDateString("en-US", { weekday: "long" })
            .toLowerCase()
      )?.timeRanges || [];

    const updatedTimeslots = fetchedAvailableTimeslots.map((slot) => {
      const existingSlot = existingTimeslots.find(
        (es) => es.startTime === slot.startTime
      );
      return {
        startTime: slot.startTime,
        status: existingSlot ? existingSlot.status : "Y",
      };
    });

    const appointmentData = {
      HID: HID,
      doctor: selectedDoctor,
      department: selectedDepartment,
      date: selectedDate.toISOString(),
      availableTimeslots: updatedTimeslots,
    };

    await setDoc(appointmentRef, appointmentData, { merge: true });

    return appointmentData;
  };

  const checkDoctorAvailability = (doctorName, selectedDate) => {
    const doctor = doctorsList.find((doctor) => doctor.name === doctorName);
    if (doctor) {
      const dayOfWeek = selectedDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      return doctor.availableDays.includes(dayOfWeek);
    }
    return false;
  };

  const fetchDepartmentsAndDoctors = () => {
    const departmentsQuery = query(
      collection(db, "doctors"),
      where("hospitalData.HID", "==", HID)
    );

    const unsubscribeDepartments = onSnapshot(departmentsQuery, (snapshot) => {
      console.log("Snapshot size:", snapshot.size);
      const departments = new Set();
      snapshot.forEach((doc) => {
        const doctorData = doc.data();
        if (doctorData.departmentName) {
          departments.add(doctorData.departmentName);
        }
      });
      setAvailableDepartments(Array.from(departments));
    });

    return () => unsubscribeDepartments();
  };

  useEffect(() => {
    const unsubscribe = fetchDepartmentsAndDoctors();

    return () => unsubscribe();
  }, [HID]);

  const fetchDoctorsByDepartment = async (department) => {
    const doctorsQuery = query(
      collection(db, "doctors"),
      where("departmentName", "==", department),
      where("availability", "==", "Y"),
      where("appointmentAllowed", "!=", "X"), // Add this condition
      where("hospitalData.HID", "==", HID)
    );

    const unsubscribeDoctors = onSnapshot(doctorsQuery, (snapshot) => {
      const doctors = snapshot.docs.map((doc) => doc.data());
      setDoctorsList(doctors);
    });

    return () => unsubscribeDoctors();
  };

  const handleDepartmentChange = (event) => {
    const selectedDepartment = event.target.value;
    setSelectedDepartment(selectedDepartment);
    setSelectedDoctor(""); // Reset the selected doctor
    setSelectedTimeslot(null); // Reset the selected timeslot
    setAvailableTimeslots([]); // Reset available timeslots
    setIsChecked(false); // Reset isChecked
    setSelectedDoctorAvailableDays([]); // Reset available days
    setCurrentDate(null); // Reset selected date

    const unsubscribe = fetchDoctorsByDepartment(selectedDepartment);

    // Check if there are any available doctors for the selected department
    if (unsubscribe.length === 0) {
      setDoctorsList([]);
      alert("No doctors available for the selected department.");
    }

    // Cleanup function to unsubscribe from the previous snapshot listener - for realtime update
    return () => unsubscribe();
  };

  const handleDoctorChange = (event) => {
    const selectedDoctor = event.target.value;
    setSelectedDoctor(selectedDoctor);

    const doctor = doctorsList.find((doctor) => doctor.name === selectedDoctor);

    if (doctor) {
      setSelectedDoctor(selectedDoctor);
      setSelectedDoctorAvailableDays(doctor.availableDays);
      setSelectedTimeslot(null);
      setAvailableTimeslots([]);
      setIsChecked(false);
      setCurrentDate(null);
      setConsultationFee(doctor.consultationFee || "");
      setConsultationFeeComments(doctor.consultationFeeComments || "");
      setCurrency(doctor.hospitalData?.currency || ""); // Set the currency from hospitalData
    }
  };

  // Add this to reset isChecked when date changes
  useEffect(() => {
    setIsChecked(false);
  }, [currentDate]);

  useEffect(() => {
    fetchDepartmentsAndDoctors();
  }, []);

  useEffect(() => {
    if (patientId) {
      const patientRef = doc(db, "patients", patientId);

      const unsubscribe = onSnapshot(patientRef, (doc) => {
        if (doc.exists()) {
          const patientData = doc.data();
          setPatientDetails(patientData);

          if (patientData.status !== status) {
            setStatus(patientData.status);
            vibrateAndPlaySound();
          }

          if (
            patientData.doctorAssigned &&
            ["C", "D", "E", "F"].includes(patientData.status)
          ) {
            const servingTokenQuery = query(
              collection(db, "patients"),
              where("status", "==", "D"),
              where("doctorAssigned", "==", patientData.doctorAssigned)
            );
            const unsubscribeServingToken = onSnapshot(
              servingTokenQuery,
              (snapshot) => {
                const serving = snapshot.docs
                  .map((doc) => doc.data())
                  .find((p) => p.doctorAssigned === patientData.doctorAssigned);
                if (serving) {
                  setNowServingToken(serving.tokenNumber);
                } else {
                  setNowServingToken("");
                }
              }
            );

            return () => unsubscribeServingToken();
          }
        }
      });

      return () => unsubscribe();
    }
  }, [patientId, status]);

  const vibrateAndPlaySound = () => {
    if ("vibrate" in navigator) {
      navigator.vibrate(500);
    }
    const audio = new Audio(soundA);
    audio
      .play()
      .catch((error) => console.error("Audio playback failed:", error));
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(":");
    const period = parseInt(hours, 10) >= 12 ? "PM" : "AM";
    const formattedHours = (((parseInt(hours, 10) + 11) % 12) + 1).toString();
    return `${formattedHours}:${minutes} ${period}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setProceedClicked(true);
      setIsProcessing(true);

      console.log("Selected Timeslot:", selectedTimeslot);

      const selectedDoctorData = doctorsList.find(
        (doctor) => doctor.name === selectedDoctor
      );

      const selectedDate = new Date(currentDate.toISOString());
      selectedDate.setHours(0, 0, 0, 0);
      const appointmentsQuery = query(
        collection(db, "Appointments"),
        where("date", "==", selectedDate.toISOString()),
        where("doctor", "==", selectedDoctor)
      );
      const appointmentsSnapshot = await getDocs(appointmentsQuery);
      const existingAppointment = appointmentsSnapshot.docs[0];
      const appointmentId = existingAppointment ? existingAppointment.id : null;

      const apid = `${
        appointmentId || "new"
      }_${selectedDate.toISOString()}_${selectedDoctor}_${selectedTimeslot}`;

        // Extract the required fields from the selected doctor's data
    const { KCC, PFEE, HospName } = selectedDoctorData.hospitalData || {};

      const docRef = await addDoc(collection(db, "patients"), {
        mobileNumber: `${phoneCode}${mobileNumber}`,
        name,
        status: "A",
        requestTime: new Date(),
        tag: "PWU",
        PKC:"A",
        selectedDepartment,
        doctorAssigned: selectedDoctor,
        doctorRoom: selectedDoctorData?.room || "",
        appointmentDate: currentDate.toISOString(),
        selectedTimeslot: selectedTimeslot || "",
        HID,
        APID: apid,
        consultationFee,
        consultationFeeComments,
        currency, // Add the currency to the patient record
        // Add the new fields here
      KCC: KCC || 0,
      PFEE: PFEE || 0,
      hospitalName: HospName || "",
      });

      setPatientId(docRef.id);
      setStatus("A");
      vibrateAndPlaySound();

      // Update the status of the selected timeslot to 'X'
      if (existingAppointment) {
        const existingAppointmentRef = doc(
          db,
          "Appointments",
          existingAppointment.id
        );
        const existingAppointmentData = existingAppointment.data();
        const updatedAvailableTimeslots =
          existingAppointmentData.availableTimeslots.map((slot) => ({
            ...slot,
            status: slot.startTime === selectedTimeslot ? "X" : slot.status,
          }));
        await updateDoc(existingAppointmentRef, {
          availableTimeslots: updatedAvailableTimeslots,
        });
      }

      // Send email using EmailJS
      const templateParams = {
        to_name: "Hospital Staff",
        to_email: email,
        patient_name: name,
        patient_mobile: `${phoneCode}${mobileNumber}`,
        selected_department: selectedDepartment,
        doctor_assigned: selectedDoctor,
        appointment_date: currentDate.toISOString().split("T")[0],
        selected_timeslot: selectedTimeslot,
      };

      emailjs
        .send(
          "service_0afemra", // Replace with your EmailJS service ID
          "template_m275mja", // Replace with your EmailJS template ID
          templateParams,
          "VJkDKxpFqFnUc1vFq" // Replace with your EmailJS user ID
        )
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
        })
        .catch((err) => {
          console.error("FAILED...", err);
        });
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const fetchLatestToken = async () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const patientsQuery = query(
      collection(db, "patients"),
      where("mobileNumber", "==", existingMobileNumber),
      where("requestTime", ">=", today),
      orderBy("requestTime", "desc"),
      limit(1)
    );

    const querySnapshot = await getDocs(patientsQuery);
    const latestPatient = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))[0];

    if (latestPatient) {
      setPatientDetails(latestPatient);
      setPatientId(latestPatient.id);
      setStatus(latestPatient.status);
      setName(latestPatient.name);
      setMobileNumber(latestPatient.mobileNumber);
    } else {
      alert("No token found for the given mobile number today.");
    }
  };

  const takeScreenshot = () => {
    const patientDetailsElement = document.querySelector(".patient-details");
    domtoimage
      .toBlob(patientDetailsElement)
      .then(function (blob) {
        saveAs(blob, "token-details.png");
      })
      .catch(function (error) {
        console.error("Error taking screenshot:", error);
      });
  };

  const displayMessage = () => {
    if (!patientDetails) return null;

    let messageClass = "" + "" + patientDetails.status;
    switch (patientDetails.status) {
      case "A":
        return (
          <div className={messageClass}>
            <br/>
            <strong>Your appointment is confirmed</strong> 
            <br />
            <br />
            
            <p>
              One of our representatives will contact your during the working hours to reconfirm your appointment.
            </p>
            <br/>
            <div>
              {" "}
              <a href="/AppointmentStatus">
                Check Appointment Details
              </a>
            </div>
          </div>
        );
      case "B":
        return (
          <div className={messageClass}>
            <br/>
            <strong>Appointment reconfirmed</strong>
            <br />
            <br />
            <div>
              {" "}
              <a href="/AppointmentStatus" >
                Check Appointment Details
              </a>
            </div>
          </div>
        );

      case "C":
        return (
          <div className={messageClass}>
            <div>
              {" "}
              <a href="/AppointmentStatus">
                Check Appointment Status
              </a>
            </div>
          </div>
        );
      case "D":
        return (
          <div className={messageClass}>
            <div>
              {" "}
              <a href="/AppointmentStatus">
                Check Appointment Status
              </a>
            </div>
          </div>
        );

      case "E":
        return (
          <div className={messageClass}>
            <div>
              {" "}
              <a href="/AppointmentStatus">
                Check Appointment Status
              </a>
            </div>
          </div>
        );
      case "F":
        return (
          <div className={messageClass}>
            <div>
              {" "}
              <a href="/AppointmentStatus">
                Check Appointment Status
              </a>
            </div>
          </div>
        );
      default:
        return (
          <div className={messageClass}>
            <div>
              {" "}
              <a href="/AppointmentStatus" >
                Check Appointment Status
              </a>
            </div>
          </div>
        );
    }
  };

  //sound playing messsage start

  const playSound = () => {
    const audio = new Audio(soundB);
    audio.play().catch((error) => console.error("Audio playback failed:", error));
  };

  useEffect(() => {
    if (step !== 'name') {
      playSound();
    }
  }, [step]);

  useEffect(() => {
    if (isCheckSuccessful && availableTimeslots.length > 0) {
      playSound();
    }
  }, [isCheckSuccessful, availableTimeslots]);

  useEffect(() => {
    if (patientId) {
      playSound();
    }
  }, [patientId]);

  useEffect(() => {
    if (noAvailableTimeslots) {
      playSound();
    }
  }, [noAvailableTimeslots]);

  useEffect(() => {
    if (mobileNumberError || dateError) {
      playSound();
    }
  }, [mobileNumberError, dateError]);

   //sound playing messsage end


 

  return (
    <div className="CBwhatsapp-container">
     <div className="CBchat-header">
  {botInfo.photoURL ? (
    <img src={botInfo.photoURL} alt={botInfo.name} className="CBcontact-image" />
  ) : (
    <div className="CBdefault-bot-color contact-image"></div>
  )}
  <div className="CBcontact-info">
    <h2>{botInfo.name}</h2>
    <p>Online</p>
  </div>
</div>
      <div className="CBchat-messages" ref={chatMessagesRef}>
        {!patientId ? (
          <>
            <div className="CBmessage CBreceived">
              Hello! I'm your appointment booking assistant for {botInfo.hospitalName}. I will help you to make an appointment.
            </div>
            {step === 'name' && (
              <>
                <div className="CBmessage CBreceived">
                 Could you please tell me the patient name?
                </div>
                <div className="CBmessage CBsent">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter patient name"
                    className="CBinput"
                  />
                  <button onClick={() => setStep('mobile')} className="CBsend-button">Send</button>
                </div>
              </>
            )}
             {step === 'mobile' && (
              <>
                <div className="CBmessage CBsent">{name}</div>
                <div className="CBmessage CBreceived">
                  Nice to meet you, {name}! Could you please provide your mobile number? (without the country code)
                </div>
                <div className="CBmessage CBsent">
                  <input
                    type="text"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    onBlur={(e) => {
                      const pattern =
                        phoneCode === "+91"
                          ? /^\d{10}$/
                          : phoneCode === "+968"
                          ? /^\d{8}$/
                          : null;
                      if (pattern && !pattern.test(e.target.value)) {
                        setMobileNumberError(
                          phoneCode === "+91"
                            ? "Please enter exactly 10 digits for the mobile number"
                            : "Please enter exactly 8 digits for the mobile number"
                        );
                      } else {
                        setMobileNumberError("");
                      }
                    }}
                    placeholder={`Mobile number without ${phoneCode}`}
                    required
                    pattern={
                      phoneCode === "+91"
                        ? "\\d{10}"
                        : phoneCode === "+968"
                        ? "\\d{8}"
                        : null
                    }
                    title={
                      phoneCode === "+91"
                        ? "Please enter exactly 10 digits for the mobile number"
                        : phoneCode === "+968"
                        ? "Please enter exactly 8 digits for the mobile number"
                        : "Please enter a valid mobile number"
                    }
                    className="CBinput"
                  />
                  <button onClick={() => !mobileNumberError && setStep('department')} className="CBsend-button">Send</button>
                </div>
                {mobileNumberError && (
                  <div className="CBmessage CBreceived CBerror">{mobileNumberError}</div>
                )}
              </>
            )}
            {step === 'department' && (
              <>
                <div className="CBmessage CBsent">{phoneCode}{mobileNumber}</div>
                <div className="CBmessage CBreceived">
                  Which department would you like to book an appointment with?
                </div>
                <div className="CBmessage CBsent">
                  <select
                    value={selectedDepartment}
                    onChange={(e) => handleDepartmentChange(e)}
                  >
                    <option value="" disabled>Select Department</option>
                    {availableDepartments.map((department, index) => (
                      <option key={index} value={department}>{department}</option>
                    ))}
                  </select>
                  <button onClick={() => setStep('doctor')}>Send</button>
                </div>
              </>
            )}
            {step === 'doctor' && (
              <>
                <div className="CBmessage CBsent">{selectedDepartment}</div>
                <div className="CBmessage CBreceived">
                  Now, which doctor would you like to see?
                </div>
                <div className="CBmessage CBsent">
                  <select
                    value={selectedDoctor}
                    onChange={(e) => handleDoctorChange(e)}
                  >
                    <option value="" disabled>Select Doctor</option>
                    {doctorsList.map((doctor, index) => (
                      <option key={index} value={doctor.name}>{doctor.name}</option>
                    ))}
                  </select>
                  <button onClick={() => setStep('date')}>Send</button>
                </div>
              </>
            )}
{step === 'date' && (
  <>
    <div className="CBmessage CBsent">{selectedDoctor}</div>
    <div className="CBmessage CBreceived">
      When would you like to schedule your appointment?
      <br />
      <br />

      {selectedDoctor} is available on following day(s):
      <br/>
      <br/>
      
      {selectedDoctorAvailableDays.map((day, index) => (
        <div key={index} style={{ marginBottom: '8px' }}>{day}</div>
      ))}
    </div>
    <div className="CBmessage CBsent">
    <p className="dateInput">Select date</p>
      <input
        type="date"
        value={currentDate ? currentDate.toISOString().split('T')[0] : ''}
        min={new Date().toISOString().split('T')[0]}
        onChange={(e) => {
          const selectedDate = new Date(e.target.value);
          setCurrentDate(selectedDate);
          setIsCheckingAvailability(false); // Reset checking state on date change
          if (!checkDoctorAvailability(selectedDoctor, selectedDate)) {
            setDateError(`${selectedDoctor} is not available on ${selectedDate.toDateString()}. Please select another date.`);
          } else {
            setDateError('');
          }
        }}
      />
    </div>
    {dateError && (
      <div className="CBmessage CBreceived CBerror">{dateError}</div>
    )}
    {currentDate && !dateError && (
      <div className="CBmessage CBreceived">
        {selectedDoctor} is available on {currentDate.toDateString()}. Would you like to check for available time slots?
      </div>
    )}
    {currentDate && !dateError && (
      <div className="CBmessage CBsent">
        <button 
          onClick={handleCheckAvailability} 
          disabled={isProcessing}
        >
          {isProcessing ? "Checking..." : "Check Availability"}
        </button>
      </div>
    )}
    {noAvailableTimeslots && (
      <div className="CBmessage CBreceived">
        Unfortunately, there are no available timepoints for the selected date. 
        Please choose another date from the doctor's available days: {selectedDoctorAvailableDays.join(', ')} 
        <br/>
        <br/>
        Press the Check Availability button again after selecting the new date
      </div>
    )}
  </>
)}

{isCheckSuccessful && availableTimeslots.length > 0 && (
  <>
    <div className="CBmessage CBreceived">
      Here are the available time slots for your selected date:
    </div>
    <div className="CBmessage CBsent CBtimeslots-container">
      {["Morning", "Afternoon", "Evening"].map((period) => {
        const filteredSlots = availableTimeslots.filter((slot) => {
          const hour = parseInt(slot.split(":")[0]);
          if (period === "Morning") return hour >= 6 && hour < 12;
          if (period === "Afternoon") return hour >= 12 && hour < 17;
          if (period === "Evening") return hour >= 17 || hour < 6;
        });

        if (filteredSlots.length === 0) return null;

        return (
          <div key={period} className="timeslot-period">
            <h3>{period}</h3>
            <div className="timeslot-options">
              {filteredSlots.map((timeslot, index) => (
                <button
                  key={index}
                  onClick={() => setSelectedTimeslot(timeslot)}
                  className={selectedTimeslot === timeslot ? 'selected' : ''}
                >
                  {formatTime(timeslot)}
                </button>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  </>
)}
            {selectedTimeslot && (
              <>
                <div className="CBmessage CBreceived">
                  Great! Here's a summary of your appointment:
                  <br />
                  <br/>
                  Name: {name}
                  <br />
                  Mobile: {phoneCode}{mobileNumber}
                  <br />
                  Department: {selectedDepartment}
                  <br />
                  Doctor: {selectedDoctor}
                  <br />
                  Date: {currentDate.toDateString()}
                  <br />
                  Time: {formatTime(selectedTimeslot)}
                  <br />
                  <br/>
                  Is this correct?
                </div>
                <div className="CBmessage CBsent">
                  <button onClick={handleSubmit} disabled={isProcessing}>
                    {isProcessing ? "Processing..." : "Confirm Appointment"}
                  </button>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="CBmessage CBreceived">
            Here are the details:
            <br />
            <br/>
            Name: {name}
            <br />
            Mobile: {mobileNumber}
            <br />
            {displayMessage()}
          </div>
        )}
      </div>
      <div className="CBrestart-button-container">
        <button onClick={handleRestartClick} className="CBrestart-button">Restart Chat</button>
      </div>

      {showConfirm && (
        <div className="CBconfirm-restart">
          <p>Are you sure you want to restart the chat?</p>
          <button onClick={confirmRestart} className="CBconfirm-button">Yes</button>
          <button onClick={cancelRestart} className="CBcancel-button">No</button>
        </div>
      )}
    </div>
  );
};

export default ScreenP;
