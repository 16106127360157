import React, { useState, useEffect, useContext } from "react";
import { db, storage } from "../../firebaseconfig";
import { HospitalContext } from "../ScreenForAdmin/HospitalContext";
import {
  onSnapshot,
  collection,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Geolocation from "react-native-geolocation-service";
import "./ProfileUpdate.css";

const HospitalsData = () => {
  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;
  const [hospitals, setHospitals] = useState([]);
  const [updatingLogo, setUpdatingLogo] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const [fetchingCoordinates, setFetchingCoordinates] = useState(false);
  const [updateMessage, setUpdateMessage] = useState(null);
  const [editMode, setEditMode] = useState({});
  const [phoneCode, setPhoneCode] = useState(null);
  const [editableFields, setEditableFields] = useState({});

  useEffect(() => {
    if (HID) {
      const unsubscribe = onSnapshot(
        query(collection(db, "hospitals"), where("HID", "==", HID)),
        (snapshot) => {
          const hospitalsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setHospitals(hospitalsData);
          setPhoneCode(hospitalsData[0]?.phoneCode); // Initialize phoneCode
        }
      );

      return () => unsubscribe();
    }
  }, [HID]);

  const handleUpdate = async (id) => {
    setUpdatingLogo(true);
    try {
      await updateDoc(doc(db, "hospitals", id), editableFields[id] || {});
      setUpdateMessage("Data updated successfully!");
      alert("Data updated successfully!");
      setEditMode((prev) => ({ ...prev, [id]: false }));
      setEditableFields((prev) => {
        const newFields = { ...prev };
        delete newFields[id];
        return newFields;
      });
    } catch (error) {
      console.error("Error updating data:", error);
      setUpdateMessage("Error updating data. Please try again.");
    } finally {
      setUpdatingLogo(false);
      setTimeout(() => {
        setUpdateMessage(null);
      }, 3000);
    }
  };

  const handleFieldChange = (id, field, value) => {
    setEditableFields((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value,
      },
    }));
  };

  const handleFetchCoordinates = async (id) => {
    setFetchingCoordinates(true);
    try {
      const position = await new Promise((resolve, reject) => {
        Geolocation.getCurrentPosition(
          (position) => resolve(position),
          (error) => reject(error),
          { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
        );
      });

      const { latitude, longitude } = position.coords;
      setCoordinates({ latitude, longitude });
      setFetchingCoordinates(false);

      // Update Firestore document with new coordinates
      await updateDoc(doc(db, "hospitals", id), {
        location: `${latitude},${longitude}`,
      });
      setUpdateMessage("Location Coordinates updated successfully!");
      alert("Location updated successfully!");
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      setFetchingCoordinates(false);
    }
  };

  const handleLogoUpload = async (id, file) => {
    setUpdatingLogo(true);
    try {
      const storageRef = ref(storage, `logos/${id}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      await uploadTask;

      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
      await updateDoc(doc(db, "hospitals", id), { logoURL: downloadURL });

      setUpdateMessage("Logo updated successfully!");
      alert("Logo updated successfully!");
    } catch (error) {
      console.error("Error updating logo:", error);
      setUpdateMessage("Error updating logo. Please try again.");
    } finally {
      setUpdatingLogo(false);
      setTimeout(() => {
        setUpdateMessage(null);
      }, 3000);
    }
  };

  const handleChatBotLogoUpload = async (id, file) => {
    setUpdatingLogo(true);
    try {
      const storageRef = ref(storage, `chatBotLogos/${id}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      await uploadTask;
  
      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
      await updateDoc(doc(db, "hospitals", id), { chatBotLogoURL: downloadURL });
  
      setUpdateMessage("ChatBot Logo updated successfully!");
      alert("ChatBot Logo updated successfully!");
    } catch (error) {
      console.error("Error updating ChatBot logo:", error);
      setUpdateMessage("Error updating ChatBot logo. Please try again.");
    } finally {
      setUpdatingLogo(false);
      setTimeout(() => {
        setUpdateMessage(null);
      }, 3000);
    }
  };

  const toggleEditMode = (id) => {
    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [id]: !prevEditMode[id],
    }));
    setEditableFields((prev) => ({
      ...prev,
      [id]: hospitals.find((hospital) => hospital.id === id) || {},
    }));
  };

  const handleCancelEdit = (id) => {
    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [id]: false,
    }));
    setEditableFields((prev) => {
      const newFields = { ...prev };
      delete newFields[id];
      return newFields;
    });
  };

  return (
    <div className="Phospitals-data">
      <h4 className="LoginFormTitle">Profile</h4>
      {updateMessage && <p className="update-message">{updateMessage}</p>}
      <ul className="Phospital-list">
        {hospitals.map((hospital) => (
          <li key={hospital.id} className="Phospital-item">
            <h3>{hospital.HospName}</h3>
      
            {/* <p>
              Verification Status:{" "}
              {hospital.VERIF === "X"
                ? "Pending"
                : hospital.VERIF === "Y"
                ? "verified"
                : hospital.VERIF}{" "}
            </p> */}

            
            <br/>
            {hospital.country !== "India" && (
              <p>Governorate: {hospital.governorate}</p>
            )}
            <br/>
            <p>Currency: {hospital.currency}</p>
            <br/>
            <p>Governorate: {hospital.governorate}</p>
            <br/>
            <p>Phone Code: {hospital.phoneCode}</p>
            <br/>






        
  <span class="platform-fee">Platform Fee: {hospital.currency} {hospital.PFEE.toFixed(3)} per Approved or Created Patient
  <span class="noteKCC">
  {/* Bills will only be displayed at the end of each month.<br/> */}
    <a href="/billing" target="_blank">Check your bills</a>
  </span>
    
    </span>
    
    <br/>


  <span class="krysta-care-commission">Krysta Care Commission: {(hospital.KCC * 100).toFixed(0)}% of 
    Doctor Consultation Fee
    <br/>
    
    <span class="noteKCC">
    This commission will only be charged for patients who book a consultation with the doctor through <br/>
    <a href="https://krysta.care" target="_blank">www.krysta.care</a>
  </span>
  
  </span>


            {hospital.country !== "Oman" && <p>State: {hospital.state}</p>}
            <br />

            <div className="location-container">
              <p>
                Your Location Coordinates:{" "}
                {fetchingCoordinates ? "Updating..." : hospital.location}
              </p>
              <button
                onClick={() => handleFetchCoordinates(hospital.id)}
                className="fetch-coordinates-btn"
              >
                Re-Fetch your Location Coordinates
              </button>
            </div>

            <div className="logo-container">
            <h4>Main Logo</h4>
            <br/>
            <br/>
              {hospital.logoURL && (
                <div>
                  <img
                    src={hospital.logoURL}
                    alt="Hospital Logo"
                    className="hospital-logo"
                  />
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleLogoUpload(hospital.id, e.target.files[0])
                }
                className="logo-input"
              />
              {updatingLogo && <p>Updating logo...</p>}
              <p className="LUnote">NOTE: Please log in again to view your updated logo.</p>
            </div>

            {/* New ChatBot Logo container */}
<div className="chatbot-logo-container">
  <h4>ChatBot Logo</h4>
  {hospital.chatBotLogoURL && (
    <div>
      <img
        src={hospital.chatBotLogoURL}
        alt="ChatBot Logo"
        className="chatbot-logo"
      />
    </div>
  )}
  <input
    type="file"
    accept="image/*"
    onChange={(e) => handleChatBotLogoUpload(hospital.id, e.target.files[0])}
    className="chatbot-logo-input"
  />
  {updatingLogo && <p>Updating ChatBot logo...</p>}
  <p className="LUnote">NOTE: Please log in again to view your updated ChatBot logo.</p>
</div>

            {[
              { label: "Name", field: "HospName" },
              { label: "Address", field: "address" },
              { label: "Contact Number", field: "contactNumber" },
              { label: "Telephone Number", field: "telephoneNumber" },
              { label: "Contact Person", field: "contactPerson" },
              { label: "Email", field: "email" },
              { label: "Place", field: "place" },
              { label: "Website", field: "website" },
            ].map(({ label, field }) => (
              <p key={field}>
                {label}:{" "}
                {editMode[hospital.id] ? (
                  <input
                    type="text"
                    placeholder={
                      field === "contactNumber" || field === "telephoneNumber"
                        ? `Enter your ${label.toLowerCase()} without (${phoneCode})`
                        : ""
                    }
                    defaultValue={
                      field === "contactNumber" || field === "telephoneNumber"
                        ? hospital[field].replace(phoneCode, "")
                        : hospital[field]
                    }
                    onBlur={(e) =>
                      handleFieldChange(
                        hospital.id,
                        field,
                        field === "contactNumber" || field === "telephoneNumber"
                          ? phoneCode + e.target.value
                          : e.target.value
                      )
                    }
                  />
                ) : (
                  hospital[field]
                )}
              </p>
            ))}

            <br />
            {editMode[hospital.id] ? (
              <>
                <button
                  onClick={() => handleUpdate(hospital.id)}
                  className="save-btn"
                >
                  Save
                </button>
                <button
                  onClick={() => handleCancelEdit(hospital.id)}
                  className="PFL-cancel-btn"
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                onClick={() => toggleEditMode(hospital.id)}
                className="edit-btn"
              >
                Edit Profile
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HospitalsData;
