import React, { useEffect, useState, useContext } from "react";
import { db } from "../../firebaseconfig";
import {
  collection,
  onSnapshot,
  doc,
  updateDoc,
  serverTimestamp,
  deleteDoc,
  where,
  query,
  getDoc,
} from "firebase/firestore";
import ALogin from "../ScreenForAdmin/ALogin";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { HospitalContext } from "../ScreenForAdmin/HospitalContext"; // Import HospitalContext
import { Link } from 'react-router-dom';
import StatisticsPage from "../Dashboard/Statics/Stat";
import './AAV.css';

const ScreenAPWU = () => {
  const [patients, setPatients] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [user, setUser] = useState(null);

  const [patientsA, setPatientsA] = useState([]);
  const [patientsB, setPatientsB] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [dateFilter, setDateFilter] = useState("");
const [doctorFilter, setDoctorFilter] = useState("");

const handleDateFilter = (e) => {
  setDateFilter(e.target.value); // This will already be in YYYY-MM-DD format
};

const handleDoctorFilter = (e) => {
  setDoctorFilter(e.target.value);
};

const clearFilters = () => {
  setSearchTerm("");
  setDateFilter("");
  setDoctorFilter("");
};

  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;

  const auth = getAuth();

  useEffect(() => {
    const unsubscribePatients = onSnapshot(
      collection(db, "patients"),
      (snapshot) => {
        const sortedPatients = snapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter(
            (patient) =>
              patient.tag === "PWU" &&
              patient.HID === HID &&
              (patient.status === "A" || patient.status === "B")
          )
          .sort((a, b) => {
            const timeA = a.requestTime ? a.requestTime.seconds : 0;
            const timeB = b.requestTime ? b.requestTime.seconds : 0;
            return timeA - timeB;
          });

        setPatientsA(
          sortedPatients.filter((patient) => patient.status === "A")
        );
        setPatientsB(
          sortedPatients.filter((patient) => patient.status === "B")
        );
      }
    );

    // const unsubscribeDoctors = onSnapshot(collection(db, 'doctors'), (snapshot) => {
    //   setDoctors(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    // });

    const unsubscribeDoctors = onSnapshot(
      query(collection(db, "doctors"), where("hospitalData.HID", "==", HID)),
      (snapshot) => {
        setDoctors(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      }
    );

    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribePatients();
      unsubscribeDoctors();
      unsubscribeAuth();
    };
  }, [auth, HID]); // Include HID in dependency array

  const formatTimeSlot = (timeSlot) => {
    const [start, end] = timeSlot.split("-");
    return `${formatTime(start)}  ${formatTime(end)}`;
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // const filterPatients = (patients) => {
  //   return patients.filter((patient) =>
  //     patient.mobileNumber.includes(searchTerm)
  //   );
  // };


  const filterPatients = (patients) => {
    return patients.filter((patient) => {
      const matchesMobile = patient.mobileNumber.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesDate = dateFilter === "" || formatDate(patient.appointmentDate) === dateFilter;
      const matchesDoctor = doctorFilter === "" || patient.doctorAssigned === doctorFilter;
      return matchesMobile && matchesDate && matchesDoctor;
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const formatTime = (time) => {
    if (!time) return ""; // Return an empty string if time is undefined
    const [hours, minutes] = time.split(":");
    const period = parseInt(hours, 10) >= 12 ? "PM" : "AM";
    const formattedHours = (((parseInt(hours, 10) + 11) % 12) + 1).toString();
    return `${formattedHours}:${minutes} ${period}`;
  };

  const formatAppointmentDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleCallToReception = async (patientId) => {
    const patientDocRef = doc(db, "patients", patientId);
    await updateDoc(patientDocRef, {
      status: "B",
      tokenGenerationTime: serverTimestamp(),
    });
  };

  const handlePaymentDone = async (patient) => {
    const confirmation = window.confirm(
      `Did you WhatsApp or SMS the payment receipt message to ${patient.name}?\n\n` +
      `If so, please click on OK button`
    );
  
    if (confirmation) {
      const doctor = doctors.find((doc) => doc.name === patient.doctorAssigned);
      if (doctor) {
        const patientDocRef = doc(db, "patients", patient.id);
  
        // Convert selectedTimeslot string into a Firestore Timestamp
        const selectedDate = new Date(patient.appointmentDate);
        const [hours, minutes] = patient.selectedTimeslot.split(":");
        selectedDate.setHours(hours, minutes, 0, 0);
        const selectedTimeslotTimestamp = selectedDate.toISOString();
  
        await updateDoc(patientDocRef, {
          status: "C",
          tokenGenerationTime: serverTimestamp(),
          selectedTimeslot: selectedTimeslotTimestamp,
        });
  
        console.log("Payment confirmed and status updated to 'C'");
      } else {
        console.log("Doctor not found");
      }
    } else {
      console.log("Payment confirmation cancelled");
    }
  };

  const assignDoctor = async (patientId, doctorId) => {
    const doctor = doctors.find((doc) => doc.id === doctorId);
    if (doctor) {
      const patientDocRef = doc(db, "patients", patientId);
      await updateDoc(patientDocRef, {
        doctorAssigned: doctor.name,
        doctorRoom: doctor.room,
      });
    }
  };

  const renderStatus = (patient) => {
    switch (patient.status) {
      case "A":
        return "Awaiting Approval for Appointment";
      case "B":
        return "Awaiting Payment";
      case "C":
        return "Payment Recived";
      case "D":
        return "Doctor called the patient";
      case "E":
        return "Consultation Completed";
      case "F":
        return "No Show";
      default:
        return "N/A";
    }
  };

  const handleDeleteRecord = async (patientId) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this record?\n\n" +
        "This action will make this appointment slot free for booking as well "
    );

    if (confirmation) {
      try {
        // Create a reference to the patient document in Firestore
        const patientDocRef = doc(db, "patients", patientId);

        // Get the patient document from Firestore
        const patientDocSnapshot = await getDoc(patientDocRef);

        // Check if the patient document exists
        if (patientDocSnapshot.exists()) {
          const patientData = patientDocSnapshot.data();

          // Extract the APID from the patient record
          const apid = patientData.APID;

          // Delete the patient record from Firestore
          await deleteDoc(patientDocRef);
          console.log("Record deleted successfully");

          // Update the status of the selected timeslot in the "Appointments" collection
          if (apid) {
            const [
              appointmentId,
              appointmentDate,
              doctorName,
              selectedTimeslot,
            ] = apid.split("_");
            const appointmentDocRef = doc(db, "Appointments", appointmentId);
            const appointmentDocSnapshot = await getDoc(appointmentDocRef);

            // Check if the appointment document exists
            if (appointmentDocSnapshot.exists()) {
              const appointmentData = appointmentDocSnapshot.data();

              // Update the status of the corresponding timeslot
              const updatedAvailableTimeslots =
                appointmentData.availableTimeslots.map((slot) => {
                  if (slot.startTime === selectedTimeslot) {
                    return { ...slot, status: "Y" }; // Set status to 'Y' for matched timeslot
                  }
                  return slot;
                });

              // Update the appointment document with the updated timeslots
              await updateDoc(appointmentDocRef, {
                availableTimeslots: updatedAvailableTimeslots,
              });

              console.log("Timeslot status updated successfully");
            } else {
              console.log("Appointment document not found");
            }
          } else {
            console.log("APID not found in patient record");
          }
        } else {
          console.log("Patient document not found");
        }
      } catch (error) {
        console.error("Error deleting record:", error);
      }
    }
  };

  const renderPatientAction = (patient) => {
    if (patient.tokenNumber) {
      return <span>Token Generated</span>;
    } else if (patient.status === "A") {
      return (
        <button onClick={() => handleCallToReception(patient.id)}>
          Approve Appointment
        </button>
      );
    } else if (patient.status === "B") {
      // Render all three buttons for status 'B'
      return (
        <div>
          
          {/* <button
            classname="ARPaction-buttons"
            onClick={() => handlePaymentDone(patient)}
          >
            Payment Done
          </button> */}

          <button
            classname="ARPaction-buttons"
            onClick={() => sendSMS(patient)}
          >
            SMS
          </button>
          <button
            classname="ARPaction-buttons"
            onClick={() => shareOnWhatsApp(patient)}
          >
           WhatsApp
          </button>
        </div>
      );
    } else {
      return (
        <button
          className="ARPaction-buttons"
          onClick={() => handlePaymentDone(patient)}
        >
          Verify & Approve
        </button>
      );
    }
  };

  const patientsWithoutTokenPWU = patients
    .filter(
      (patient) =>
        patient.tag === "PWU" &&
        (patient.status === "A" || patient.status === "B")
    )
    .sort((a, b) => {
      const timeA = a.requestTime ? a.requestTime.seconds : 0;
      const timeB = b.requestTime ? b.requestTime.seconds : 0;
      return timeA - timeB;
    });

  const getGoogleMapsUrl = (latitude, longitude) => {
    if (!latitude || !longitude) return "";
    return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
  };

  const shareOnWhatsApp = (patientDetails) => {
    const {
      name,
      mobileNumber,
      selectedDepartment,
      doctorAssigned,
      doctorRoom,
      appointmentDate,
      selectedTimeslot,
      currency,
      consultationFee,
    } = patientDetails;
    const formattedDate = formatAppointmentDate(appointmentDate);
    const formattedTimeSlot = formatTimeSlot(selectedTimeslot);
    const locationUrl = getGoogleMapsUrl(
      hospitalData?.location?.split(",")[0],
      hospitalData?.location?.split(",")[1]
    );

    const message = `Hello ${name},
    
    We would like to remind you about your appointment.
    
    Your appointment details are as follows:
    
    Name of the patient: ${name}
    Mobile Number used for appointment: ${mobileNumber}
    Selected Department: ${selectedDepartment}
    Assigned Doctor: ${doctorAssigned}
    Doctor's Room: ${doctorRoom}
    Appointment Date: ${formattedDate}
    Appointment Time: ${formattedTimeSlot}
    
    
    You can view your latest appointment status by visiting 
    
    https://oih.krysta.care/AppointmentStatus
    
    Thank you for choosing ${hospitalData.HospName}.`;

    const encodedMessage = encodeURIComponent(message);
    const whatsappWebUrl = `https://web.whatsapp.com/send?phone=${mobileNumber}&text=${encodedMessage}`;
    const whatsappMobileUrl = `whatsapp://send?phone=${mobileNumber}&text=${encodedMessage}`;

    // Check if the device is a mobile device (phone or tablet)
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    if (isMobile) {
      // Open the WhatsApp mobile app directly
      window.open(whatsappMobileUrl, "_blank");
    } else {
      // Open WhatsApp Web
      window.open(whatsappWebUrl, "_blank");
    }
  };

  const sendSMS = (patientDetails) => {
    const {
      name,
      mobileNumber,
      selectedDepartment,
      doctorAssigned,
      doctorRoom,
      appointmentDate,
      selectedTimeslot,
      currency,
      consultationFee,
    } = patientDetails;
    const formattedDate = formatAppointmentDate(appointmentDate);
    const formattedTimeSlot = formatTimeSlot(selectedTimeslot);
    const locationUrl = getGoogleMapsUrl(
      hospitalData?.location?.split(",")[0],
      hospitalData?.location?.split(",")[1]
    );

    const message = `Hello ${name},
    
    We would like to remind you about your appointment.
    
    Your appointment details are as follows:
    
    Name of the patient: ${name}
    Mobile Number used for appointment registration: ${mobileNumber}
    Selected Department: ${selectedDepartment}
    Assigned Doctor: ${doctorAssigned}
    Doctor's Room: ${doctorRoom}
    Appointment Date: ${formattedDate}
    Appointment Time: ${formattedTimeSlot}
   

  You can view your latest appointment status by visiting
  
  https://oih.krysta.care/AppointmentStatus
    
  Thank you for choosing ${hospitalData.HospName}.`;

    const url = `sms:${mobileNumber}?body=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  return (
    <div className="ARPscreen-APWU">

<div>
        <Link to="/Dashboard" className="button2">
      Go to Dashboard
    </Link>
        </div>
        
      {/* <div className='ARPSTATU'><StatisticsPage/>
        </div> */}

      {user ? (
        <>
          <div>
            <h3 className="ARPtitleMain">Remind Patients - Upcoming Appointments </h3>

            {/* <div className="ssearch-bar">
              <input
                type="text"
                placeholder={`Search by mobile number without [ ${
                  hospitalData?.phoneCode || ""
                } ]`}
                value={searchTerm}
                onChange={handleSearch}
              />
            </div> */}


<div className="BBsearch-bar">
  <input
    type="text"
    placeholder={`Search by mobile number without [ ${
      hospitalData?.phoneCode || ""
    } ]`}
    value={searchTerm}
    onChange={handleSearch}
    className="BBinputt"
  />
  <p className="BBinputlabel">Filter by Date:</p>
  <input
    type="date"
    value={dateFilter}
    onChange={handleDateFilter}
    placeholder="Filter by date"
    className="BBinput"
  
  />
  <select
    value={doctorFilter}
    onChange={handleDoctorFilter}
    className="BBselectt"
  >
    <option  className="BBselect" value="">All Doctors</option>
    
    {doctors.map((doctor) => (
      <option key={doctor.id} value={doctor.name}>
        {doctor.name}
      </option>
    ))}
  </select>
  <button className="BBclear-button" onClick={clearFilters}>
    Clear Filters
  </button>
</div>




            {/* <h3 className="CategoryTitle">Awaiting Appointment Approval</h3>
            <div className="ARPadmin-patient-list">
              {filterPatients(patientsA).map((patient) => (
                <div className="ARPtile" key={patient.id}>
                  <div className="ARPpatient-info">
                    <p>
                      <span className="field">Name of the Patient:</span>{" "}
                      <span className="data">{patient.name}</span>
                    </p>
                    <p>
                      <span className="field">Mobile Number:</span>{" "}
                      <span className="data">{patient.mobileNumber}</span>
                    </p>
                    <p>
                      <span className="field">Requested Time Point:</span>{" "}
                      <span className="data">
                        {formatTimeSlot(patient.selectedTimeslot)}
                      </span>
                    </p>
                    <p>
                      <span className="field">Appointment Date:</span>{" "}
                      <span className="data">
                        {formatAppointmentDate(patient.appointmentDate)}
                      </span>
                    </p>
                    <p>
                      <span className="field">Requested Doctor:</span>{" "}
                      <span className="data">
                        {patient.doctorAssigned || "Not assigned"}
                      </span>
                    </p>
                    <p>
                      <span className="field">Status:</span>{" "}
                      <span className="data">{renderStatus(patient)}</span>
                    </p>
                    <p>
                      <span className="field">Consultation Fee:</span>{" "}
                      <span className="data">
                        {patient.currency}
                        {patient.consultationFee}
                      </span>
                    </p>
                    {patient.consultationFeeComments && (
                      <p>
                        <span className="field">Fee Comments:</span>{" "}
                        <span className="data">
                          {patient.consultationFeeComments}
                        </span>
                      </p>
                    )}
                    <p>
                      <span className="field">Appointment Source:</span>
                      <span className="data">
                        {patient.tag === "PWU"
                          ? "Direct"
                          : patient.tag === "KCP"
                          ? "Krysta.Care"
                          : patient.tag}
                      </span>
                    </p>
                  </div>
                  <div className="ARPaction-buttons">
                    {renderPatientAction(patient)}
                    <button
                      className="ARPDelete-button"
                      onClick={() => handleDeleteRecord(patient.id)}
                    >
                      Delete Record
                    </button>
                  </div>
                </div>
              ))}
            </div> */}

            {/* <h3 className="CategoryTitle">
              Patient(s) pending to make Payment{" "}
            </h3> */}
            <div className="ARPadmin-patient-list">
              {filterPatients(patientsB).map((patient) => (
                <div className="ARPtile" key={patient.id}>
                  <div className="ARPpatient-info">
                    <p>
                      <span className="field">Name of the Patient:</span>{" "}
                      <span className="data">{patient.name}</span>
                    </p>
                    <p>
                      <span className="field">Mobile Number:</span>{" "}
                      <span className="data">{patient.mobileNumber}</span>
                    </p>
                    <p>
                      <span className="field">Requested Time Point:</span>{" "}
                      <span className="data">
                        {formatTimeSlot(patient.selectedTimeslot)}
                      </span>
                    </p>
                    <p>
                      <span className="field">Appointment Date:</span>{" "}
                      <span className="data">
                        {formatAppointmentDate(patient.appointmentDate)}
                      </span>
                    </p>
                    <p>
                      <span className="field">Requested Doctor:</span>{" "}
                      <span className="data">
                        {patient.doctorAssigned || "Not assigned"}
                      </span>
                    </p>
                    <p>
                      <span className="field">Status:</span>{" "}
                      <span className="data">{renderStatus(patient)}</span>
                    </p>
                    <p>
                      <span className="field">Consultation Fee:</span>{" "}
                      <span className="data">
                        {patient.currency}
                        {patient.consultationFee}
                      </span>
                    </p>
                    {patient.consultationFeeComments && (
                      <p>
                        <span className="field">Fee Comments:</span>{" "}
                        <span className="data">
                          {patient.consultationFeeComments}
                        </span>
                      </p>
                    )}
                    <p>
                      <span className="field">Appointment Source:</span>
                      <span className="data">
                        {patient.tag === "PWU"
                          ? "Direct"
                          : patient.tag === "KCP"
                          ? "Krysta.Care"
                          : patient.tag}
                      </span>
                    </p>
                  </div>
                  <div className="ARPaction-buttons">
                    {renderPatientAction(patient)}
                    <button
                      className="ARPDelete-button"
                      onClick={() => handleDeleteRecord(patient.id)}
                    >
                      Delete Record
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <ALogin onLogin={() => setUser(auth.currentUser)} />
      )}
    </div>
  );
};

export default ScreenAPWU;
