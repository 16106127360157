import React, { useState, useEffect, useContext } from "react";
import { db } from "../../../firebaseconfig";
import { HospitalContext } from '../../ScreenForAdmin/HospitalContext';
import {
  collection,
  onSnapshot,
  doc,
  updateDoc,
  writeBatch,
  query,
  where,
} from "firebase/firestore";
import { Link } from 'react-router-dom';
import "./Stat.css";

const DoctorsManager = () => {
  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;

  const [doctors, setDoctors] = useState([]);
  const [showCounters, setShowCounters] = useState(false);
  const [resetMessage, setResetMessage] = useState("");
  const [totalPWU, setTotalPWU] = useState(0);
  const [totalQR, setTotalQR] = useState(0);
  const [totalStatusBQR, setTotalStatusBQR] = useState(0); // Walk-in patients to make payment
  const [totalStatusBPWU, setTotalStatusBPWU] = useState(0); // Appointment patients to make payment
  const [totalStatusC, setTotalStatusC] = useState(0);
  const [totalStatusD, setTotalStatusD] = useState(0);
  const [totalStatusE, setTotalStatusE] = useState(0);

  useEffect(() => {
    const unsubscribeDoctors = onSnapshot(
      query(collection(db, "doctors"), where("hospitalData.HID", "==", HID)),
      (snapshot) => {
        setDoctors(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      }
    );

    const unsubscribePatientsPWU = onSnapshot(
      query(
        collection(db, "patients"),
        where("tag", "==", "PWU"),
        where("HID", "==", HID),
        where("status", "in", ["A"])
      ),
      (snapshot) => {
        setTotalPWU(snapshot.size);
      }
    );

    const unsubscribePatientsQR = onSnapshot(
      query(
        collection(db, "patients"),
        where("tag", "==", "QR"),
        where("HID", "==", HID),
        where("status", "in", ["A"])
      ),
      (snapshot) => {
        setTotalQR(snapshot.size);
      }
    );

    const unsubscribePatientsStatusBQR = onSnapshot(
      query(
        collection(db, "patients"),
        where("status", "==", "B"),
        where("tag", "==", "QR"),
        where("HID", "==", HID)
      ),
      (snapshot) => {
        setTotalStatusBQR(snapshot.size);
      }
    );

    const unsubscribePatientsStatusBPWU = onSnapshot(
      query(
        collection(db, "patients"),
        where("status", "==", "B"),
        where("tag", "==", "PWU"),
        where("HID", "==", HID)
      ),
      (snapshot) => {
        setTotalStatusBPWU(snapshot.size);
      }
    );

    const unsubscribePatientsStatusC = onSnapshot(
      query(
        collection(db, "patients"),
        where("status", "==", "C"),
        where("HID", "==", HID)
      ),
      (snapshot) => {
        setTotalStatusC(snapshot.size);
      }
    );

    const unsubscribePatientsStatusD = onSnapshot(
      query(
        collection(db, "patients"),
        where("status", "==", "D"),
        where("HID", "==", HID)
      ),
      (snapshot) => {
        setTotalStatusD(snapshot.size);
      }
    );

    const unsubscribePatientsStatusE = onSnapshot(
      query(
        collection(db, "patients"),
        where("status", "==", "E"),
        where("HID", "==", HID)
      ),
      (snapshot) => {
        setTotalStatusE(snapshot.size);
      }
    );

    return () => {
      unsubscribeDoctors();
      unsubscribePatientsPWU();
      unsubscribePatientsQR();
      unsubscribePatientsStatusBQR();
      unsubscribePatientsStatusBPWU();
      unsubscribePatientsStatusC();
      unsubscribePatientsStatusD();
      unsubscribePatientsStatusE();
    };
  }, [HID]);

  const resetAllCounters = async () => {
    const userConfirmation = window.confirm(
      "Are you sure you want to reset all counters?"
    );

    if (userConfirmation) {
      const batch = writeBatch(db);
      doctors.forEach((doctor) => {
        const doctorRef = doc(db, "doctors", doctor.id);
        batch.update(doctorRef, { counter: 0 });
      });

      try {
        await batch.commit();
        setShowCounters(true);
        setResetMessage("All counters have been reset successfully!");
        setTimeout(() => {
          setShowCounters(false);
          setResetMessage("");
        }, 5000);
      } catch (error) {
        console.error("Error resetting counters:", error);
        setResetMessage("Failed to reset counters. Please try again.");
        setTimeout(() => {
          setShowCounters(false);
          setResetMessage("");
        }, 5000);
      }
    }
  };

  return (
    <div className="STAT-patientsContainer">



      <Link to="/appointmentrequests" className="STAT-cardLink">
        <div className="STAT-patientCard">
          <h4>Appointments <br/>Requests</h4>
          <p className="STAT-patientCount">{totalPWU}</p>
        </div>
      </Link>

      <Link to="/TokenRequests" className="STAT-cardLink">
        <div className="STAT-patientCard">
          <h4>Token<br/>Requests</h4>
          <p className="STAT-patientCount">{totalQR}</p>
        </div>
      </Link>
      <Link to="/PatientPayments-QR" className="STAT-cardLink">
      <div className="STAT-patientCard">
        <h4>Walk-In Patients<br/>to make Payment</h4>
        <p className="STAT-patientCount">{totalStatusBQR}</p>
      </div>
      </Link>

      <Link to="/PatientPayments-AP" className="STAT-cardLink">
      <div className="STAT-patientCard">
        <h4>Appointment Patients <br/>to make Payment</h4>
        <p className="STAT-patientCount">{totalStatusBPWU}</p>
      </div>
      </Link>

      <Link to="/PatientToBeConsulted" className="STAT-cardLink">
      <div className="STAT-patientCard">
        <h4>Patients <br/>to be consulted</h4>
        <p className="STAT-patientCount">{totalStatusC}</p>
      </div>
      </Link>

      {/* <div className="STAT-patientCard">
        <h4>Patients <br/>got consultation</h4>
        <p className="STAT-patientCount">{totalStatusE}</p>
      </div> */}
    </div>
  );
};

export default DoctorsManager;
