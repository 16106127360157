import React, { useState, useEffect, useContext, useRef } from 'react';
import { db, auth } from '../../firebaseconfig';
import { collection, getDocs, query, where, onSnapshot } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { HospitalContext } from '../ScreenForAdmin/HospitalContext';
import ALogin from './VAPLogin';
import './screenVAP.css';

const Screen = () => {
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [selectedVideoLinks, setSelectedVideoLinks] = useState([]);
  const [showVideo, setShowVideo] = useState(true);
  const [doctors, setDoctors] = useState([]);
  const [videoLinks, setVideoLinks] = useState([]);
  const [user, setUser] = useState(null);
  const videoPlayerRef = useRef(null);

  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;

  useEffect(() => {
    const fetchDoctors = async () => {
      if (hospitalData && hospitalData.HID) {
        const doctorsCollection = collection(db, 'doctors');
        const q = query(doctorsCollection, where("hospitalData.HID", "==", hospitalData.HID));
        const querySnapshot = await getDocs(q);
        const doctorsData = querySnapshot.docs.map((doc) => doc.data());
        setDoctors(doctorsData);
      } else {
        console.warn('hospitalData or hospitalData.HID is undefined');
      }
    };
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    fetchDoctors();

    const videoLinksCollection = collection(db, 'videoLinks');
    const unsubscribe = onSnapshot(query(videoLinksCollection, where("HID", "==", HID)), (snapshot) => {
      const links = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVideoLinks(links);
    });

    return () => {
      unsubscribeAuth();
      unsubscribe();
    };
  }, [HID]);

  const handleDoctorSelect = (doctorName) => {
    setSelectedDoctors((prevSelected) =>
      prevSelected.includes(doctorName)
        ? prevSelected.filter((name) => name !== doctorName)
        : [...prevSelected, doctorName]
    );
  };

  const handleVideoLinkSelect = (videoLinkId) => {
    setSelectedVideoLinks((prevSelected) =>
      prevSelected.includes(videoLinkId)
        ? prevSelected.filter((id) => id !== videoLinkId)
        : [...prevSelected, videoLinkId]
    );
  };

  const toggleVideo = () => {
    setShowVideo((prevState) => !prevState);
  };

  const handleCopyLink = () => {
    const selectedVideoLinkIds = selectedVideoLinks.join(',');
    const shareableLink = `${window.location.origin}/token-details?doctors=${selectedDoctors.join(',')}&videoLinks=${selectedVideoLinkIds}&showVideo=${showVideo}`;
    navigator.clipboard.writeText(shareableLink);
    alert('Link copied to clipboard!');
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log('User signed out successfully');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  useEffect(() => {
    if (selectedVideoLinks.length > 0 && showVideo) {
      const videoPlayer = videoPlayerRef.current;
      let currentVideoIndex = 0;

      const playVideos = () => {
        const videoLink = selectedVideoLinks[currentVideoIndex];
        const videoUrl = `https://www.youtube.com/embed/${videoLink}?autoplay=1`;
        videoPlayer.src = videoUrl;

        currentVideoIndex = (currentVideoIndex + 1) % selectedVideoLinks.length;
      };

      const intervalId = setInterval(playVideos, 10 * 60 * 1000); // Play next video every 10 minutes

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [selectedVideoLinks, showVideo]);

  return (
    <div className="ST-screen-container">
      <h1 className="ST-main-heading">Selection of Doctor(s) and Videos for Serving Tokens Page [ For TV screen ]</h1>
      <p className='noteVAP'>Open this page on a TV screen browser and log in 
        with your email and password, and click the red button at the bottom to display the token numbers.</p>

      <br/>
  
      {user ? (
        <>
          <div className="ST-doctor-selection">
            <h2>Select Doctors:</h2>
            {doctors.map((doctor, index) => (
              <div key={index} className="ST-doctor-checkbox">
                <input
                  type="checkbox"
                  id={`doctor-${index}`}
                  value={doctor.name}
                  checked={selectedDoctors.includes(doctor.name)}
                  onChange={() => handleDoctorSelect(doctor.name)}
                />
                <label htmlFor={`doctor-${index}`}>{doctor.name}</label>
              </div>
            ))}
          </div>
  
          <div className="ST-video-selection">
            <h2>Select from saved YouTube Video Links</h2>

            <div className='AvailDays3'>
  <p>To add YouTube video links to the list below for selection, please follow 

  <Link className="link" to="/VideosForServingTokenPage"> this link</Link>
  </p>
  
</div>

          
            {videoLinks.map((videoLink) => (
              <div key={videoLink.id} className="ST-video-link-checkbox">
                <input
                  type="checkbox"
                  id={`videoLink-${videoLink.id}`}
                  value={videoLink.id}
                  checked={selectedVideoLinks.includes(videoLink.id)}
                  onChange={() => handleVideoLinkSelect(videoLink.id)}
                />
                {/* <label htmlFor={`videoLink-${videoLink.id}`}>{videoLink.label}: {videoLink.link}</label> */}
                <label htmlFor={`videoLink-${videoLink.id}`}>{videoLink.label}</label>
              </div>
            ))}
          </div>
  
          <div className="ST-buttons-container">
            <button onClick={toggleVideo}>{showVideo ? 'Hide Video' : 'Show Video'}</button>
            <button onClick={handleCopyLink}>Copy Link for Serving Tokens Page</button>
            
            <Link
              to={{
                pathname: '/token-details',
                search: `?doctors=${selectedDoctors.join(',')}&videoLinks=${selectedVideoLinks.join(',')}&showVideo=${showVideo}`,
              }}
            >
              {/* <button>Play Serving Tokens Page on this Screen</button> */}

              <button style={{ backgroundColor: 'red', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
  Play Serving Tokens Page on this TV Screen
</button>



            </Link>

            <button className="ST-logout-btn" onClick={handleLogout}>
              Logout
            </button>

          </div>
        </>
      ) : (
        <ALogin onLogin={() => setUser(auth.currentUser)} />
      )}
    </div>
  );
  
};

export default Screen;