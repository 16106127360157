import React, { useEffect, useState, useContext } from "react";
import { db } from "../../firebaseconfig";
import {
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import ALogin from "../ScreenForAdmin/ALogin";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { HospitalContext } from "../ScreenForAdmin/HospitalContext"; 
import StatisticsPage from "../Dashboard/Statics/Stat";
import "./AllAppointmentsForDoctor.css";

const ScreenAPWU = () => {
  const [patients, setPatients] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [user, setUser] = useState(null);
  const [patientsA, setPatientsA] = useState([]);
  const [patientsB, setPatientsB] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState(() => localStorage.getItem("selectedDoctor") || "");
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const currentMonth = new Date().getMonth() + 1;
    return currentMonth.toString().padStart(2, '0');
  });
  const [selectedDate, setSelectedDate] = useState(""); // New state variable for date filter

  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;

  const auth = getAuth();

  useEffect(() => {
    const unsubscribePatients = onSnapshot(
      collection(db, "patients"),
      (snapshot) => {
        const sortedPatients = snapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter(
            (patient) =>
              patient.tag === "PWU" &&
              patient.HID === HID &&
              (patient.status === "A" || patient.status === "B")
          )
          .sort((a, b) => {
            const timeA = a.requestTime ? a.requestTime.seconds : 0;
            const timeB = b.requestTime ? b.requestTime.seconds : 0;
            return timeA - timeB;
          });

        setPatientsA(
          sortedPatients.filter((patient) => patient.status === "A")
        );
        setPatientsB(
          sortedPatients.filter((patient) => patient.status === "B")
        );
      }
    );

    const unsubscribeDoctors = onSnapshot(
      query(collection(db, "doctors"), where("hospitalData.HID", "==", HID)),
      (snapshot) => {
        setDoctors(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      }
    );

    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribePatients();
      unsubscribeDoctors();
      unsubscribeAuth();
    };
  }, [auth, HID]);

  const formatTimeSlot = (timeSlot) => {
    const [start, end] = timeSlot.split("-");
    return `${formatTime(start)}  ${formatTime(end)}`;
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDoctorChange = (e) => {
    const doctorName = e.target.value;
    setSelectedDoctor(doctorName);
    localStorage.setItem("selectedDoctor", doctorName);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const filterPatients = (patients) => {
    return patients.filter((patient) => {
      const patientMonth = new Date(patient.appointmentDate).getMonth() + 1;
      const patientMonthString = patientMonth.toString().padStart(2, '0');
      const appointmentDate = new Date(patient.appointmentDate).toISOString().split('T')[0];
      
      return (
        patient.mobileNumber.includes(searchTerm) &&
        (!selectedDoctor || patient.doctorAssigned === selectedDoctor) &&
        (selectedMonth === "00" || patientMonthString === selectedMonth) &&
        (!selectedDate || appointmentDate === selectedDate)
      );
    });
  };

  const formatTime = (time) => {
    if (!time) return ""; 
    const [hours, minutes] = time.split(":");
    const period = parseInt(hours, 10) >= 12 ? "PM" : "AM";
    const formattedHours = (((parseInt(hours, 10) + 11) % 12) + 1).toString();
    return `${formattedHours}:${minutes} ${period}`;
  };

  const formatAppointmentDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const renderStatus = (patient) => {
    switch (patient.status) {
      case "A":
        return "Awaiting Approval for Appointment";
      case "B":
        return "Awaiting Payment";
      case "C":
        return "Payment Received";
      case "D":
        return "Doctor called the patient";
      case "E":
        return "Consultation Completed";
      case "F":
        return "No Show";
      default:
        return "N/A";
    }
  };

  return (
    <div className="AAVDscreen-APWU">
      <div className="AAVDSTATU">
        {/* <StatisticsPage /> */}
      </div>

      {user ? (
        <>
          <div>
            <h3 className="AAVDtitleMain">
              All Upcoming Appointments
            </h3>

            <div className="AAVDssearch-bar">
              <input
                type="text"
                placeholder={`Search by mobile number without [ ${
                  hospitalData?.phoneCode || ""
                } ]`}
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>

            <div className="AAVDfilter-bar">
              
              <div className="AAVDfilter-content">
                <label htmlFor="doctor-filter">Filter by Doctor: </label>
                <select
                  id="doctor-filter"
                  value={selectedDoctor}
                  onChange={handleDoctorChange}
                >
                  <option value="">All</option>
                  {doctors.map((doctor) => (
                    <option key={doctor.id} value={doctor.name}>
                      {doctor.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="AAVDfilter-content">
                <label htmlFor="month-filter">Filter by Month: </label>
                <select
                  id="month-filter"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                >
                  <option value="00">All</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
              <div className="AAVDfilter-content">
                <label htmlFor="date-filter">Filter by Date: </label>
                <input
                  type="date"
                  id="date-filter"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </div>
            </div>

            <div className="AAVDtable-container">
              <table className="AAVDpatient-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Mobile Number</th>
                    <th>Requested Time</th>
                    <th>Appointment Date</th>
                    <th>Requested Doctor</th>
                    <th>Status</th>
                    <th>Consultation Fee</th>
                    <th>Fee Comments</th>
                    <th>Appointment Source</th>
                  </tr>
                </thead>
                <tbody>
                  {filterPatients(patientsB).map((patient) => (
                    <tr key={patient.id}>
                      <td>{patient.name}</td>
                      <td>{patient.mobileNumber}</td>
                      <td>{formatTimeSlot(patient.selectedTimeslot)}</td>
                      <td>{formatAppointmentDate(patient.appointmentDate)}</td>
                      <td>{patient.doctorAssigned || "Not assigned"}</td>
                      <td>{renderStatus(patient)}</td>
                      <td>
                        {patient.currency}
                        {patient.consultationFee}
                      </td>
                      <td>{patient.consultationFeeComments || "N/A"}</td>
                      <td>
                        {patient.tag === "PWU"
                          ? "Direct"
                          : patient.tag === "KCP"
                          ? "Krysta.Care"
                          : patient.tag}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <ALogin onLogin={() => setUser(auth.currentUser)} />
      )}
    </div>
  );
};

export default ScreenAPWU;
