import React, { useState, useEffect, useContext } from "react";
import { db, storage } from "../../firebaseconfig";
import { HospitalContext } from '../ScreenForAdmin/HospitalContext'; // Import HospitalContext
import {
  collection,
  onSnapshot,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
  setDoc,
  writeBatch,
  query,
  where
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import ALogin from "../ScreenForAdmin/ALogin";
import { auth } from "../../firebaseconfig";
import { Link } from 'react-router-dom';
import "./ViewDoctors.css";



const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];



const formatTime = (time) => {
  const [hours, minutes] = time.split(":");
  const parsedHours = parseInt(hours, 10);
  const period = parsedHours >= 12 ? "PM" : "AM";
  const formattedHours = parsedHours % 12 || 12;
  return `${formattedHours}:${minutes} ${period}`;
};

const DoctorsManager = () => {
  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;
  const [doctors, setDoctors] = useState([]);
  const [newDoctor, setNewDoctor] = useState({
    name: "",
    room: "",
    counter: 0,
    departmentName: "",
    hospitalName: "", // New field for Hospital Name
    availability: "Y",
    availableDays: [],
    timeSlots: [],
    photoURL: "",
    appointmentAllowed: "Y", // New field to store appointment allowance
    consultationFee: "", // Add this line
  consultationFeeComments: "", // Add this line
  });
  const [user, setUser] = useState(null);

  const [photoFile, setPhotoFile] = useState(null);
 
  const [updatingPhoto, setUpdatingPhoto] = useState(false);
  const [photoUpdated, setPhotoUpdated] = useState(false);

  const [isEditingFee, setIsEditingFee] = useState(false);
  const [savingFee, setSavingFee] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  


  const [isEditingRoom, setIsEditingRoom] = useState(false);
const [isEditingQualifications, setIsEditingQualifications] = useState(false);
const [isEditingExpertise, setIsEditingExpertise] = useState(false);
const [isEditingAbout, setIsEditingAbout] = useState(false);
const [savingRoom, setSavingRoom] = useState(false);
const [savingQualifications, setSavingQualifications] = useState(false);
const [savingExpertise, setSavingExpertise] = useState(false);
const [savingAbout, setSavingAbout] = useState(false);

const saveRoom = async (id) => {
  try {
    setSavingRoom(true);
    const doctorRef = doc(db, "doctors", id);
    const updatedDoctor = doctors.find((doctor) => doctor.id === id);
    if (updatedDoctor) {
      await updateDoc(doctorRef, { room: updatedDoctor.room });
      setIsEditingRoom(false);
    }
  } catch (error) {
    console.error("Error saving room:", error);
  } finally {
    setSavingRoom(false);
  }
};

const saveQualifications = async (id) => {
  try {
    setSavingQualifications(true);
    const doctorRef = doc(db, "doctors", id);
    const updatedDoctor = doctors.find((doctor) => doctor.id === id);
    if (updatedDoctor) {
      await updateDoc(doctorRef, { educationalQualifications: updatedDoctor.educationalQualifications });
      setIsEditingQualifications(false);
    }
  } catch (error) {
    console.error("Error saving qualifications:", error);
  } finally {
    setSavingQualifications(false);
  }
};

const saveExpertise = async (id) => {
  try {
    setSavingExpertise(true);
    const doctorRef = doc(db, "doctors", id);
    const updatedDoctor = doctors.find((doctor) => doctor.id === id);
    if (updatedDoctor) {
      await updateDoc(doctorRef, { areasOfExpertise: updatedDoctor.areasOfExpertise });
      setIsEditingExpertise(false);
    }
  } catch (error) {
    console.error("Error saving areas of expertise:", error);
  } finally {
    setSavingExpertise(false);
  }
};

const saveAbout = async (id) => {
  try {
    setSavingAbout(true);
    const doctorRef = doc(db, "doctors", id);
    const updatedDoctor = doctors.find((doctor) => doctor.id === id);
    if (updatedDoctor) {
      await updateDoc(doctorRef, { aboutDoctor: updatedDoctor.aboutDoctor });
      setIsEditingAbout(false);
    }
  } catch (error) {
    console.error("Error saving about doctor:", error);
  } finally {
    setSavingAbout(false);
  }
};



const deleteDoctor = async (id) => {
  try {
    const confirmation = window.confirm("Are you sure you want to delete this doctor?");
    if (confirmation) {
      setIsDeleting(true); // Set isDeleting to true before starting the deletion

      const doctorRef = doc(db, "doctors", id);
      const doctorSnapshot = await getDoc(doctorRef);
      const doctorData = doctorSnapshot.data();

      if (doctorData.photoURL) {
        const photoRef = ref(storage, `doctorPhotos/${id}`);
        await deleteObject(photoRef);
      }
      await deleteDoc(doctorRef);
      window.location.reload(); // Refresh the page after deletion
    }
  } catch (error) {
    console.error("Error deleting doctor:", error);
  } finally {
    setIsDeleting(false); // Set isDeleting back to false after the deletion is completed
  }
};


  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(db, "doctors"),
        where("hospitalData.HID", "==", HID)
      ), // Access the nested HID field
      (snapshot) => {
        setDoctors(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            editCounter: false,
            editedCounterValue: doc.data().counter,
          }))
        );
      }
    );

    
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
      
      unsubscribeAuth();
    };
  }, [HID]); // Add HID as a dependency to the useEffect hook

  const saveConsultationFee = async (id) => {
    try {
      setSavingFee(true); // Set the loading state to true
  
      const doctorRef = doc(db, "doctors", id);
      const updatedDoctor = doctors.find((doctor) => doctor.id === id);
  
      if (updatedDoctor) {
        await updateDoc(doctorRef, {
          consultationFee: updatedDoctor.consultationFee,
          consultationFeeComments: updatedDoctor.consultationFeeComments,
        });
        setIsEditingFee(false);
      }
    } catch (error) {
      console.error("Error saving consultation fee:", error);
    } finally {
      setSavingFee(false); // Set the loading state back to false after saving
    }
  };
  
  // const deleteDoctor = async (id) => {
  //   try {
  //     const confirmation = window.confirm(
  //       "Are you sure you want to delete this doctor?"
  //     );
  //     if (confirmation) {
  //       const doctorRef = doc(db, "doctors", id);
  //       const doctorSnapshot = await getDoc(doctorRef);
  //       const doctorData = doctorSnapshot.data();

  //       if (doctorData.photoURL) {
  //         const photoRef = ref(storage, `doctorPhotos/${id}`);
  //         await deleteObject(photoRef);
  //       }
  //       await deleteDoc(doctorRef);
  //       window.location.reload(); // Refresh the page after deletion
  //     }
  //   } catch (error) {
  //     console.error("Error deleting doctor:", error);
  //   }
  // };

  // New code end

  const resetCounter = async (id) => {
    try {
      const confirmation = window.confirm(
        "Are you sure you want to reset the counter?"
      );
      if (confirmation) {
        const doctorRef = doc(db, "doctors", id);
        await updateDoc(doctorRef, { counter: 0 });
      }
    } catch (error) {
      console.error("Error resetting counter:", error);
    }
  };

  const resetAllCounters = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to reset all counters?"
    );
    if (confirmation) {
      const batch = writeBatch(db);
      doctors.forEach((doctor) => {
        const doctorRef = doc(db, "doctors", doctor.id);
        batch.update(doctorRef, { counter: 0 });
      });

      try {
        await batch.commit();
      } catch (error) {
        console.error("Error resetting counters:", error);
      }
    }
  };

  const toggleEditCounter = (id) => {
    setDoctors((prevDoctors) =>
      prevDoctors.map((doctor) =>
        doctor.id === id
          ? { ...doctor, editCounter: !doctor.editCounter }
          : doctor
      )
    );
  };

  const handleEditedCounterChange = (id, newValue) => {
    setDoctors((prevDoctors) =>
      prevDoctors.map((doctor) =>
        doctor.id === id ? { ...doctor, editedCounterValue: newValue } : doctor
      )
    );
  };

  const updateCounterValue = async (id) => {
    const updatedValue = doctors.find((doctor) => doctor.id === id)?.editedCounterValue;
    const updatedConsultationFee = doctors.find((doctor) => doctor.id === id)?.consultationFee;
    const updatedConsultationFeeComments = doctors.find((doctor) => doctor.id === id)?.consultationFeeComments;
  
    if (updatedValue !== undefined) {
      const doctorRef = doc(db, "doctors", id);
      await updateDoc(doctorRef, {
        counter: parseInt(updatedValue, 10),
        consultationFee: updatedConsultationFee,
        consultationFeeComments: updatedConsultationFeeComments,
      });
      toggleEditCounter(id);
      window.location.reload();
    }
  };

  const toggleAvailability = async (id, newAvailability) => {
    await updateDoc(doc(db, "doctors", id), { availability: newAvailability });
  };

  const toggleAppointmentAllowed = async (id, newAppointmentAllowed) => {
    await updateDoc(doc(db, "doctors", id), {
      appointmentAllowed: newAppointmentAllowed,
    });
  };
  

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    setPhotoFile(file);
  };

  const updateDoctorPhoto = async (id) => {
    const doctor = doctors.find((doctor) => doctor.id === id);
    if (!doctor) {
      console.error("Doctor not found");
      return;
    }

    try {
      setUpdatingPhoto(true); // Set updatingPhoto to true when starting the update process

      if (photoFile) {
        const photoRef = ref(storage, `doctorPhotos/${id}`);
        await uploadBytes(photoRef, photoFile);
        const photoURL = await getDownloadURL(photoRef);

        // Update the photoURL field in the Firestore document
        const doctorRef = doc(db, "doctors", id);
        await updateDoc(doctorRef, { photoURL });

        console.log("Photo updated successfully");
        setPhotoUpdated(true); // Set photoUpdated to true after successfully updating the photo
      } else {
        console.error("No photo file selected");
      }
    } catch (error) {
      console.error("Error updating photo:", error);
    } finally {
      setUpdatingPhoto(false); // Set updatingPhoto back to false after updating process completes
    }
  };

  return (
    <div className="dLLM-container">

      
      {user ? (
        <>


         {isDeleting && ( // Render the overlay only when isDeleting is true
      <div className="dLLM-overlay">
        <div className="dLLM-overlay-content">Deleting the Doctor Profile</div>
      </div>
    )}




    


          <h2 className="dLLM-title">View & Manage Doctors</h2>
          
          
           
          {/* <button className="dLLM-reset-all-btn" onClick={resetAllCounters}>
            Reset All Counters
          </button> */}
          
          <div className="dLLM-doctors-list">

          {doctors.length === 0 && (
  <div className="dLLM-no-doctors-message">
    <p>No doctors found. Go to <Link to="/AddDoctor">Add Doctor</Link> to add a new doctor.</p>
  </div>
)}

            
            {doctors.map((doctor) => (
              <div key={doctor.id} className="dLLM-doctor-item">
                <span className="dLLM-doctor-name">
                  Doctor Name: {doctor.name}
                </span>

                <div className="dLLM-doctor-photo">
                  {doctor.photoURL ? (
                    <>
                      <img
                        src={doctor.photoURL}
                        alt="Doctor Photo"
                        className="dLLM-photo-img"
                      />
                      <input
                        type="file"
                        className="dLLM-photo-input"
                        onChange={(e) => handlePhotoUpload(e, doctor.id)} // Pass doctor.id here
                        accept="image/*"
                      />
                      <button
                        className="dLLM-photo-update-btn"
                        onClick={() => updateDoctorPhoto(doctor.id)} // Pass doctor.id here
                        disabled={updatingPhoto} // Disable the button while updatingPhoto is true
                      >
                        {updatingPhoto
                          ? "Updating..."
                          : photoUpdated
                          ? "Updated"
                          : "Update Photo"}
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="dLLM-placeholder-photo">
                        No Photo
                      </div>
                      <input
                        type="file"
                        className="dLLM-photo-input"
                        onChange={(e) => handlePhotoUpload(e, doctor.id)} // Pass doctor.id here
                        accept="image/*"
                      />
                      <button
                        className="dLLM-photo-update-btn"
                        onClick={() => updateDoctorPhoto(doctor.id)} // Pass doctor.id here
                      >
                        Update Photo
                      </button>
                    </>
                  )}
                </div>






                {/* <span className="dLLM-doctor-room">Room: {doctor.room}</span> */}
                {/* Room */}
{isEditingRoom ? (
  <div className="dLLM-doctor-edit-fields">
    <label>
      Room:
      <input
        type="text"
        value={doctor.room}
        onChange={(e) =>
          setDoctors((prevDoctors) =>
            prevDoctors.map((prevDoctor) =>
              prevDoctor.id === doctor.id
                ? { ...prevDoctor, room: e.target.value }
                : prevDoctor
            )
          )
        }
      />
    </label>
    <button onClick={() => saveRoom(doctor.id)} disabled={savingRoom}>
      {savingRoom ? "Saving..." : "Save"}
    </button>
  </div>
) : (
  <>
    <span className="dLLM-doctor-room">Room: {doctor.room}</span>
    <button className="dLLM-counter-btn" onClick={() => setIsEditingRoom(true)}>Edit Room</button>
  </>
)}






                <span className="dLLM-doctor-department">
                  Department: {doctor.departmentName}
                </span>



                {/* <span className="dLLM-doctor-department">
  Qualifications: {doctor.educationalQualifications && doctor.educationalQualifications.trim() !== "" ? doctor.educationalQualifications : "Not Specified"}
</span> */}


{/* Qualifications */}
{isEditingQualifications ? (
  <div className="dLLM-doctor-edit-fields">
    <label>
      Qualifications:
      <input
        type="text"
        value={doctor.educationalQualifications}
        onChange={(e) =>
          setDoctors((prevDoctors) =>
            prevDoctors.map((prevDoctor) =>
              prevDoctor.id === doctor.id
                ? { ...prevDoctor, educationalQualifications: e.target.value }
                : prevDoctor
            )
          )
        }
      />
    </label>
    <button onClick={() => saveQualifications(doctor.id)} disabled={savingQualifications}>
      {savingQualifications ? "Saving..." : "Save"}
    </button>
  </div>
) : (
  <>
    <span className="dLLM-doctor-department">
      Qualifications: {doctor.educationalQualifications && doctor.educationalQualifications.trim() !== "" ? doctor.educationalQualifications : "Not Specified"}
    </span>
    <button className="dLLM-counter-btn" onClick={() => setIsEditingQualifications(true)}>Edit Qualifications</button>
  </>
)}






                {/* <span className="dLLM-doctor-department">
  Areas Of Expertise: {doctor.areasOfExpertise ? doctor.areasOfExpertise.join(", ") : "Not specified"}
</span> */}


{/* Areas of Expertise */}
{isEditingExpertise ? (
  <div className="dLLM-doctor-edit-fields">
    <label>
      Areas of Expertise:
      <input
        type="text"
        value={doctor.areasOfExpertise ? doctor.areasOfExpertise.join(", ") : ""}
        onChange={(e) =>
          setDoctors((prevDoctors) =>
            prevDoctors.map((prevDoctor) =>
              prevDoctor.id === doctor.id
                ? { ...prevDoctor, areasOfExpertise: e.target.value.split(", ") }
                : prevDoctor
            )
          )
        }
      />
    </label>
    <button onClick={() => saveExpertise(doctor.id)} disabled={savingExpertise}>
      {savingExpertise ? "Saving..." : "Save"}
    </button>
  </div>
) : (
  <>
    <span className="dLLM-doctor-department">
      Areas Of Expertise: {doctor.areasOfExpertise ? doctor.areasOfExpertise.join(", ") : "Not specified"}
    </span>
    <button className="dLLM-counter-btn" onClick={() => setIsEditingExpertise(true)}>Edit Areas of Expertise 
      
      [ Please make sure to add comma after each of your expertise. 
      
      
      For eg: A,B,C]</button>
  </>
)}





{/* <span className="dLLM-doctor-department">
  About Doctor: {doctor.aboutDoctor && doctor.aboutDoctor.trim() !== "" ? doctor.aboutDoctor : "Not Specified"}
</span> */}


{/* About Doctor */}
{isEditingAbout ? (
  <div className="dLLM-doctor-edit-fields">
    <label>
      About Doctor:
      <textarea
        value={doctor.aboutDoctor}
        onChange={(e) =>
          setDoctors((prevDoctors) =>
            prevDoctors.map((prevDoctor) =>
              prevDoctor.id === doctor.id
                ? { ...prevDoctor, aboutDoctor: e.target.value }
                : prevDoctor
            )
          )
        }
      />
    </label>
    <button onClick={() => saveAbout(doctor.id)} disabled={savingAbout}>
      {savingAbout ? "Saving..." : "Save"}
    </button>
  </div>
) : (
  <>
    <span className="dLLM-doctor-department">
      About Doctor: {doctor.aboutDoctor && doctor.aboutDoctor.trim() !== "" ? doctor.aboutDoctor : "Not Specified"}
    </span>
    <button className="dLLM-counter-btn" onClick={() => setIsEditingAbout(true)}>Edit About Doctor</button>
  </>
)}
                
<span className="dLLM-doctor-languages">
  Languages Known: {doctor.languages ? doctor.languages.join(", ") : "Not specified"}
</span>

{/* <span className="dLLM-doctor-consultation-fee">
  Consultation Fee: {hospitalData.currency}{doctor.consultationFee}
</span>
<span className="dLLM-doctor-consultation-fee-comments">
  Consultation Fee Comments: {doctor.consultationFeeComments}
</span> */}

{isEditingFee ? (
  <div className="dLLM-doctor-edit-fields">
    <label>
      Consultation Fee:
      <input
        type="text"
        value={doctor.consultationFee}
        onChange={(e) =>
          setDoctors((prevDoctors) =>
            prevDoctors.map((prevDoctor) =>
              prevDoctor.id === doctor.id
                ? { ...prevDoctor, consultationFee: e.target.value }
                : prevDoctor
            )
          )
        }
      />
    </label>
    <label>
      Consultation Fee Comments:
      <input
        type="text"
        value={doctor.consultationFeeComments}
        onChange={(e) =>
          setDoctors((prevDoctors) =>
            prevDoctors.map((prevDoctor) =>
              prevDoctor.id === doctor.id
                ? { ...prevDoctor, consultationFeeComments: e.target.value }
                : prevDoctor
            )
          )
        }
      />
    </label>
    <button onClick={() => saveConsultationFee(doctor.id)} disabled={savingFee}>
      {savingFee ? "Saving..." : "Save"}
    </button>
  </div>
) : (
  <>
    <span className="dLLM-doctor-consultation-fee">
      Consultation Fee: {hospitalData.currency} {doctor.consultationFee}
    </span>
    <span className="dLLM-doctor-consultation-fee-comments">
      Consultation Fee Comments: {doctor.consultationFeeComments}
    </span>
    <button className="dLLM-counter-btn" onClick={() => setIsEditingFee(true)}>Edit Consultation Fee and Comments</button>
  </>
)}


                
{doctor.availableDays && doctor.availableDays.length > 0 && (
                <div className="APavailtog">
                  <label className="dLLM-label">
                    Appointment Availability:
                  </label>
                  <label className="dLLM-switch">
                    <input
                      type="checkbox"
                      checked={doctor.appointmentAllowed === "Y"}
                      onChange={() =>
                        toggleAppointmentAllowed(
                          doctor.id,
                          doctor.appointmentAllowed === "Y" ? "X" : "Y"
                        )
                      }
                      className="dLLM-checkbox"
                    />
                    <span className="dLLM-slider"></span>
                  </label>
                  <span className="dLLM-appointment-message">
                    {" "}
                    {doctor.appointmentAllowed === "Y"
                      ? ""
                      : ""}
                  </span>
                </div>

)}

                <div className="APavailtog">
                  <label className="dLLM-label">Availability Now:</label>
                  <label className="dLLM-switch">
                    <input
                      type="checkbox"
                      checked={doctor.availability === "Y"}
                      onChange={() =>
                        toggleAvailability(
                          doctor.id,
                          doctor.availability === "Y" ? "X" : "Y"
                        )
                      }
                      className="dLLM-checkbox"
                    />
                    <span className="dLLM-slider"></span>
                  </label>
                  <span className="dLLM-availability-message">
                    {" "}
                    {doctor.availability === "Y"
                      ? ""
                      : ""}
                  </span>
                </div>

                <span className="dLLM-doctor-counter">
                  Counter:{" "}
                  {doctor.editCounter ? (
                    <>
                      <input
                        type="number"
                        value={
                          doctor.editedCounterValue !== undefined
                            ? doctor.editedCounterValue
                            : doctor.counter
                        }
                        onChange={(e) =>
                          handleEditedCounterChange(doctor.id, e.target.value)
                        }
                        className="dLLM-counter-input"
                      />
                      <button
                        onClick={() => updateCounterValue(doctor.id)}
                        className="dLLM-counter-btn"
                      >
                        {doctor.editCounter ? "Done" : "Edit"}
                      </button>
                    </>
                  ) : (
                    <>
                      {doctor.counter}
                      <button
                        onClick={() => toggleEditCounter(doctor.id)}
                        className="dLLM-counter-btn"
                      >
                        Define Initial Value for the Counter
                      </button>
                    </>
                  )}
                </span>
                <button
                  className="dLLM-reset-btn"
                  onClick={() => resetCounter(doctor.id)}
                >
                  Reset Counter
                </button>

                {/* Available Days and Time Slots */}
                <div className="dLLM-doctor-availability">
                  <h3 className="dLLM-availability-title">
                    Available Days and Time Slots
                  </h3>
                  {doctor.availableDays && doctor.availableDays.length > 0 ? (
                    <ul className="dLLM-available-days-list">
                      {doctor.availableDays.map((day) => (
                        <li key={day} className="dLLM-available-day-item">
                          <span className="dLLM-available-day">{day}</span>
                          <ul className="dLLM-time-slots-list">
                            {doctor.timeSlots
                              .find((slot) => slot.day === day)
                              ?.timeRanges.map((range, index) => (
                                <li key={index} className="dLLM-time-slot-item">
                                  <span className="dLLM-time-slot-range">
                                    {formatTime(range.startTime)}{" "}
                                    {/* {formatTime(range.endTime)} */}
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="dLLM-no-availability">
                    No available days or time slots have been defined.
                    Please delete this doctor record and re-add it if needed. You can disable appointment availability at any time.
                    </p>
                  )}
                </div>

                {/* <button
                  className="delete-btn"
                  onClick={() => deleteDoctor(doctor.id)}
                >
                  Delete
                </button> */}


<button
  className="delete-btn"
  onClick={() => deleteDoctor(doctor.id)}
  disabled={isDeleting} // Disable the button when isDeleting is true
>
  {isDeleting ? 'Deleting...' : 'Delete'} 
</button>

              </div>
            ))}
          </div>
          {/* <DepartmentsManager /> */}
        </>
      ) : (
        <ALogin />
      )}
    </div>
  );
};

export default DoctorsManager;
