// src/components/OfflinePage/OfflinePage.jsx
import React from 'react';
import './OfflinePage.css';

const OfflinePage = () => {
  return (
    <div className="offline-container">
      <div className="offline-content">
        <h1>Offline</h1>
        <p>Please connect to the internet to continue.</p>
      </div>
    </div>
  );
};

export default OfflinePage;
