import React, { useState, useEffect, useContext } from "react";
import { db } from "../../firebaseconfig";
import { HospitalContext } from "../ScreenForAdmin/HospitalContext";
import { onSnapshot, collection, query, where } from "firebase/firestore";
import QRCode from "qrcode.react";
import "./QRcodeForAppointment.css";

const AppointmentLink = () => {
  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;
  const [appointmentLink, setAppointmentLink] = useState("");
  const [fetchingLink, setFetchingLink] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (HID) {
      setFetchingLink(true);
      const unsubscribe = onSnapshot(
        query(collection(db, "hospitals"), where("HID", "==", HID)),
        (snapshot) => {
          const hospital = snapshot.docs[0]?.data();
          if (hospital && hospital.LinkForAppointment) {
            setAppointmentLink(hospital.LinkForAppointment);
          } else {
            setErrorMessage("Appointment link not found. please go to Dashboard to create the the Appointment Link");
          }
          setFetchingLink(false);
        },
        (error) => {
          console.error("Error fetching appointment link:", error);
          setErrorMessage("Error fetching appointment link. Please try again.");
          setFetchingLink(false);
        }
      );

      return () => unsubscribe();
    }
  }, [HID]);

  return (
   
     
      <div className="QRcontent-row">
        <h3 className="QRpage-title">Scan QR to book appointments for {hospitalData.HospName}</h3>
        {fetchingLink ? (
          <p>Loading...</p>
        ) : errorMessage ? (
          <p className="error-message">{errorMessage}</p>
        ) : (
          <div className="qr-code-container">
            
            <QRCode value={appointmentLink} size={210} />
            
          </div>
        )}
      </div>
    
  );
};

export default AppointmentLink;
