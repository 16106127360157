import React, { useEffect, useState, useContext } from "react";
import { db } from "../../firebaseconfig";
import { collection, onSnapshot, where, query } from "firebase/firestore";
import ALogin from "../ScreenForAdmin/ALogin";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { HospitalContext } from "../ScreenForAdmin/HospitalContext";
import "./Billing.css";

const ScreenA = () => {
  const [consultationCount, setConsultationCount] = useState({});
  const [user, setUser] = useState(null);
  const [pfee, setPfee] = useState(0);
  const [currency, setCurrency] = useState("");
  const [grandTotal, setGrandTotal] = useState(0);
  const [grandTotalPfee, setGrandTotalPfee] = useState(0);
  const [totalKCPPatients, setTotalKCPPatients] = useState(0);
  const [grandTotalCombined, setGrandTotalCombined] = useState(0);
  const [asOnDate, setAsOnDate] = useState(""); // New state for AsOnDate

  const auth = getAuth();
  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;
  const today = new Date();

  const fetchConsultationCount = () => {
    if (!HID) return;

    const unsubscribe = onSnapshot(
      query(
        collection(db, "patients"),
        where("HID", "==", HID),
        where("PKC", "==", "B")
      ),
      (snapshot) => {
        const consultationCountByMonth = {};
        let latestPfee = 0;
        let latestCurrency = "";
        let totalGrandPayableAmount = 0;
        let totalGrandPayableAmountPfee = 0;
        let totalKCPPatientsCount = 0;

         // Variable to keep track of the latest PKCtoBTimeStamp
         let latestPKCtoBTimeStamp = null;

        snapshot.docs.forEach((doc) => {
          const patient = doc.data();
          const consultedAt = new Date(patient.consultedAt);
          const monthYear = consultedAt.toLocaleString("default", {
            month: "long",
            year: "numeric",
          });
          const doctorAssigned = patient.doctorAssigned;

          const patientHospitalData = patient || {};
          const patientPFEE = patientHospitalData.PFEE || 0;

          latestPfee = patientPFEE;
          latestCurrency = patientHospitalData.currency || "";

           // Update the latest PKCtoBTimeStamp if it is newer
           if (!latestPKCtoBTimeStamp || patient.PKCtoBTimeStamp > latestPKCtoBTimeStamp) {
            latestPKCtoBTimeStamp = patient.PKCtoBTimeStamp;
          }

          if (!consultationCountByMonth[monthYear]) {
            consultationCountByMonth[monthYear] = {};
          }

          if (!consultationCountByMonth[monthYear][doctorAssigned]) {
            consultationCountByMonth[monthYear][doctorAssigned] = {
              count: 0,
              payableAmount: 0,
              payableAmountPfee: 0,
              consultationFee: Number(patient.consultationFee) || 0,
              KCC: patient.KCC || 0,
              kcpPatients: 0,
            };
          }

          const consultationFee = Number(patient.consultationFee) || 0;
          const KCC = patient.KCC || 0;

          let payableAmountPerConsultation = 0;
          let payableAmountPerConsultationPfee = 0;

          if (
            (patient.status === "B" || patient.status === "C" || patient.status === "D" || patient.status === "E" || patient.status === "F") &&
            patient.PKC === "B" &&
            patient.type === "KCP" &&
            patient.HID === HID
        ) {
            payableAmountPerConsultation = KCC * consultationFee;
            consultationCountByMonth[monthYear][doctorAssigned].kcpPatients++;
            totalKCPPatientsCount++;
          }

          if (
            (patient.status === "B" || patient.status === "C" || patient.status === "D" || patient.status === "E" || patient.status === "F") &&
            patient.PKC === "B" &&
            patient.HID === HID
          ) {
            payableAmountPerConsultationPfee = latestPfee;
            totalGrandPayableAmountPfee += payableAmountPerConsultationPfee;
          }

          consultationCountByMonth[monthYear][doctorAssigned].count++;
          consultationCountByMonth[monthYear][doctorAssigned].payableAmount +=
            payableAmountPerConsultation;
          consultationCountByMonth[monthYear][
            doctorAssigned
          ].payableAmountPfee += payableAmountPerConsultationPfee;

          totalGrandPayableAmount += payableAmountPerConsultation;
        });

        setConsultationCount(consultationCountByMonth);
        setPfee(latestPfee);
        setCurrency(latestCurrency);
        setGrandTotal(totalGrandPayableAmount);
        setGrandTotalPfee(totalGrandPayableAmountPfee);
        setTotalKCPPatients(totalKCPPatientsCount);
        setGrandTotalCombined(
          totalGrandPayableAmount + totalGrandPayableAmountPfee
        );

       // Set the latest PKCtoBTimeStamp as AsOnDate
       if (latestPKCtoBTimeStamp) {
        const asOnDateObj = new Date(latestPKCtoBTimeStamp.seconds * 1000);
        setAsOnDate(asOnDateObj.toLocaleString("default", {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit"
        }));
      }
    }
  );

  return unsubscribe;
};

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    const unsubscribeConsultationCount = fetchConsultationCount();

    return () => {
      unsubscribeConsultationCount();
      unsubscribeAuth();
    };
  }, [auth, HID, today]);

  // Format message for WhatsApp
  const formatWhatsAppMessage = (grandTotalCombined) => {
    let message = `Hospital Name: ${hospitalData.HospName}\n`;
    message += `HID: ${hospitalData.HID}\n`;
    message += `Country: ${hospitalData.country}\n`;
    message += `As On Date: ${asOnDate}\n`; // Add AsOnDate to the message

    // Iterate over consultationCount keys (monthYear)
    Object.keys(consultationCount).forEach((monthYear) => {
      // message += `${monthYear}\n`;
      // Iterate over doctors in each month
      // Object.entries(consultationCount[monthYear]).forEach(
      //   ([doctorName, { count, payableAmount, consultationFee, KCC, payableAmountPfee, kcpPatients }]) => {
      //     message += `Doctor: ${doctorName}\n`;
      //     message += `Consultation Count: ${count}\n`;
      //     message += `Consultation Fee: ${currency} ${consultationFee.toFixed(2)}\n`;
      //     message += `KCC: ${(KCC * 100).toFixed(0)}%\n`;
      //     message += `Payable Amount: ${currency} ${payableAmount.toFixed(3)}\n`;
      //     message += `PFEE Amount: ${currency} ${payableAmountPfee.toFixed(3)}\n`;
      //     message += `Total KCP Patients: ${kcpPatients}\n\n`;
      //   }
      // );
    });

    message += `Grand Total Payable Amount (combined): ${currency} ${grandTotalCombined.toFixed(
      3
    )}\n`;
    // message += `Month: ${today.toLocaleString("default", { month: "long" })}\n`;
    // message += `Year: ${today.getFullYear()}\n`;

    return message;
  };

  const openWhatsApp = (grandTotalCombined) => {
    const message = formatWhatsAppMessage(grandTotalCombined);
    const phoneNumber = "+96871198835"; // Recipient phone number

    // Construct the WhatsApp URL with recipient number and message
    const url = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;

    // Open the URL in a new tab/window
    window.open(url, "_blank");
  };

  const openWhatsAppMobile = (grandTotalCombined) => {
    const message = formatWhatsAppMessage(grandTotalCombined);
    const phoneNumber = "+96871198835"; // Recipient phone number

    // Construct the WhatsApp URL with recipient number and message
    const url = `whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;

    // Open the URL
    window.open(url);
  };

  return (
    <div className="BLL-billing">
      {user ? (
        <>
          <div className="BLL-content">
            {/* <h2 className="BLL-title">{hospitalData.HospName}</h2> */}
            {grandTotalCombined === 0 ? (
              <p className="BLL-no-payments">No Bills Allocated to Pay</p>
            ) : (
              Object.keys(consultationCount).map((monthYear) => (
                <div key={monthYear} className="BLL-month">
                  <h3 className="BLL-month-title">Bills allocated to pay as on {asOnDate}</h3> {/* Updated title */}
                  <div className="BLL-table-wrapper">
                    <table className="BLL-table">
                      <thead>
                        <tr>
                          <th>Doctor Name</th>
                          <th>Total Consultations</th>
                         
                          <th>
  Krysta.Care Patients 
  <br/>
  <a href="https://krysta.care" class="coming-soon" target="_blank">coming soon</a>
</th>


                          <th>Consultation Fee </th>
                          <th> Krysta Care Commission(%)</th>
                          <th>Krysta Care Commission</th>
                          <th>Platform Fee</th>
                          <th>Total Payable</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(consultationCount[monthYear]).map(
                          ([
                            doctorName,
                            {
                              count,
                              payableAmount,
                              consultationFee,
                              KCC,
                              payableAmountPfee,
                              kcpPatients,
                            },
                          ]) => (
                            <tr key={doctorName}>
                              <td>{doctorName}</td>
                              <td>{count}</td>
                              <td>{kcpPatients}</td>
                              <td>
                                {currency} {consultationFee.toFixed(2)}
                              </td>
                              <td>{(KCC * 100).toFixed(0)}</td>
                              <td>
                                {currency} {payableAmount.toFixed(3)}
                              </td>
                              <td>
                                {currency} {payableAmountPfee.toFixed(3)}
                              </td>
                              <td>
                                {currency}{" "}
                                {(payableAmount + payableAmountPfee).toFixed(3)}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))
            )}
            {grandTotalCombined !== 0 && (
              <div className="BLL-grand-total">
                <h3 className="BLL-total-title">Krysta Care Commission</h3>
                <p className="BLL-total-amount">
                  {currency} {grandTotal.toFixed(3)}
                </p>
                <h3 className="BLL-total-title">Platform Fee</h3>
                <p className="BLL-total-amount">
                  {currency} {grandTotalPfee.toFixed(3)}
                </p>
                <h3 className="BLL-total-title">Grand Total</h3>
                <p className="BLL-total-amount">
                  {currency} {grandTotalCombined.toFixed(3)}
                </p>
                <button
                  className="BLL-pay-button"
                  onClick={() => {
                    const userAgent =
                      navigator.userAgent || navigator.vendor || window.opera;
                    if (/android/i.test(userAgent)) {
                      openWhatsAppMobile(grandTotalCombined);
                    } else {
                      openWhatsApp(grandTotalCombined);
                    }
                  }}
                >
                  PAY
                </button>
              </div>
            )}
          </div>
        </>
      ) : (
        <ALogin />
      )}
    </div>
  );
};

export default ScreenA;
