import React, { useEffect, useState, useContext } from "react";
import "./Main.css";
import Statitics from "../Statics/Stat";
import DoctorDetailsForDashboard from "../DoctorDetails/DD";
import { HospitalContext } from "../../ScreenForAdmin/HospitalContext";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { hospitalData } = useContext(HospitalContext);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the device is mobile
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    const isMobileDevice = mobileRegex.test(userAgent);
    setIsMobile(isMobileDevice);

    if (isMobileDevice) {
      // Route to the MakeAppointment page if the device is mobile
      navigate("/MobileUser");
    }
  }, [navigate]);

  if (!hospitalData) {
    // Redirect to the login page if the user is not logged in
    navigate("/login");
    return null; // Return early to prevent rendering the component
  }

  return (
    <div className="Main-container">
      {!isMobile && (
        <>
          <Statitics />
          <DoctorDetailsForDashboard />
        </>
      )}
    </div>
  );
};

export default Home;
