import React, { createContext, useState, useEffect } from 'react';

export const HospitalContext = createContext();

export const HospitalProvider = ({ children }) => {
  const [hospitalData, setHospitalData] = useState({});

  useEffect(() => {
    // Retrieve hospital data from local storage on component mount
    const storedHospitalData = localStorage.getItem('hospitalData');
    if (storedHospitalData) {
      setHospitalData(JSON.parse(storedHospitalData));
    }
  }, []);

  const updateHospitalData = (data) => {
    setHospitalData(data);
    // Store hospital data in local storage
    localStorage.setItem('hospitalData', JSON.stringify(data));
  };

  return (
    <HospitalContext.Provider value={{ hospitalData, updateHospitalData }}>
      {children}
    </HospitalContext.Provider>
  );
};
