import React, { useEffect, useState } from "react";
import { db } from "../../firebaseconfig";
import {
  collection,
  addDoc,
  doc,
  query,
  where,
  onSnapshot,
  getDocs,
  setDoc,
  getDoc,
  orderBy,
  writeBatch,
  updateDoc,
  limit,
} from "firebase/firestore";
import emailjs from "emailjs-com";
import soundA from "./Sound2.mp3";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import "./ScreenPWebsite.css";

const ScreenP = () => {
  const [hids, setHids] = useState([]);

  const [HID, setHid] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [existingMobileNumber, setExistingMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [patientId, setPatientId] = useState(null);
  const [patientDetails, setPatientDetails] = useState(null);
  const [nowServingToken, setNowServingToken] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [proceedClicked, setProceedClicked] = useState(false);

  const [availableDepartments, setAvailableDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);

  const [currentDate, setCurrentDate] = useState(null); // Initialize currentDate state to null

  const [selectedDoctorAvailableDays, setSelectedDoctorAvailableDays] =
    useState([]);

  const [isCheckSuccessful, setIsCheckSuccessful] = useState(false); // Define isCheckSuccessful state
  const [availableTimeslots, setAvailableTimeslots] = useState([]); // Define availableTimeslots state
  const [isChecking, setIsChecking] = useState(false); // New state to track if checking is in progress

  const [isChecked, setIsChecked] = useState(false);

  const [phoneCode, setPhoneCode] = useState("");

  const [email, setEmail] = useState("");

  const [consultationFee, setConsultationFee] = useState("");
  const [currency, setCurrency] = useState("");
  const [consultationFeeComments, setConsultationFeeComments] = useState("");

  const [mobileNumberError, setMobileNumberError] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hidValue = urlParams.get("HID");
    const phoneCodeValue = urlParams.get("phoneCode");
    const emailValue = urlParams.get("email"); // Add this line to get the email parameter
    setHid(hidValue);
    setPhoneCode(decodeURIComponent(phoneCodeValue) || ""); // Decode and set the phoneCode
    setEmail(emailValue); // Set the email state
  }, []);

  useEffect(() => {
    setSelectedTimeslot(null); // Reset selected timeslot when date changes and show Proceed button
  }, [currentDate]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hidValue = urlParams.get("HID");
    setHid(hidValue);
  }, []);

  const fetchHIDs = async () => {
    try {
      const doctorsRef = collection(db, "doctors");
      const querySnapshot = await getDocs(doctorsRef);

      const ids = new Set();
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.hospitalData && data.hospitalData.HID) {
          ids.add(data.hospitalData.HID);
        }
      });

      setHids(Array.from(ids));
    } catch (error) {
      console.error("Error fetching HID values:", error);
    }
  };

  useEffect(() => {
    fetchHIDs();
  }, []);

  const fetchDepartments = async () => {
    const departmentsQuery = query(collection(db, "doctors"));
    const querySnapshot = await getDocs(departmentsQuery);
    const departments = new Set();
    querySnapshot.forEach((doc) => {
      const doctorData = doc.data();
      if (doctorData.departmentName) {
        departments.add(doctorData.departmentName);
      }
    });
    return Array.from(departments);
  };

  const [hasCheckedOnce, setHasCheckedOnce] = useState(false); // New state to track if "Check" button has been clicked at least once

  const handleCheckAvailability = async (e) => {
    e.preventDefault();
    try {
      setIsProcessing(true);
      setIsChecking(true);
      setIsCheckSuccessful(false);
      setAvailableTimeslots([]);
      setSelectedTimeslot(null);
      setIsChecked(true);
      setHasCheckedOnce(true);

      const selectedDate = new Date(currentDate.toISOString());
      selectedDate.setHours(0, 0, 0, 0);

      // Create or update the appointment record
      const appointmentRecord = await createNewAppointmentRecord(selectedDate);

      // Filter and set available timeslots
      const availableTimeslots = appointmentRecord.availableTimeslots
        .filter((slot) => slot.status === "Y")
        .map((slot) => slot.startTime);

      setAvailableTimeslots(availableTimeslots);
      setIsCheckSuccessful(true);
    } catch (error) {
      console.error("Error checking availability: ", error);
    } finally {
      setIsChecking(false);
      setIsProcessing(false);
    }
  };

  // Add this useEffect hook to reset available timeslots when the date changes
  useEffect(() => {
    setAvailableTimeslots([]);
  }, [currentDate]);

  // Add this useEffect hook to create a new appointment record when date changes
  useEffect(() => {
    const handleDateChange = async () => {
      if (selectedDoctor && currentDate) {
        const selectedDate = new Date(currentDate.toISOString());
        selectedDate.setHours(0, 0, 0, 0);

        const appointmentsQuery = query(
          collection(db, "Appointments"),
          where("date", "==", selectedDate.toISOString()),
          where("doctor", "==", selectedDoctor)
        );
        const appointmentsSnapshot = await getDocs(appointmentsQuery);
        const existingAppointment = appointmentsSnapshot.docs[0];

        if (!existingAppointment) {
          await createNewAppointmentRecord(selectedDate);
        }
      }
    };

    handleDateChange();
  }, [selectedDoctor, currentDate]); // Run this effect whenever selectedDoctor or currentDate changes

  // const createNewAppointmentRecord = async (selectedDate) => {
  //   const newAppointmentRef = doc(collection(db, "Appointments"));
  //   const selectedDoctorData = doctorsList.find(
  //     (doctor) => doctor.name === selectedDoctor
  //   );
  //   const fetchedAvailableTimeslots =
  //     selectedDoctorData.timeSlots.find(
  //       (slot) =>
  //         slot.day.toLowerCase() ===
  //         selectedDate
  //           .toLocaleDateString("en-US", { weekday: "long" })
  //           .toLowerCase()
  //     )?.timeRanges || [];

  //   // Filter and set available timeslots with status 'Y'
  //   const availableTimeslots = fetchedAvailableTimeslots
  //     .filter((slot) => slot.status === "Y")
  //     .map((slot) => slot.startTime);

  //   // Create the new appointment document
  //   const batch = writeBatch(db);
  //   batch.set(newAppointmentRef, {
  //     HID: HID, // Add the HID value as a separate field
  //     doctor: selectedDoctor,
  //     date: selectedDate.toISOString(),
  //     availableTimeslots: fetchedAvailableTimeslots.map((slot) => ({
  //       startTime: slot.startTime,
  //       status: "Y",
  //     })),
  //   });
  //   await batch.commit();

  //   // Return the available timeslots from the newly created record
  //   return availableTimeslots;
  // };

  const createNewAppointmentRecord = async (selectedDate) => {
    const selectedDoctorData = doctorsList.find(
      (doctor) => doctor.name === selectedDoctor
    );

    // Check for existing appointment with doctor, HID, and department
    const existingAppointmentQuery = query(
      collection(db, "Appointments"),
      where("date", "==", selectedDate.toISOString()),
      where("doctor", "==", selectedDoctor),
      where("HID", "==", HID),
      where("department", "==", selectedDepartment)
    );
    const existingAppointmentSnapshot = await getDocs(existingAppointmentQuery);

    let appointmentRef;
    let existingTimeslots = [];

    if (!existingAppointmentSnapshot.empty) {
      appointmentRef = doc(
        db,
        "Appointments",
        existingAppointmentSnapshot.docs[0].id
      );
      existingTimeslots =
        existingAppointmentSnapshot.docs[0].data().availableTimeslots;
    } else {
      appointmentRef = doc(collection(db, "Appointments"));
    }

    const fetchedAvailableTimeslots =
      selectedDoctorData.timeSlots.find(
        (slot) =>
          slot.day.toLowerCase() ===
          selectedDate
            .toLocaleDateString("en-US", { weekday: "long" })
            .toLowerCase()
      )?.timeRanges || [];

    const updatedTimeslots = fetchedAvailableTimeslots.map((slot) => {
      const existingSlot = existingTimeslots.find(
        (es) => es.startTime === slot.startTime
      );
      return {
        startTime: slot.startTime,
        status: existingSlot ? existingSlot.status : "Y",
      };
    });

    const appointmentData = {
      HID: HID,
      doctor: selectedDoctor,
      department: selectedDepartment,
      date: selectedDate.toISOString(),
      availableTimeslots: updatedTimeslots,
    };

    await setDoc(appointmentRef, appointmentData, { merge: true });

    return appointmentData;
  };

  const checkDoctorAvailability = (doctorName, selectedDate) => {
    const doctor = doctorsList.find((doctor) => doctor.name === doctorName);
    if (doctor) {
      const dayOfWeek = selectedDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      return doctor.availableDays.includes(dayOfWeek);
    }
    return false;
  };

  const fetchDepartmentsAndDoctors = () => {
    const departmentsQuery = query(
      collection(db, "doctors"),
      where("hospitalData.HID", "==", HID)
    );

    const unsubscribeDepartments = onSnapshot(departmentsQuery, (snapshot) => {
      console.log("Snapshot size:", snapshot.size);
      const departments = new Set();
      snapshot.forEach((doc) => {
        const doctorData = doc.data();
        if (doctorData.departmentName) {
          departments.add(doctorData.departmentName);
        }
      });
      setAvailableDepartments(Array.from(departments));
    });

    return () => unsubscribeDepartments();
  };

  useEffect(() => {
    const unsubscribe = fetchDepartmentsAndDoctors();

    return () => unsubscribe();
  }, [HID]);

  const fetchDoctorsByDepartment = async (department) => {
    const doctorsQuery = query(
      collection(db, "doctors"),
      where("departmentName", "==", department),
      where("availability", "==", "Y"),
      where("appointmentAllowed", "!=", "X"), // Add this condition
      where("hospitalData.HID", "==", HID)
    );

    const unsubscribeDoctors = onSnapshot(doctorsQuery, (snapshot) => {
      const doctors = snapshot.docs.map((doc) => doc.data());
      setDoctorsList(doctors);
    });

    return () => unsubscribeDoctors();
  };

  const handleDepartmentChange = (event) => {
    const selectedDepartment = event.target.value;
    setSelectedDepartment(selectedDepartment);
    setSelectedDoctor(""); // Reset the selected doctor
    setSelectedTimeslot(null); // Reset the selected timeslot
    setAvailableTimeslots([]); // Reset available timeslots
    setIsChecked(false); // Reset isChecked
    setSelectedDoctorAvailableDays([]); // Reset available days
    setCurrentDate(null); // Reset selected date

    const unsubscribe = fetchDoctorsByDepartment(selectedDepartment);

    // Check if there are any available doctors for the selected department
    if (unsubscribe.length === 0) {
      setDoctorsList([]);
      alert("No doctors available for the selected department.");
    }

    // Cleanup function to unsubscribe from the previous snapshot listener - for realtime update
    return () => unsubscribe();
  };

  const handleDoctorChange = (event) => {
    const selectedDoctor = event.target.value;
    setSelectedDoctor(selectedDoctor);

    const doctor = doctorsList.find((doctor) => doctor.name === selectedDoctor);

    if (doctor) {
      setSelectedDoctor(selectedDoctor);
      setSelectedDoctorAvailableDays(doctor.availableDays);
      setSelectedTimeslot(null);
      setAvailableTimeslots([]);
      setIsChecked(false);
      setCurrentDate(null);
      setConsultationFee(doctor.consultationFee || "");
      setConsultationFeeComments(doctor.consultationFeeComments || "");
      setCurrency(doctor.hospitalData?.currency || ""); // Set the currency from hospitalData
    }
  };

  // Add this to reset isChecked when date changes
  useEffect(() => {
    setIsChecked(false);
  }, [currentDate]);

  useEffect(() => {
    fetchDepartmentsAndDoctors();
  }, []);

  useEffect(() => {
    if (patientId) {
      const patientRef = doc(db, "patients", patientId);

      const unsubscribe = onSnapshot(patientRef, (doc) => {
        if (doc.exists()) {
          const patientData = doc.data();
          setPatientDetails(patientData);

          if (patientData.status !== status) {
            setStatus(patientData.status);
            vibrateAndPlaySound();
          }

          if (
            patientData.doctorAssigned &&
            ["C", "D", "E", "F"].includes(patientData.status)
          ) {
            const servingTokenQuery = query(
              collection(db, "patients"),
              where("status", "==", "D"),
              where("doctorAssigned", "==", patientData.doctorAssigned)
            );
            const unsubscribeServingToken = onSnapshot(
              servingTokenQuery,
              (snapshot) => {
                const serving = snapshot.docs
                  .map((doc) => doc.data())
                  .find((p) => p.doctorAssigned === patientData.doctorAssigned);
                if (serving) {
                  setNowServingToken(serving.tokenNumber);
                } else {
                  setNowServingToken("");
                }
              }
            );

            return () => unsubscribeServingToken();
          }
        }
      });

      return () => unsubscribe();
    }
  }, [patientId, status]);

  const vibrateAndPlaySound = () => {
    if ("vibrate" in navigator) {
      navigator.vibrate(500);
    }
    const audio = new Audio(soundA);
    audio
      .play()
      .catch((error) => console.error("Audio playback failed:", error));
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(":");
    const period = parseInt(hours, 10) >= 12 ? "PM" : "AM";
    const formattedHours = (((parseInt(hours, 10) + 11) % 12) + 1).toString();
    return `${formattedHours}:${minutes} ${period}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setProceedClicked(true);
      setIsProcessing(true);

      console.log("Selected Timeslot:", selectedTimeslot);

      const selectedDoctorData = doctorsList.find(
        (doctor) => doctor.name === selectedDoctor
      );

      const selectedDate = new Date(currentDate.toISOString());
      selectedDate.setHours(0, 0, 0, 0);
      const appointmentsQuery = query(
        collection(db, "Appointments"),
        where("date", "==", selectedDate.toISOString()),
        where("doctor", "==", selectedDoctor)
      );
      const appointmentsSnapshot = await getDocs(appointmentsQuery);
      const existingAppointment = appointmentsSnapshot.docs[0];
      const appointmentId = existingAppointment ? existingAppointment.id : null;

      const apid = `${
        appointmentId || "new"
      }_${selectedDate.toISOString()}_${selectedDoctor}_${selectedTimeslot}`;

        // Extract the required fields from the selected doctor's data
    const { KCC, PFEE, HospName } = selectedDoctorData.hospitalData || {};

      const docRef = await addDoc(collection(db, "patients"), {
        mobileNumber: `${phoneCode}${mobileNumber}`,
        name,
        status: "A",
        requestTime: new Date(),
        tag: "PWU",
        PKC:"A",
        selectedDepartment,
        doctorAssigned: selectedDoctor,
        doctorRoom: selectedDoctorData?.room || "",
        appointmentDate: currentDate.toISOString(),
        selectedTimeslot: selectedTimeslot || "",
        HID,
        APID: apid,
        consultationFee,
        consultationFeeComments,
        currency, // Add the currency to the patient record
        // Add the new fields here
      KCC: KCC || 0,
      PFEE: PFEE || 0,
      hospitalName: HospName || "",
      });

      setPatientId(docRef.id);
      setStatus("A");
      vibrateAndPlaySound();

      // Update the status of the selected timeslot to 'X'
      if (existingAppointment) {
        const existingAppointmentRef = doc(
          db,
          "Appointments",
          existingAppointment.id
        );
        const existingAppointmentData = existingAppointment.data();
        const updatedAvailableTimeslots =
          existingAppointmentData.availableTimeslots.map((slot) => ({
            ...slot,
            status: slot.startTime === selectedTimeslot ? "X" : slot.status,
          }));
        await updateDoc(existingAppointmentRef, {
          availableTimeslots: updatedAvailableTimeslots,
        });
      }

      // Send email using EmailJS
      const templateParams = {
        to_name: "Hospital Staff",
        to_email: email,
        patient_name: name,
        patient_mobile: `${phoneCode}${mobileNumber}`,
        selected_department: selectedDepartment,
        doctor_assigned: selectedDoctor,
        appointment_date: currentDate.toISOString().split("T")[0],
        selected_timeslot: selectedTimeslot,
      };

      emailjs
        .send(
          "service_0afemra", // Replace with your EmailJS service ID
          "template_m275mja", // Replace with your EmailJS template ID
          templateParams,
          "VJkDKxpFqFnUc1vFq" // Replace with your EmailJS user ID
        )
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
        })
        .catch((err) => {
          console.error("FAILED...", err);
        });
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const fetchLatestToken = async () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const patientsQuery = query(
      collection(db, "patients"),
      where("mobileNumber", "==", existingMobileNumber),
      where("requestTime", ">=", today),
      orderBy("requestTime", "desc"),
      limit(1)
    );

    const querySnapshot = await getDocs(patientsQuery);
    const latestPatient = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))[0];

    if (latestPatient) {
      setPatientDetails(latestPatient);
      setPatientId(latestPatient.id);
      setStatus(latestPatient.status);
      setName(latestPatient.name);
      setMobileNumber(latestPatient.mobileNumber);
    } else {
      alert("No token found for the given mobile number today.");
    }
  };

  const takeScreenshot = () => {
    const patientDetailsElement = document.querySelector(".patient-details");
    domtoimage
      .toBlob(patientDetailsElement)
      .then(function (blob) {
        saveAs(blob, "token-details.png");
      })
      .catch(function (error) {
        console.error("Error taking screenshot:", error);
      });
  };

  const displayMessage = () => {
    if (!patientDetails) return null;

    let messageClass = "message " + "status-" + patientDetails.status;
    switch (patientDetails.status) {
      case "A":
        return (
          <div className={messageClass}>
            <strong>Appointment Confirmed</strong> <br />
            
            <br />
            <br />
            <p className="message">
              One of our representatives will contact your during the working hours to reconfirm your appointment.
            </p>
            <div>
              {" "}
              <a href="/AppointmentStatus" className="APcheckbuttonlink">
                Check Appointment Details
              </a>
            </div>
          </div>
        );
      case "B":
        return (
          <div className={messageClass}>
            Appointment reconfirmed
            <br />
            <br />
            <div>
              {" "}
              <a href="/AppointmentStatus" className="APcheckbuttonlink">
                Check Appointment Details
              </a>
            </div>
          </div>
        );

      case "C":
        return (
          <div className={messageClass}>
            <div>
              {" "}
              <a href="/AppointmentStatus" className="APcheckbuttonlink">
                Check Appointment Status
              </a>
            </div>
          </div>
        );
      case "D":
        return (
          <div className={messageClass}>
            <div>
              {" "}
              <a href="/AppointmentStatus" className="APcheckbuttonlink">
                Check Appointment Status
              </a>
            </div>
          </div>
        );

      case "E":
        return (
          <div className={messageClass}>
            <div>
              {" "}
              <a href="/AppointmentStatus" className="APcheckbuttonlink">
                Check Appointment Status
              </a>
            </div>
          </div>
        );
      case "F":
        return (
          <div className={messageClass}>
            <div>
              {" "}
              <a href="/AppointmentStatus" className="APcheckbuttonlink">
                Check Appointment Status
              </a>
            </div>
          </div>
        );
      default:
        return (
          <div className={messageClass}>
            <div>
              {" "}
              <a href="/AppointmentStatus" className="APcheckbuttonlink">
                Check Appointment Status
              </a>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="AP-screenP-container">
      <h2 className="APTLINK-heading">Appointment Request Form</h2>

      {patientId ? (
        <div class="SP-P1-info">
          {/* <h2 className='AP-titleP'>Please find the details below</h2> */}

          <p>
            <strong class="SP-P1-label">Your Name:</strong> {name}
          </p>
          <p>
            <strong class="SP-P1-label">Your Mobile Number:</strong>{" "}
            {mobileNumber}
          </p>
          {displayMessage()}
        </div>
      ) : (
        <div>
          <form className="AP-form-container" onSubmit={handleSubmit}>
            <div className="conditional-fields">
              {/* <input
                className="name-input-field"
                type="text"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                placeholder={`Your mobile number without ${phoneCode}`}
                required // Add 'required' attribute to make it mandatory
                // pattern="\d{8}" // Add 'pattern' attribute to enforce 8 digits only
                // title="Please enter exactly 8 digits for the mobile number" // Add 'title' attribute with the custom message
              /> */}

              <input
                className="name-input-field"
                type="text"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                onBlur={(e) => {
                  const pattern =
                    phoneCode === "+91"
                      ? /^\d{10}$/
                      : phoneCode === "+968"
                      ? /^\d{8}$/
                      : null;
                  if (pattern && !pattern.test(e.target.value)) {
                    setMobileNumberError(
                      phoneCode === "+91"
                        ? "Please enter exactly 10 digits for the mobile number"
                        : "Please enter exactly 8 digits for the mobile number"
                    );
                  } else {
                    setMobileNumberError("");
                  }
                }}
                placeholder={`Patient mobile number without ${phoneCode}`}
                required
                pattern={
                  phoneCode === "+91"
                    ? "\\d{10}"
                    : phoneCode === "+968"
                    ? "\\d{8}"
                    : null
                }
                title={
                  phoneCode === "+91"
                    ? "Please enter exactly 10 digits for the mobile number"
                    : phoneCode === "+968"
                    ? "Please enter exactly 8 digits for the mobile number"
                    : "Please enter a valid mobile number"
                }
              />
              {mobileNumberError && (
                <p className="error-message">{mobileNumberError}</p>
              )}

              <input
                className="name-input-field"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name of the patient"
                required // Add 'required' attribute to make it mandatory
              />

              {/* {!mobileNumber || !name && (
  <p>Please enter your name and 8-digit mobile number.</p>
)} */}

              <select
                className="dept-input-field"
                value={selectedDepartment}
                onChange={(e) => handleDepartmentChange(e)}
                required // Add 'required' attribute to make it mandatory
              >
                <option value="" disabled>
                  Select Department
                </option>
                {availableDepartments.map((department, index) => (
                  <option key={index} value={department}>
                    {department}
                  </option>
                ))}
              </select>

              <select
                className="dept-input-field"
                value={selectedDoctor}
                onChange={(e) => handleDoctorChange(e)}
              >
                <option value="" disabled>
                  Select Doctor
                </option>
                {doctorsList.length === 0 ? (
                  <option disabled>
                    No doctors available for appointments. Please contact the
                    Reception
                  </option>
                ) : (
                  doctorsList.map((doctor, index) => (
                    <option key={index} value={doctor.name}>
                      {doctor.name}
                    </option>
                  ))
                )}
              </select>

              {selectedDoctor && (
                <div className="selected-infoAP">
                  <p>Selected Doctor: {selectedDoctor}</p>
                  <p>
                    Consultation Fee: {currency} {consultationFee}
                  </p>
                  {consultationFeeComments && (
                    <p>Comments: {consultationFeeComments}</p>
                  )}
                  <h3 className="AvailDays77">
                    Please see the available days below
                  </h3>
                  <ul className="timeslot-option2">
                    {selectedDoctorAvailableDays.map((day, index) => (
                      <li className="timeslot-label2" key={index}>
                        {day}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {selectedDoctor && (
                <div className="selectAPdtae">
                  <label>
                    Select the appointment date based on the available days:
                  </label>

                  <input
                    type="date"
                    className="APPselex"
                    value={
                      currentDate ? currentDate.toISOString().split("T")[0] : ""
                    }
                    min={new Date().toISOString().split("T")[0]}
                    onChange={(e) =>
                      setCurrentDate(
                        e.target.value ? new Date(e.target.value) : null
                      )
                    }
                  />
                </div>
              )}

{selectedDoctor && currentDate && (
  <div>
    {checkDoctorAvailability(selectedDoctor, currentDate) ? (
      <div className="selectAdtae">
        <p>
          {selectedDoctor} is available on {currentDate.toDateString()}.
        </p>
        <button
          className="checkkk-button"
          onClick={(e) => handleCheckAvailability(e)}
          disabled={isChecking || isProcessing}
        >
          <span>
            {isChecking ? "Checking..." : "Check Availability"}
            <svg
              viewBox="0 0 19.9 19.7"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-labelledby="title desc"
              className="svg-icon search-icon"
            >
              <title>Search Icon</title>
              <desc id="desc">A magnifying glass icon.</desc>
              <g stroke="white" fill="none" className="search-path">
                <path d="M18.5 18.3l-5.4-5.4" strokeLinecap="square"></path>
                <circle r="7" cy="8" cx="8"></circle>
              </g>
            </svg>
          </span>
        </button>

        {isChecking ? (
          // <p>Checking available timeslots...</p>
          <p></p>
        ) : isCheckSuccessful && availableTimeslots.length > 0 ? (
          <div>
            <p>Please select from the available timepoint(s) for {currentDate.toDateString()}</p>
            <div className="timeslots-container">
              {["Morning", "Afternoon", "Evening"].map((period) => {
                const filteredSlots = availableTimeslots.filter((slot) => {
                  const hour = parseInt(slot.split(":")[0]);
                  if (period === "Morning") return hour >= 6 && hour < 12;
                  if (period === "Afternoon") return hour >= 12 && hour < 17;
                  if (period === "Evening") return hour >= 17 || hour < 6;
                });

                if (filteredSlots.length === 0) return null;

                return (
                  <div key={period} className="timeslot-period">
                    <h3>{period}</h3>
                    <div className="timeslot-options">
                      {filteredSlots.map((timeslot, index) => (
                        <label key={index} className="timeslot-option">
                          <input
                            type="radio"
                            name="timeslot"
                            value={timeslot}
                            checked={selectedTimeslot === timeslot}
                            onChange={(e) => setSelectedTimeslot(e.target.value)}
                          />
                          <span className="timeslot-label">
                            {formatTime(timeslot)}
                          </span>
                        </label>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>

            {selectedTimeslot && (
              <div className="appointment-summary">
                <h4>Appointment Request Summary</h4>
                <div className="summary-content">
                  <p>
                    {name} ({phoneCode}{mobileNumber}) is requesting an appointment with {selectedDoctor} of the {selectedDepartment} department.
                    The appointment is scheduled for {currentDate.toDateString()} at {formatTime(selectedTimeslot)}. If correct, please click the Proceed button.
                  </p>
                </div>
              </div>
            )}
          </div>
        ) : isChecked && isCheckSuccessful && availableTimeslots.length === 0 ? (
          <p>
            No available time slots for {selectedDoctor} on {currentDate.toDateString()}.
            Please try again later. If any patients cancel their appointments, time slots will become available.
          </p>
        ) : null}
      </div>
    ) : (
      <p>
        {selectedDoctor} is not available on {currentDate.toDateString()}.
      </p>
    )}
  </div>
)}

              {selectedTimeslot &&
                mobileNumber &&
                name &&
                selectedDoctor &&
                selectedDepartment &&
                currentDate && (
                  // <button
                  //   className="AP-tokn-proceed-button"
                  //   type="submit"
                  //   disabled={isProcessing}
                  // >
                  //   {isProcessing ? "Processing" : "Proceed"}
                  // </button>

                  <button
                    className="AP-tokn-proceed-button"
                    type="submit"
                    disabled={isProcessing}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                      ></path>
                    </svg>
                    <div className="textPCD">
                      {isProcessing ? "Processing" : "Proceed"}
                    </div>
                  </button>
                )}

              {!selectedTimeslot && (
                <p className="noteAP">
                  NOTE: Please fill all the fields and select timeslot
                </p>
              )}
              {!mobileNumber && <p></p>}
              {!name && <p></p>}
              {!selectedDoctor && <p></p>}
              {!selectedDepartment && <p></p>}
              {!currentDate && <p></p>}
            </div>
          </form>
          {proceedClicked && isProcessing && (
            <div className="AP-processing-message"></div>
          )}

          {/* <div className="AP-mobile-number-lookup">
            <p className="AP-alreadytext">
              Already made an appointment request?
            </p>

            <div>
              {" "}
              <a href="/AppointmentStatus" className="AP-token-proceed-button">
                Check Appointment Status
              </a>
            </div>

            
          </div> */}
        </div>
      )}
    </div>
  );
};

export default ScreenP;
