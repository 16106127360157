import React, { useState, useEffect, useContext } from 'react';
import { db } from '../../firebaseconfig';
import { HospitalContext } from '../ScreenForAdmin/HospitalContext'; // Import HospitalContext
import {
  collection,
  addDoc,
  serverTimestamp,
  onSnapshot,
  doc,
  updateDoc,
  query,
  where,
  getDocs,
  orderBy,
  limit
} from 'firebase/firestore';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Screen4de4Admin.css';



const Screen4DE = () => {
  const [name, setName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [availableDepartments, setAvailableDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState("");

  const { hospitalData } = useContext(HospitalContext);
const HID = hospitalData ? hospitalData.HID : null;


  const fetchDepartments = async () => {
    const departmentsQuery = query(collection(db, 'doctors'),
  
    where("hospitalData.HID", "==", HID) // Filter by HID
  
  
  );
    const querySnapshot = await getDocs(departmentsQuery);
    const departments = new Set();
    querySnapshot.forEach((doc) => {
      const doctorData = doc.data();
      if (doctorData.departmentName) {
        departments.add(doctorData.departmentName);
      }
    });
    return Array.from(departments);
  };

  const fetchDepartmentsAndDoctors = () => {
    const departmentsQuery = query(collection(db, 'doctors'),
  
    where("hospitalData.HID", "==", HID) // Filter by HID
  
  );

    const unsubscribeDepartments = onSnapshot(departmentsQuery, (snapshot) => {
      const departments = new Set();
      snapshot.forEach((doc) => {
        const doctorData = doc.data();
        if (doctorData.departmentName) {
          departments.add(doctorData.departmentName);
        }
      });
      setAvailableDepartments(Array.from(departments));
    });

    return () => unsubscribeDepartments();
  };

  useEffect(() => {
    const unsubscribe = fetchDepartmentsAndDoctors();

    // Cleanup function to unsubscribe from the previous snapshot listener
    return () => unsubscribe();
  }, []);

  const fetchDoctorsByDepartment = async (department) => {
    const doctorsQuery = query(
      collection(db, 'doctors'),
      where('departmentName', '==', department),
      where('availability', '==', 'Y'),
      where("hospitalData.HID", "==", HID) // Filter by HID
    );

    const unsubscribeDoctors = onSnapshot(doctorsQuery, (snapshot) => {
      const doctors = snapshot.docs.map((doc) => doc.data());
      setDoctorsList(doctors);
    });

    return () => unsubscribeDoctors();
  };

  const handleDepartmentChange = (event) => {
    const selectedDepartment = event.target.value;
    setSelectedDepartment(selectedDepartment);
    const unsubscribe = fetchDoctorsByDepartment(selectedDepartment);

    // Cleanup function to unsubscribe from the previous snapshot listener - for realtime update
    return () => unsubscribe();
  };

  const handleDoctorChange = (event) => {
    const selectedDoctor = event.target.value;
    setSelectedDoctor(selectedDoctor);
  };

  useEffect(() => {
    fetchDepartmentsAndDoctors();
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'doctors'), (snapshot) => {
      setDoctors(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    });

    return () => unsubscribe();
  }, []);

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  
  //   if (!name || !mobileNumber || !selectedDoctor) {
  //     alert("Please fill in all fields");
  //     return;
  //   }
  
  //   if (!HID) {
  //     alert("Hospital ID (HID) is not available");
  //     return;
  //   }
  
  //   setLoading(true);
  
  //   try {
  //     const doctorRef = doctors.find(doc => doc.name === selectedDoctor);
  //     const phoneCode = hospitalData ? hospitalData.phoneCode : '';
  
  //     if (!doctorRef) {
  //       alert("Selected doctor does not exist");
  //       return;
  //     }
  
  //     const newTokenNumber = doctorRef.counter + 1;

  //     // Extract the required fields from the selected doctor's data
  //   const { KCC, PFEE, HospName , currency } = doctorRef.hospitalData || {};
  //   const { consultationFee, consultationFeeComments} = doctorRef || {};
  
  //     // Use HID in Firestore operations
  //     const patientData = {
  //       name,
  //       mobileNumber: `${phoneCode}${mobileNumber}`, // Concatenate phoneCode and mobileNumber
  //       doctorAssigned: selectedDoctor,
  //       doctorRoom: doctorRef.room,
  //       requestTime: serverTimestamp(),
  //       status: 'C',
  //       PKC:"A",
  //       tokenNumber: newTokenNumber,
  //       tag: 'DE',
  //       tokenGenerationTime: serverTimestamp(),
  //       selectedDepartment,
  //       appointmentDate: new Date().toISOString(), // Current appointment date
  //       HID: HID, // Include HID in patient data

  //       // Add the new fields here
  //     KCC: KCC || 0,
  //     PFEE: PFEE || 0,
  //     consultationFee: consultationFee || "",
  //     consultationFeeComments: consultationFeeComments || "",
  //     currency: currency || "",
  //     hospitalName: HospName || "",


  //     };
  
  //     // Add patient document with HID
  //     await addDoc(collection(db, "patients"), patientData);
  
  //     // Update doctor's counter
  //     await updateDoc(doc(db, 'doctors', doctorRef.id), { counter: newTokenNumber });
  
  //     setSuccessMessage('Success');
  //   } catch (error) {
  //     console.error('Error generating token:', error);
  //     alert("Failed to generate token. Please try again.");
  //   } finally {
  //     setLoading(false);
  //     // Reset fields
  //     setName('');
  //     setMobileNumber('');
  //     setSelectedDoctor('');
  //     setSelectedDepartment('');
  //     // Clear success message after a delay
  //     setTimeout(() => {
  //       setSuccessMessage('');
  //     }, 3000); // Hide message after 3 seconds
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!name || !mobileNumber || !selectedDoctor || !selectedDepartment) {
      alert("Please fill in all fields");
      return;
    }
  
    if (!HID) {
      alert("Hospital ID (HID) is not available");
      return;
    }
  
    setLoading(true);
  
    try {
      // Query to find the correct doctor
      const doctorsQuery = query(
        collection(db, 'doctors'),
        where('name', '==', selectedDoctor),
        where('departmentName', '==', selectedDepartment),
        where('hospitalData.HID', '==', HID)
      );
  
      const doctorSnapshot = await getDocs(doctorsQuery);
  
      if (doctorSnapshot.empty) {
        alert("Selected doctor does not exist in this department and hospital");
        setLoading(false);
        return;
      }
  
      const doctorDoc = doctorSnapshot.docs[0];
      const doctorData = doctorDoc.data();
      const newTokenNumber = (doctorData.counter || 0) + 1;
  
      const phoneCode = hospitalData ? hospitalData.phoneCode : '';
  
      // Extract the required fields from the doctor's data
      const { KCC, PFEE, HospName, currency } = doctorData.hospitalData || {};
      const { consultationFee, consultationFeeComments } = doctorData || {};
  
      // Create patient data
      const patientData = {
        name,
        mobileNumber: `${phoneCode}${mobileNumber}`,
        doctorAssigned: selectedDoctor,
        doctorRoom: doctorData.room,
        requestTime: serverTimestamp(),
        status: 'C',
        PKC: "A",
        tokenNumber: newTokenNumber,
        tag: 'DE',
        tokenGenerationTime: serverTimestamp(),
        selectedDepartment,
        appointmentDate: new Date().toISOString(),
        HID: HID,
        KCC: KCC || 0,
        PFEE: PFEE || 0,
        consultationFee: consultationFee || "",
        consultationFeeComments: consultationFeeComments || "",
        currency: currency || "",
        hospitalName: HospName || "",
      };
  
      // Add patient document
      await addDoc(collection(db, "patients"), patientData);
  
      // Update doctor's counter
      await updateDoc(doc(db, 'doctors', doctorDoc.id), { counter: newTokenNumber });
  
      setSuccessMessage('Success');
    } catch (error) {
      console.error('Error generating token:', error);
      alert("Failed to generate token. Please try again.");
    } finally {
      setLoading(false);
      // Reset fields
      setName('');
      setMobileNumber('');
      setSelectedDoctor('');
      setSelectedDepartment('');
      // Clear success message after a delay
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    }
  };
  
  
  

  return (
    <div className="screen4de">

{availableDepartments.length === 0 && (
          <div className='AvailDays3'>
            <p>It looks like there are no departments and doctors available in your profile. Please add the same by following the below link</p>
            <Link className="linkStyle2"to="/AddDoctor">Add Doctor Details Link</Link>
          </div>
        )}
      <h3 className='CThead'>Create Token</h3>
      <form onSubmit={handleSubmit}>


        {/* <input
          type="text"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
          // placeholder="Mobile Number without Country Code"
          placeholder={`Patient mobile number without ${hospitalData.phoneCode}`}
          disabled={loading || successMessage === 'Success'}
        /> */}


<input
                className="name-input-field"
                type="text"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                onBlur={(e) => {
                  const pattern =
                    hospitalData.phoneCode === "+91"
                      ? /^\d{10}$/
                      : hospitalData.phoneCode === "+968"
                      ? /^\d{8}$/
                      : null;
                  if (pattern && !pattern.test(e.target.value)) {
                    setMobileNumberError(
                      hospitalData.phoneCode === "+91"
                        ? "Please enter exactly 10 digits for the mobile number"
                        : "Please enter exactly 8 digits for the mobile number"
                    );
                  } else {
                    setMobileNumberError("");
                  }
                }}
                placeholder={`Your mobile number without ${hospitalData.phoneCode}`}
                required
                pattern={
                  hospitalData.phoneCode === "+91"
                    ? "\\d{10}"
                    : hospitalData.phoneCode === "+968"
                    ? "\\d{8}"
                    : null
                }
                title={
                  hospitalData.phoneCode === "+91"
                    ? "Please enter exactly 10 digits for the mobile number"
                    : hospitalData.phoneCode === "+968"
                    ? "Please enter exactly 8 digits for the mobile number"
                    : "Please enter a valid mobile number"
                }
              />
              {mobileNumberError && (
                <p className="error-message">{mobileNumberError}</p>
              )}


        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Patient Name"
          disabled={loading || successMessage === 'Success'}
        />

        <select
          className="input-field"
          value={selectedDepartment}
          onChange={(e) => handleDepartmentChange(e)}
        >
          <option value="" disabled>Select Department</option>
          {availableDepartments.map((department, index) => (
            <option key={index} value={department}>
              {department}
            </option>
          ))}
        </select>

        <select
          className="input-field"
          value={selectedDoctor}
          onChange={(e) => handleDoctorChange(e)}
        >
          <option value="" disabled>Select Doctor</option>
          {doctorsList.map((doctor, index) => (
            <option key={index} value={doctor.name}>
              {doctor.name}
            </option>
          ))}
        </select>
{/* 
        {selectedDepartment && doctorsList.length > 0 && (
          <div>
            <h3>Doctors in {selectedDepartment}</h3>
            <ul>
              {doctorsList.map((doctor, index) => (
                <li key={index}>
                  {doctor.name} - Room {doctor.room}
                </li>
              ))}
            </ul>
          </div>
        )} */}
        <div>
          <button
            type="submit"
            disabled={loading || successMessage === 'Success'}
            className={`token-button ${loading ? 'processing' : successMessage ? 'success' : ''}`}
          >
            {loading ? 'Processing...' : successMessage ? 'Token Generated Successfully!' : 'Payment Done & Generate Token'}
          </button>
        </div>
      </form>
      {/* {successMessage && !loading && <p>{successMessage}</p>} */}
    </div>
  );
};

export default Screen4DE;
