import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebaseconfig';
import './TokenStatusPage.css';

const TokenStatusCheck = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [hospitalData, setHospitalData] = useState([]);
  const [tokenRecords, setTokenRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [displayMessage, setDisplayMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [buttonText, setButtonText] = useState('Check Status');

  useEffect(() => {
    const fetchHospitalData = async () => {
      try {
        const hospitalsRef = collection(db, 'hospitals');
        const querySnapshot = await getDocs(hospitalsRef);
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setHospitalData(data);
      } catch (error) {
        console.error('Error fetching hospital data:', error);
      }
    };
    fetchHospitalData();
  }, []);

  const fetchHospitalName = async (hospitalId) => {
    try {
      if (!hospitalId) {
        return 'Unknown Hospital (No ID)';
      }

      const hospitalsRef = collection(db, 'hospitals');
      const hospitalDoc = doc(hospitalsRef, hospitalId);
      const hospitalSnapshot = await getDoc(hospitalDoc);

      if (hospitalSnapshot.exists()) {
        const hospitalData = hospitalSnapshot.data();
        if (hospitalData && hospitalData.HospName) {
          return hospitalData.HospName;
        }
      }

      const doctorsRef = collection(db, 'doctors');
      const querySnapshot = await getDocs(query(doctorsRef, where('hospitalData.HID', '==', hospitalId)));

      if (!querySnapshot.empty) {
        const doctorDoc = querySnapshot.docs[0];
        const doctorData = doctorDoc.data();
        if (doctorData && doctorData.hospitalData && doctorData.hospitalData.HospName) {
          return doctorData.hospitalData.HospName;
        }
      }

      return 'Unknown Hospital';
    } catch (error) {
      console.error('Error fetching hospital name:', error);
      return 'Unknown Hospital (Error)';
    }
  };

  const getUniquePhoneCodesWithCountries = () => {
    const uniquePhoneCodesWithCountries = new Map();
    hospitalData.forEach((hospital) => {
      const { phoneCode, country } = hospital;
      if (!uniquePhoneCodesWithCountries.has(phoneCode)) {
        uniquePhoneCodesWithCountries.set(phoneCode, country);
      }
    });
    return Array.from(uniquePhoneCodesWithCountries.entries());
  };

  const fetchTokenRecords = async () => {
    setLoading(true);
    setError(null);

    try {
      if (!mobileNumber || !phoneCode) {
        setError('Please enter a mobile number and select a country code.');
        return;
      }

      const fullMobileNumber = `${phoneCode}${mobileNumber}`;
      const tokensRef = collection(db, 'patients');
      const querySnapshot = await getDocs(
        query(tokensRef, where('tag', '==', 'PWU'), where('mobileNumber', '==', fullMobileNumber))
      );

      const records = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const recordsWithHospitalNames = await Promise.all(
        records.map(async (record) => {
          const hospitalId = record.HID;
          if (hospitalId && typeof hospitalId === 'string') {
            const hospitalName = await fetchHospitalName(hospitalId);
            return { ...record, hospitalName };
          } else {
            return { ...record, hospitalName: 'Unknown Hospital (Invalid ID)' };
          }
        })
      );

      recordsWithHospitalNames.sort((a, b) => b.requestTime.toMillis() - a.requestTime.toMillis());
      setTokenRecords(recordsWithHospitalNames);

      if (recordsWithHospitalNames.length === 0) {
        setDisplayMessage('No records found with the provided mobile number');
      }
    } catch (error) {
      console.error('Error in fetchTokenRecords:', error);
      setError('Error fetching records. Please try again.');
    } finally {
      setLoading(false);
      setButtonText('Check Status');
    }
  };

  const handleRecordSelect = (record) => {
    setSelectedRecord(record);
    setDisplayMessage(generateDisplayMessage(record));
  };

  const handleClick = () => {
    if (!mobileNumber) {
      setError('Please enter a mobile number.');
      return;
    }
    fetchTokenRecords();
    setButtonText('Checking...');
  };

  const generateDisplayMessage = (record) => {
    let message = '';
    switch (record.status) {
      case 'A':
        message = <p className="APTVW-message APTVW-message--processing">Appointment Confirmed. One of our representatives
        contact you during the working hours</p>;
        break;
      case 'B':
        message = <p className="APTVW-message APTVW-message--acknowledged">
          Appointment Reconfirmed <br/><br/>
          Please reach the {record.hospitalName} 15 minutes before your appointment time of {formatTime(record.selectedTimeslot)} on {formatDate(record.appointmentDate)} for making the payment
        </p>;
        break;
      case 'C':
        message = (
          <div className="APTVW-message APTVW-message--waiting">
            You have made the payment.
            <br/>
            <p>Please wait. Your appointment is at {formatTime(record.selectedTimeslot)} on {formatDate(record.appointmentDate)} with {record.doctorAssigned} of {record.hospitalName} </p>
            <p>Doctor's Room: {record.doctorRoom}</p>
          </div>
        );
        break;
      case 'D':
        message = (
          <div className="APTVW-message APTVW-message--called">
            <p>The doctor has called you.</p>
            <p>Please go to the Doctor's room now</p>
            <p>Doctor Assigned: {record.doctorAssigned}</p>
            <p>Doctor's Room: {record.doctorRoom}</p>
          </div>
        );
        break;
      case 'E':
        message = <p className="APTVW-message APTVW-message--completed">Consultation Completed. Thank you for visiting us!</p>;
        break;
      case 'F':
        message = <p className="APTVW-message APTVW-message--missed">You didn't show up for the consultation. Please contact the Reception.</p>;
        break;
      case 'X':
        message = <p className="APTVW-message APTVW-message--missed">Your appointment request was cancelled by the hospital or clinic.
        We request you to contact the hospital reception to know the reason for cancellation of your appointment.</p>;
        break;
      default:
        message = <p className="APTVW-message APTVW-message--unrecognized">Status not recognized.</p>;
    }
    return message;
  };

  return (
    <div className="Apt-status-container">
      <h3 className='aptchecktitle'>Appointment Status Check</h3>
      <div className="check-status-form">
        <select value={phoneCode} onChange={(e) => setPhoneCode(e.target.value)}>
          <option value="">Select Country Code</option>
          {getUniquePhoneCodesWithCountries().map(([code, country]) => (
            <option key={code} value={code}>
              {`${code} (${country})`}
            </option>
          ))}
        </select>
        
        <input className="APTinput"
          type="text"
          id="mobileNumber"
          placeholder={`Enter your mobile number without country code ${phoneCode}`}
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        />
        <button
          disabled={loading}
          onClick={handleClick}
          className="APT-status-button"
        >
          {buttonText}
        </button>
      </div>

      {tokenRecords.length > 0 && (
        <div className="record-selection">
          <h4 className='aptchecktitle'>Select an appointment to check status</h4>
          <ul className="token-list">
            {tokenRecords.map((record) => (
              <li
                key={record.id}
                onClick={() => handleRecordSelect(record)}
                className={selectedRecord && selectedRecord.id === record.id ? 'selected' : ''}
              >
                <div className="record-details">
                  <div className="doctor-info">
                    <p>Hospital Name: {record.hospitalName}</p>
                    <p>Doctor Assigned: {record.doctorAssigned}</p>
                    <p>Appointment Date: {formatDate(record.appointmentDate)}</p>
                    <p>Appointment Time: {formatTime(record.selectedTimeslot)}</p>
                    <p>Name of the patient: {record.name}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {error && <div className="error-message">{error}</div>}

      <h4 className='aptchecktitle'>Status for the selected Appointment</h4>
      {displayMessage && (
        <div className="status-display">
          <p>{displayMessage}</p>
        </div>
      )}
    </div>
  );
};

const formatDate = (dateString) => {
  const options = { weekday: 'short', year: 'numeric', month: 'short', day: '2-digit' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};

const formatTime = (timeString) => {
  if (timeString.length === 5 && timeString.includes(':')) {
    const [hours, minutes] = timeString.split(':');
    const period = parseInt(hours) >= 12 ? 'PM' : 'AM';
    const formattedHours = parseInt(hours) % 12 || 12;
    return `${formattedHours}:${minutes} ${period}`;
  } else {
    const time = new Date(timeString);
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
  }
};

export default TokenStatusCheck;