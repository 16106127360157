import React from 'react';
import { Link } from 'react-router-dom';
import './FPFMU.css';

const UltraModernDashboard = () => {
  const buttons = [
    { to: '/appointmentrequests', text: 'Appointment Requests' },
    { to: '/MakeAppointment', text: 'Create Appointment' },
    { to: '/TokenRequests', text: 'Token Requests' },
    { to: '/CreateToken', text: 'Create Token' },
    { to: '/adddoctor', text: 'Add Doctor' },
    { to: '/viewdoctors', text: 'View Doctors' },
    { to: '/st', text: 'For TV screen' },
    { to: '/VideosForServingTokenPage', text: 'Videos For TV screen' },
    { to: '/LinkforAppointment', text: 'Shareable Link For Appointment' },
    { to: '/LinkForQRbasedTokens', text: 'Link For getting QR code-based Tokens' },
    { to: '/ForDoctors', text: 'For Doctors' },
    { to: '/AAV', text: 'Remind Appointments' },
    { to: '/PatientPayments-AP', text: 'Patients to Make Payment' },
    { to: '/login', text: 'Logout' },
  ];

  return (
    <div className="dashboard">
      <h1 className="dashboard__title">Quick Links</h1>
      <div className="dashboard__grid">
        {buttons.map((button, index) => (
          <Link key={index} to={button.to} className="dashboard__button">
            {button.text}
          </Link>
        ))}
      </div>
      <div className="notice">For the best experience, please log in using a laptop or desktop.</div>

      
    </div>
  );
};

export default UltraModernDashboard;