import React, { useEffect, useState } from 'react';
import { db } from '../../firebaseconfig';
import { collection, getDocs, query, where } from 'firebase/firestore';
import './UPATV.css';

const ScreenD = () => {
  const [doctor, setDoctor] = useState('');
  const [patients, setPatients] = useState([]);
  const [HID, setHID] = useState('');
  const [isChecking, setIsChecking] = useState(true);
  const [lastCheckTime, setLastCheckTime] = useState(null);
  const [phoneCode, setPhoneCode] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hidValue = urlParams.get('HID');
    const doctorValue = urlParams.get('doctor');
    setHID(hidValue);
    if (doctorValue) {
      setDoctor(decodeURIComponent(doctorValue));
    }

    // Fetch phoneCode from doctors collection
    const fetchPhoneCode = async () => {
      if (hidValue) {
        const doctorQuery = query(
          collection(db, 'doctors'),
          where('hospitalData.HID', '==', hidValue)
        );
        const doctorSnapshot = await getDocs(doctorQuery);
        if (!doctorSnapshot.empty) {
          const doctorData = doctorSnapshot.docs[0].data();
          setPhoneCode(doctorData.hospitalData.phoneCode);
        }
      }
    };

    fetchPhoneCode();
  }, []);

  const fetchPatients = async () => {
    if (doctor && HID) {
      setIsChecking(true);
      const q = query(
        collection(db, 'patients'), 
        where('doctorAssigned', '==', doctor),
        where('tag', '==', 'PWU'),
        where('HID', '==', HID)
      );

      try {
        const snapshot = await getDocs(q);
        const currentTime = new Date();
        const allPatients = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        const upcomingAppointments = allPatients.filter(patient => {
          const appointmentDate = new Date(patient.selectedTimeslot);
          return appointmentDate > currentTime;
        });

        upcomingAppointments.sort((a, b) => new Date(a.selectedTimeslot) - new Date(b.selectedTimeslot));

        setPatients(upcomingAppointments);
        setLastCheckTime(currentTime);
      } catch (error) {
        console.error("Error fetching patients:", error);
      } finally {
        setIsChecking(false);
      }
    }
  };

  useEffect(() => {
    fetchPatients();
  }, [doctor, HID]);

  const renderPatientList = () => {
    if (isChecking) {
      return <p className="UPAPTV-checking">Checking...</p>;
    }

    if (patients.length === 0) {
      return (
        <div className="UPAPTV-no-appointments-container">
          <p className="UPAPTV-no-appointments">No upcoming appointments for {doctor}</p>
          {/* <button className="UPAPTV-check-again" onClick={fetchPatients}>Check Again</button> */}
        </div>
      );
    }

    return (
      <>
        <div className="UPAPTV-appointment-list">
          {patients.map((patient) => (
            <div key={patient.id} className="UPAPTV-appointment-item">
              <p className="UPAPTV-appointment-time">
                {new Date(patient.selectedTimeslot).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </p>
            </div>
          ))}
        </div>
      </>
    );
  };

  const generateBackLink = () => {
    if (HID && phoneCode) {
      const baseUrl = window.location.origin;
      return `${baseUrl}/QR-Tokens?HID=${HID}&phoneCode=${encodeURIComponent(phoneCode)}`;
    }
    return '#';
  };

  return (
    <div className="UPAPTV-screen-d">
      <h2 className="UPAPTV-heading">Upcoming Appointments for {doctor}</h2>
      
      {doctor ? renderPatientList() : <p className="UPAPTV-no-doctor">No doctor specified</p>}
      {lastCheckTime && (
        <p className="UPAPTV-last-check">
          Last checked: {lastCheckTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
        </p>
      )}
      <br/>
      <p>NOTE: After clicking the button below, please enter the 
        mobile number used to create the token in the status check portion of the form to see your current token status.</p>
        <br/>

      <a href={generateBackLink()} className="UPAPTV-back-button">Back to Token Status Page</a>
      
    </div>
  );
};

export default ScreenD;