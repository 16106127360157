import React, { useContext, useState } from 'react';
import { HospitalContext } from './HospitalContext';
import './GenerateLinkForQR.css';

const GenerateLink = () => {
  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;
  const phoneCode = hospitalData ? hospitalData.phoneCode : null;

  const generateLink = () => {
    if (HID) {
      const baseUrl = window.location.origin;
      const link = `${baseUrl}/QR-Tokens?HID=${HID}&phoneCode=${encodeURIComponent(phoneCode)}`;
      return link;
    }
    return '';
  };

  const link = generateLink();
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };

  const qrCodeGeneratorLink = 'https://www.qrcode-monkey.com/?utm_source=google_c&utm_medium=cpc&utm_campaign=&utm_content=&utm_term=qr%20monkey_e&gad_source=1&gclid=Cj0KCQjw97SzBhDaARIsAFHXUWDPCLdOjcfqvde8JOaoZ4wZDxRqtmQVjEwyrvrShoSJVa_EisEdbTsaApoQEALw_wcB';

  return (
    <div className="QRLINK-container">
      <h2 className="QRLINK-heading">Shareable Link for Generating Token Requests from Patients</h2>
      <p className="QRLINK-text"></p>
      <div className="QRLINK-input-container">
        <input type="text" value={link} readOnly className="QRLINK-link-input" />
        <button className="QRLINK-copy-button" onClick={copyToClipboard}>
          {copySuccess ? 'Copied!' : 'Copy Link'}
        </button>
      </div>
      <p className="QRLINK-info">Generate QR Code for this copied link by entering the copied link <a href={qrCodeGeneratorLink} target="_blank" rel="noopener noreferrer">here</a>. 
      <br/><br/>This will help the patients to scan this QR code and create request
      for Token Number and they can see their Token Status on their mobile phone as well.</p>
    </div>
  );
};

export default GenerateLink;
