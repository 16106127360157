import React, { useState, useEffect, useContext } from "react";
import { db, auth } from "../../../firebaseconfig";
import { HospitalContext } from "../../ScreenForAdmin/HospitalContext";
import {
  collection,
  onSnapshot,
  query,
  where,
  doc,
  writeBatch,
} from "firebase/firestore";
import ALogin from "../../ScreenForAdmin/ALogin";
import { Link } from 'react-router-dom';
import "./DD.css";

const DoctorsManager = ({ resetAllCounters }) => {
  const { hospitalData } = useContext(HospitalContext);
  const HID = hospitalData ? hospitalData.HID : null;

  const [doctors, setDoctors] = useState([]);
  const [user, setUser] = useState(null);
  const [showCounters, setShowCounters] = useState(false);
  const [resetMessage, setResetMessage] = useState("");

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "doctors"), where("hospitalData.HID", "==", HID)),
      (snapshot) => {
        setDoctors(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
      }
    );

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
      unsubscribeAuth();
    };
  }, [HID]);

  const handleResetAllCounters = async () => {
    const userConfirmation = window.confirm(
      "Are you sure you want to reset all counters?"
    );

    if (userConfirmation) {
      const batch = writeBatch(db);
      doctors.forEach((doctor) => {
        const doctorRef = doc(db, "doctors", doctor.id);
        batch.update(doctorRef, { counter: 0 });
      });

      try {
        await batch.commit();
        setShowCounters(true);
        setResetMessage("All counters have been reset successfully!");
        setTimeout(() => {
          setShowCounters(false);
          setResetMessage("");
        }, 5000); // Hide message after 5 seconds
      } catch (error) {
        console.error("Error resetting counters:", error);
        setResetMessage("Failed to reset counters. Please try again.");
        setTimeout(() => {
          setShowCounters(false);
          setResetMessage("");
        }, 5000); // Hide message after 5 seconds
      }
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      console.log('User signed out successfully');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="DDD-container">
      {user ? (
        <div className="DDD-dashboard">
          <div className="DDD-doctors-button">
            <button className="DDD-resett" onClick={handleResetAllCounters}>
              Reset All Counters
            </button>
            <Link to="/MakeAppointment" className="DDD-resett">
              Create Appointment
            </Link>
            <Link to="/CreateToken" className="DDD-resett">
              Create Token
            </Link>

            <Link to="/ForDoctors" className="DDD-resett">
              For Doctors
            </Link>

            <Link to="/Profile" className="DDD-resett">
              Profile
            </Link>
           
            <Link to ="/AddDoctor" className="DDD-resett">
              Add Doctor
            </Link>
            <Link to ="/ViewDoctors" className="DDD-resett">
              View Doctors
            </Link>
            <a href="/ST" className="DDD-resett">
              Display Token Numbers
            </a>
            <a href="/VideosForServingTokenPage" className="DDD-resett">
              Videos for Token Screen
            </a>
            <a href="/LinkForAppointment" className="DDD-resett">
              Appointment Link for Patients
            </a>
            <a href="/AAV" className="DDD-resett">
             Remind Appointments
            </a>
            <a href="/LinkForQRbasedTokens" className="DDD-resett" >
              QR code for Token No.
            </a>
            <a href="/Billing" className="DDD-resett" >
              Billing
            </a>
            <button className="DDD-resett" onClick={handleLogout}>
              Logout
            </button>
            
          </div>

          <div className="DDD-doctors-list">

          {doctors.length === 0 && (
  <div className="DDD-no-doctors">
    <h1>Thanks for choosing Krysta Care</h1>
    <Link to="/AddDoctor" className="DDD-add-doctor-link">
      Add a Doctor
    </Link>
  </div>
)}
            {doctors.map((doctor) => (
              <div className="DDD-doctor-item" key={doctor.id}>
                <div className="DDD-doctor-photo">
                  {doctor.photoURL ? (
                    <img
                      src={doctor.photoURL}
                      alt="Doctor"
                      className="DDD-photo-img"
                      loading="lazy" // Adding lazy loading attribute
                    />
                  ) : (
                    <div className="DDD-placeholder-photo">
                      No Photo
                    </div>
                  )}
                </div>
                <div className="DDD-doctor-details">
                  <span className="DDD-doctor-name">{doctor.name}</span>
                  <span className="DDD-doctor-department">
                    {doctor.departmentName}
                  </span>
                  <span className="DDD-doctor-counter">
                    Counter Value: {doctor.counter}
                  </span>
                  <div className="DDD-availability-toggle">
                    <label className="DDD-label"></label>
                    <span className="DDD-availability-message">
                      {doctor.availability === "Y" ? "Available" : "Unavailable"}
                    </span>
                  </div>
                  <div className="DDD-appointment-toggle">
                    <label className="DDD-label"></label>
                    <span className="DDD-appointment-message">
                      {doctor.appointmentAllowed === "Y"
                        ? "Appointments allowed"
                        : "Appointments not allowed"}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <ALogin />
      )}

      {showCounters && (
        <div className="DDD-reset-message animated">
          {resetMessage}
        </div>
      )}
    </div>
  );
};

export default DoctorsManager;
